.tooltipster-sidetip.tooltipster-light .tooltipster-box {
	border-radius: 0;
	border: 1px solid #ccc;
	background: #fff;
}

.tooltipster-sidetip.tooltipster-light .tooltipster-content {
	color: #666;
	font-size: 0.825em;
}

.tooltipster-sidetip.tooltipster-light .tooltipster-arrow {
	height: 9px;
	margin-left: -9px;
	width: 18px
}

.tooltipster-sidetip.tooltipster-light.tooltipster-left .tooltipster-arrow, .tooltipster-sidetip.tooltipster-light.tooltipster-right .tooltipster-arrow {
	height: 18px;
	margin-left: 0;
	margin-top: -9px;
	width: 9px
}

.tooltipster-sidetip.tooltipster-light .tooltipster-arrow-background {
	border: 9px solid transparent
}

.tooltipster-sidetip.tooltipster-light.tooltipster-bottom .tooltipster-arrow-background {
	border-bottom-color: #fff;
	top: 1px
}

.tooltipster-sidetip.tooltipster-light.tooltipster-left .tooltipster-arrow-background {
	border-left-color: #fff;
	left: -1px
}

.tooltipster-sidetip.tooltipster-light.tooltipster-right .tooltipster-arrow-background {
	border-right-color: #fff;
	left: 1px
}

.tooltipster-sidetip.tooltipster-light.tooltipster-top .tooltipster-arrow-background {
	border-top-color: #fff;
	top: -1px
}

.tooltipster-sidetip.tooltipster-light .tooltipster-arrow-border {
	border: 9px solid transparent
}

.tooltipster-sidetip.tooltipster-light.tooltipster-bottom .tooltipster-arrow-border {
	border-bottom-color: #ccc
}

.tooltipster-sidetip.tooltipster-light.tooltipster-left .tooltipster-arrow-border {
	border-left-color: #ccc
}

.tooltipster-sidetip.tooltipster-light.tooltipster-right .tooltipster-arrow-border {
	border-right-color: #ccc
}

.tooltipster-sidetip.tooltipster-light.tooltipster-top .tooltipster-arrow-border {
	border-top-color: #ccc
}

.tooltipster-sidetip.tooltipster-light.tooltipster-bottom .tooltipster-arrow-uncropped {
	top: -9px
}

.tooltipster-sidetip.tooltipster-light.tooltipster-right .tooltipster-arrow-uncropped {
	left: -9px
}