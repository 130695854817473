.filter-category-list {
	display: flex;
	@include list-unstyled;
	margin: 0;
	padding: 0;
	flex-wrap: wrap;

	&__item {
		flex: 1 1 100%;
		max-width: 100%;
		flex-basis: 33.33%;
		margin-right: 2%;

		&--full, &--Full {
			margin-right: 0;

			@media #{$sm-up} {
				flex-basis: 100%;

			}
		}
	}

	@supports(display: grid) {
		margin-right: 0;

		@media #{$sm-up} {
			display: grid;
			grid-template-columns: 1fr;
			grid-column-gap: 40px;
			grid-auto-flow: dense;
			grid-template-columns: 1fr 1fr 1fr;
		}

		&__item {
			display: flex;
			align-items: center;
			grid-column-start: 1;

			&--center, &--Center {
				grid-column-start: 1;

				@media #{$sm-up}{
					grid-column-start: 2;
				}

				&:first-child {
					grid-column-start: 1;
				}
			}

			&--right, &--Right {
				grid-column-start: 1;

				@media #{$sm-up}{
					grid-column-start: 3;
				}

				&:first-child {
					grid-column-start: 1;
				}
			}

			&--one-third, &--One-Third {
				align-items: start;
			}

			&--grow, &--Grow {
				@media #{$sm-up} {
					grid-row: span 10;
				}
			}

			&--two-thirds, &--Two-Thirds {
				align-items: start;
				@media #{$sm-up}{
					grid-column-end: span 2;
				}
			}

			&--full, &--Full {
				@media #{$sm-up}{
					grid-column-end: span 3;
				}
			}

			@media #{$xs-only} {
				flex-basis: 100%;
			}

			&--seperator, &--separator {
				border-top: 2px solid white;
				padding-top: map-get($spacing, medium);
			}
		}
	}
}
