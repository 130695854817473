.stack-list {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	max-width: 100%;

	@media #{$sm-up} {
		flex-direction: row;
		flex-wrap: nowrap;
		display: flex;
		align-items: flex-start;
	}

	&__item {
		margin: 0 map-get($spacing, small);
		overflow:hidden;

		@media #{$xs-only} {
			display: block;
			margin-bottom: map-get($spacing, small);
			border-bottom: 1px solid map-get($brand-colors, gray-light);

			&:last-child {
				border-bottom: 0;
			}
		}

		@media #{$sm-up} {
			flex: 1 1 0;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		&--reversed {
			.stack__title {
				color: white;
			}
			.stack__description {
				color: white;

				a:not(.btn) {
					color: map-get($brand-colors, white);
					text-decoration: underline;

					&:hover {
						color: map-get($brand-colors, white);
					}
				}
			}
			.stack__cta {
				background: transparent;
				color: white;

				&:hover {
					background: transparent;
					color: white;
				}
			}
		}

		&--static {
			.stack__img {
				height: 80px;
			}
		}
	}

	&--tall {
		.stack__img {
			height: auto;
			max-width: 100%;
			max-height: 325px;
			min-height: initial;
			min-width: initial;
			display: block;
			margin: 0 auto map-get($spacing, tiny) auto;
			flex: 0 0 auto;
		}
	}

	&--taller {
		.stack__img {
			height: auto;
			max-width: 100%;
			max-height: 450px;
			min-height: initial;
			min-width: initial;
			display: block;
			margin: 0 auto map-get($spacing, tiny) auto;
			flex: 0 0 auto;
		}
	}

	&--bordered {
		.stack-list__item {
			@media #{$sm-up}{
				border-right: 1px solid map-get($brand-colors, gray-light);
				margin: 0;
				padding: 0 map-get($spacing, small);

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					border-right: 0;
					padding-right: 0;
				}
			}
		}
	}

	&--wrap {
		@media #{$sm-up} {
			flex-wrap: wrap;
			justify-content: space-around;
		}

		.stack-list__item {
			@media #{$sm-up} {
				margin-bottom: map-get($spacing, medium);
				flex-basis: calc(33.33% - #{map-get($spacing, small) * 2});
				max-width: 33.33%;
			}

			@media #{$sm-up} {
				//flex-basis: calc(25% - #{map-get($spacing, small) * 2});
			}

			@media #{$lg-up} {
				flex-basis: calc(20% - #{map-get($spacing, small) * 2});
			}
		}
	}
}

.stack {
	display: block;
	position: relative;
	text-align: center;

	@media #{$sm-up}{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&--left-aligned {
		text-align: left;
		@media #{$sm-up}{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}
	}

	&__img-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 100%;

		.stack--left-aligned & {
			align-items: center;
			justify-content: flex-start;
		}

		@media #{$xs-only}{
			flex-direction: column;
		}
	}

	&__img {
		max-width: 100%;
		max-height: 80px;
		display: block;
		margin: 0 auto map-get($spacing, tiny) auto;
		flex: 0 0 auto;

		.stack--left-aligned & {
			margin: 0;
		}
	}

	&__title {
		@include responsive-type($h5-map);
		text-transform: uppercase;
		flex: 1 1 auto;
		margin: 1em 0;
		font-weight: normal;
		@include pfont-bold;
		line-height: 1.1;
		text-align: center;
		max-width: 100%;
		padding: 0 map-get($spacing, small);

		.stack--left-aligned & {
			text-align: left;
			padding: 0;
		}
	}

	&__description {
		line-height: 1.5;
		margin-bottom: map-get($spacing, medium);
		text-align: center;
		width: 100%;
		font-size: 0.9rem;
		max-width: 100%;

		> ul {
			@include list-unstyled;

			> li {
				border-bottom: 1px solid rgba(map-get($brand-colors, gray-light), 0.2);
				padding: map-get($spacing, small) 0;

				&:last-child {
					padding-bottom: 0;
					border-bottom: 0;
				}
			}
		}

		.stack--left-aligned & {
			text-align: left;
		}
	}

	&__cta {
		margin-top: map-get($spacing, tiny);
		flex: 1 1 auto;
	}


}
