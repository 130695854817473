/*================================================================================
[Product result]
*/
.product-result {
	&__link {
		display: flex;
		align-items: center;
		padding: map-get($spacing, tiny);
		border-radius: 5px;

		&, &:hover, &:active, &:focus {
			color: inherit;
			text-decoration: inherit;
		}
	}

	&__img {
		width: 80px;
		text-align: center;

		img {
			max-width: 100%;
			mix-blend-mode: multiply;
		}
	}

	&__body {
		margin-left: map-get($spacing, medium);
		flex-grow: 1;
	}

	&__model {
		font-size: 0.7em;
		text-transform: uppercase;
		@include pfont-bold;
	}

	&__name {
		font-size: 1.1em;
		@include pfont-bold;
		text-transform: uppercase;
	}
	
	&__retailer-exclusive {
		img {
			max-height: 75px;
		}
	}
}
