.tag {
	border-radius: 22px;
	transition: all 250ms ease-in-out;
	background: #fff;
	border: 1px solid map-get($brand-colors, gray-light);
	display: inline-block;
	margin-right: map-get($spacing, tiny);
	padding: 0px 18px;
	@include pfont-regular;

	&:hover {
		cursor: pointer;
		background: rgba(map-get($brand-colors, gray-light), 0.25);
	}

	&--inactive {
		&, &:hover {
			cursor: default;
			background: #fff;
		}
	}

	&__name {
		overflow: hidden;
		display: inline-block;
		//@include pfont-bold;
		@include pfont-regular;
		font-size: 0.825em;
		line-height: 36px;
	}
	&:before {
		color: map-get($brand-colors, red-light);
		overflow: hidden;
		display: inline-block;
		font-size: 0.7em;
		line-height: 36px;
	}
	&.state-grow {
		transition: all 150ms ease-in-out;
		transform: scale(1.1);
	}
	&.state-shrink {
		transition: all 150ms ease-in-out;
		transform: scale(.9);
	}
	&.state-fade-out {
		opacity: 0;
		transition: all 210ms ease-in-out;
	}

	&--blog {
		text-decoration: none;
		line-height: 36px;
		@include pfont-regular;
		color: $text-color;

		&:hover {
			color: darken($text-color, 5%);
			text-decoration: none;
			background: map-get($brand-colors, gray-lighter);
		}
	}
}
