.v-compare {
	margin-bottom: map-get($spacing, massive);

	&__primary-image {
		max-width: 100%;
		max-height: 200px;
		margin: 0 auto map-get($spacing, medium) auto;
		display: block;

		@media #{$sm-up}{
			max-width: 100px;
			max-height: 100px;
		}
	}

	&__mobile-header {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		margin-bottom: map-get($spacing, medium);

		 @media #{$sm-up}{
		 	display: none;
		 }
	}

	&__mobile-header-item {
		flex: 0 0 auto;
		max-width: 20%;
	}

	&__mobile-header-model {
		@include pfont-bold;
		text-align: center;
		font-size: 0.7em;
		display: block;
	}

	&__title {
		display: none;

		&.state-loaded {
			display: block;
		}
	}
}
