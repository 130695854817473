/*================================================================================
[Tab list]
*/
.tab-list {
	&:not(.tab-list--img-switcher) {
		.tab-list__contents {
			> * {
				display: none;

				&.state-active {
					display: block;
				}
			}
		}
	}

	&__tabs {
		list-style: none;
		margin-left: 0;
		padding-left: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		&--center {
			justify-content: center;
		}

		&--vertical {
			flex-direction: column;
		}

		//mobile version
		@media #{$xs-only}{
			display: none;

			&.state-visible {
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				width: 100%;
				background: white;
				padding: 0;
				margin: 0;
				top: 100%;
				box-shadow: 0 6px 3px -3px rgba(#0A0A0A, 0.2);
				z-index: $zindex-dropdown-high;
			}
		}
	}

	&__tab {
		//margin: 0 map-get($spacing, small);
	}

	&__mobile-wrap {
		position: relative;
	}

	&__mobile-trigger {
		position: relative;
		display: block;
		background: white;
		border: 1px solid map-get($brand-colors, gray-light);
		padding: map-get($spacing, small) map-get($spacing, huge) map-get($spacing, small) map-get($spacing, medium);
		color: $text-color;
		border-radius: 2px;
		text-transform: uppercase;
		@include pfont-bold;
		font-size: 0.875em;

		&:hover {
			color: $text-color;
			text-decoration: none;
		}

		&:before {
			content: "\e911";
			font-family: icomoon;
			color: map-get($brand-colors, red-light);
			font-size: 1.2em;
			display: inline-block;
			position: absolute;
			right: map-get($spacing, medium);
			top: 50%;
			transform: translateY(-50%);
		}

		[class^="container"] & {
			margin: map-get($spacing, small) 0;
		}
	}

	&__trigger {
		display: block;
		font-weight: 800;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		font-size: 0.875em;
		position: relative;
		color: $text-color;
		transition: color $transition-fast ease;

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: map-get($brand-colors, red-light);
		}

		&.state-selected {
			&:after {
				border-color: map-get($brand-colors, red-light);

				.tab-list__tabs--nav & {
					border: 0;
				}
			}

			.tab-list--alternate & {
				background: white;
				color: $text-color;

				&:after {
					display: none;
				}
			}

			.tab-list--alternate2 & {
				background: map-get($brand-colors, gray-light);
				color: $text-color;

				&:after {
					display: none;
				}
			}
		}

		&.state-disabled {
			opacity: 0.5;
			pointer-events: none;
			cursor: not-allowed;
		}

		@media #{$xs-only}{
			border: 1px solid map-get($brand-colors, gray-light);
			border-top: 0;
			padding: map-get($spacing, small) map-get($spacing, medium);
			color: map-get($brand-colors, gray-med);

			&.state-selected {
				color: $text-color;
			}
		}

		@media #{$sm-up}{
			font-size: 0.7em;
			padding: 16px;

			&:after {
				content: '';
				position: absolute;
				bottom: map-get($spacing, tiny);
				left: map-get($spacing, small);
				right: map-get($spacing, small);
				border-top: 4px solid transparent;
			}

			.tab-list__tabs--vertical & {
				display: inline-block;
				padding: map-get($spacing, tiny) map-get($spacing, medium);

				&:after {
					bottom: map-get($spacing, tiny);
				}

			}

		}

		@media #{$md-up}{
			font-size: 0.75em;
			padding: 18px map-get($spacing, medium);
			&:after {
				left: map-get($spacing, medium);
				right: map-get($spacing, medium);
			}
		}

		@media #{$lg-up}{
			font-size: 0.875em;
		}
	}

	&--alternate {
		.tab-list__tabs {
			margin-bottom: 0;

			@media #{$sm-up}{
				padding-top: map-get($spacing, medium);
			}
		}
	}

	&--alternate2 {
		.tab-list__tabs {
			margin-bottom: 0;
		}
	}

	&--white {
		.tab-list__trigger {
			@media #{$sm-up}{
				color: map-get($brand-colors, true-white);
			}
		}
	}

	//this is used to remove padding from first and last item
	&--left-edge {
		.tab-list__tab {
			@media #{$sm-up}{
				&:first-child {
					.tab-list__trigger {
						padding-left: 0;

						&:after {
							left: 0;
						}
					}
				}
				&:last-child {
					.tab-list__trigger {
						padding-right: 0;

						&:after {
							right: 0;
						}
					}
				}
			}
		}

	}

	&--img-switcher {
		.tab-list__contents {
			> * {
				opacity: 0;
				z-index: 1;
				pointer-events: none;
				transition: opacity $transition-slow ease;

				&.state-active {
					opacity: 1;
					z-index: 2;
					pointer-events: all;
					transition: opacity $transition-slow ease;
				}
			}
		}
	}
}
