//content will be put in through cms so best to transform elements by tag name not class to avoid lots of class names in cms
.v-press-detail {
	&__title {
		margin-bottom: map-get($spacing, large);
	}

	&__date {
		color: map-get($brand-colors, gray-med);
		@include pfont-bold;
		font-size: 1.1em;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: map-get($spacing, medium);
	}

	&__body {
		margin-bottom: map-get($spacing, large);
	}

	/*================================================================================
	[Inline content styles]
	*/
	h2 {
		margin-bottom: map-get($spacing, large);
	}

	h3, h4 {
		margin-top: map-get($spacing, medium);
	}

	blockquote {
		margin: map-get($spacing, large) 0;
	}
}
