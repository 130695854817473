.block-link {
	position: relative;
	max-height: 550px;
	overflow: hidden;
	display: block;
	color: white;
	max-width: 100%;
	background: map-get($brand-colors, gray-dark); // TEMPORARY

	.ie11 & {
		min-height: 220px;
	}

	&:hover {
		text-decoration: none;

		.block-link__icon {
			transform: scale(1.2);
		}
	}


	.flex--3-up &, .flex--3-up-bordered &,
	.flex--4-up &, .flex--4-up-bordered &,
	.flex--5-up &, .flex--5-up-bordered & {
		.ie11 & {
			height: 220px !important;
			@media #{$sm-up}{
				height: 220px !important;
			}
			@media #{$md-up}{
				height: 280px !important;
			}
			@media #{$lg-up}{
				height: 300px !important;
			}

		}
	}

	.flex--2-up &, .flex--2-up-bordered & {
		.ie11 & {
			height: 220px !important;
			@media #{$sm-up}{
				height: 260px !important;
			}
			@media #{$md-up}{
				height: 380px !important;
			}
			@media #{$lg-up}{
				height: 430px !important;
			}
		}
	}

	&__poster {
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}

	&__content {
		position: absolute;
		left: 0;
		right: 0;
		top: -10px;
		bottom: 0;
		margin: 0 auto;
		max-width: $container-lg;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		z-index:5;
		width: 100%;
		height: 100%;

		@media #{$lg-up}{
			top: 0;
		}
	}

	&__title {
		font-size: 4vw;
		color: white;
		max-width: 80%;
		line-height: 1.1;
		text-align: center;
		text-shadow: 0 0 10px rgba(20, 20, 20, 0.5);
		@include pfont-bold;

		@media #{$sm-up} {
			font-size: 1.5vw;
		}
	}

	&__icon {
		@include responsive-type($jumbo-h1-map);
		color: white;
		text-shadow: 0 0 10px rgba(20, 20, 20, 0.5);
		transform-origin: center center;
		transform: scale(1);
		text-decoration: none;
		transition: all 0.25s ease;
		margin-top: 15px;

		@media #{$sm-down}{
			font-size: 36px;
		}
	}
}
