//used to wrap around for displaying inline
//flex-inline has been known to have numerous bugs in ie11 so avoiding it

.icons-list {
	@include list-unstyled;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;

	&__item {
		flex: 1 1 auto;
		padding: 0;
	}

	.icons-block {
		flex-direction: column;
		flex: 1 1 auto;
	}

	.icons-block__link {
		padding: map-get($spacing, tiny) 0;
	}

	.icons-block__icon {
		flex: 0 0 22px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 22px;
		height: 22px;
		max-width: 22px;
	}

	&--inline {
		display: inline-flex;
		flex: 0 0 auto;
		flex-wrap: wrap;
		flex-direction: row;

		.icons-list__item {
			flex: 0 0 auto;
			padding: 0 8px;

			&:first-child {
				padding-left: 0;
			}
		}

		.icons-block__link {
			display: flex;
			flex-direction: row;
			padding: 0;
		}

		.icons-block {
			flex-direction: row;
		}
	}

	&--separators {
		.icons-list__item {
			border-left: 0;
			padding: 0;
			border-bottom: 2px solid map-get($brand-colors, gray-light);

			&:last-child {
				border-left: 0;
				padding-left: 0;
				border-bottom: 0;
			}
		}

		&.icons-list--inline {
			.icons-list__item {
				border-top: 0;
				border-left: 2px solid map-get($brand-colors, gray-light);
				padding: 0 map-get($spacing, small);
				border-bottom: 0;

				&:first-child {
					padding-left: 0;
					border-left: 0;
				}
			}

			.icons-block__link {
				padding: 0;
			}
		}
	}

	&--compact {
		.icons-block {
			padding: 0;
		}
	}

	&--white {
		.icons-block__icon {
			color: map-get($brand-colors, red-light);
		}

		.icons-block__link {
			color: map-get($brand-colors, true-white);

			.icons-block__text {
				color: map-get($brand-colors, true-white);
			}

			.icons-block__icon {
				color: map-get($brand-colors, red-light);
			}

			&:hover {
				color: map-get($brand-colors, true-white);

				.icons-block__text {
					color: map-get($brand-colors, true-white);
				}
			}
		}
	}
}

.icons-block {
	display: inline-flex;

	&__link {
		display: flex;
		align-items: center;
		text-decoration: none;
		@include pfont-bold;

		.icons-block__icon {
			color: map-get($brand-colors, gray-med);
		}

		.icons-block__text {
			color: map-get($brand-colors, gray-dark);
		}

		&:focus {
			text-decoration: none;
			outline: 0;
		}

		&:hover {
			text-decoration: none;
			color: map-get($brand-colors, red-light);

			.icons-block__icon,
			.icons-block__text {
				color: map-get($brand-colors, red-light);
			}
		}
	}

	&__icon {
		flex: 0 0 auto;
	}

	&__text {
		flex: 0 0 auto;
		padding-left: 5px;
		@include pfont-bold;
		font-size: 0.875em;
	}
}
