.support-article-list {
	@include list-unstyled;
	margin: 0;
	padding: 0;

	&__item {
		&:first-child {
			.support-article-list__link {
				border-top: 0;
			}
		}
	}

	&__link {
		color: $gray-dark;
		text-decoration: underline;
		border-top: 2px solid map-get($brand-colors, gray-light);
		padding: map-get($spacing, small) 0;
		display: block;

		&:hover {
			color: map-get($brand-colors, true-black);
			text-decoration: underline;
		}
	}
}
