.v-collections {
	display: flex;
	flex-direction: column;

	&__collection-feature-text {
		margin-bottom: map-get($spacing, large);
		@media #{$sm-up}{
			margin-top: -(map-get($spacing, small));
		}
		@media #{$md-up}{
			margin-top: -(map-get($spacing, large));
			margin-bottom: 0;
		}
	}

	&__parallax-quote {
		> * {
			max-width: 100%;
		}
	}

	//COLLECTION NAME HERE
	&--briarsfield {
		.v-collections__parallax {
			position: relative;
			overflow: hidden;
		}

		.v-collections__parallax-img-1 {
			left: 20%;
			top: -5%;
			position: absolute;
			opacity: 0.2;
			width:40%;

			@media #{$xs-only}{
				width: 70%;
				left: 15%;
				top: 2%;
			}

			@media #{$sm-only}{
				left: 25%;
				top: 10%;
			}

			@media #{$md-up}{
				opacity: 1;
				left: 3%;
				width: 22%;
				height: auto;
			}

		}

		.v-collections__parallax-img-2 {
			left: 20%;
			right: auto;
			position: absolute;
			opacity: 1;
			top: -90px;
			width: 15%;

			@media #{$sm-down}{
				display: none;
			}

			@media #{$md-up}{
				opacity: 1;
				width: 16%;
				right: 0;
				top: -90px;
				left: auto;
				height: auto;
			}
		}

		.v-collections__parallax-decor-1 {
			left: 0;
			bottom: 0;
			position: absolute;
			max-width: 35%;
			height: auto;

			@media #{$sm-down}{
				display: none;
			}
		}

		.v-collections__parallax-decor-2 {
			display: none;

		}

		.v-collections__parallax-decor-3 {
			bottom: 0;
			right: 16%;
			transform-origin: bottom right;
			position: absolute;
			max-width: 17%;


			@media #{$sm-down}{
				display: none;
			}

			@media #{$md-up}{
				right: 8%;
			}

			@media #{$lg-up}{
				right: 11%;
			}
		}

		.v-collections__parallax-quote {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 70%;
			margin: 0 auto;
			flex-direction: column;
			z-index: $zindex-lower;
			position: relative;

			@media #{$xs-only}{
				padding: 90px 0;
			}

			@media #{$sm-up}{
				max-width: 600px;
				min-height: 90vh;
			}
		}

		.v-collections__parallax-quote-text {
			color: map-get($brand-colors, gray-med);
			@include responsive-type($h2-map);
			@include pfont-regular;
			text-align: center;
		}

		.v-collections__parallax-quote-cite {
			margin-top: map-get($spacing, large);
			text-align: center;
		}
	}

	//COLLECTION NAME HERE

	&--tisbury {
		.v-collections__parallax {
			position: relative;
			overflow: hidden;
		}

		.v-collections__parallax-img-1 {
			left: 0;
			bottom: 5%;
			position: absolute;
			opacity: 0.05;

			@media #{$sm-down}{
				opacity: 0.2;
				height: auto;
				bottom: auto;
				top: 12%;
				left: 10%;
				width: 80%;
			}

			@media #{$md-up}{
				opacity: 1;
				width: 30%;
				height: auto;
			}
		}

		.v-collections__parallax-img-2 {
			right: 3%;
			top: 0;
			position: absolute;
			opacity: 0.05;

			@media #{$sm-down}{
				display: none;
			}

			@media #{$md-up}{
				opacity: 1;
				width: 20%;
				height: auto;
				top: -90px;
			}
		}

		.v-collections__parallax-decor-1 {
			left: 0;
			top: -20px;
			position: absolute;
			max-width: 17%;
			height: auto;

			@media #{$sm-down}{
				display: none;
			}

			@media #{$md-up}{
				max-width: 30%;
			}
		}

		.v-collections__parallax-decor-2 {
			display: none;
		}

		.v-collections__parallax-decor-3 {
			bottom: 0;
			right: 0;
			transform: scale(1);
			transform-origin: bottom right;
			position: absolute;
			max-width: 17%;

			@media #{$sm-down}{
				display: none;
			}

			@media #{$md-up}{
				max-width: 30%;
			}
		}

		.v-collections__parallax-quote {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 70%;
			margin: 0 auto;
			flex-direction: column;
			z-index: $zindex-lower;
			position: relative;

			@media #{$xs-only}{
				padding: 90px 0;
			}

			@media #{$sm-up}{
				max-width: 600px;
				min-height: 90vh;
			}
		}

		.v-collections__parallax-quote-text {
			color: map-get($brand-colors, gray-med);
			@include responsive-type($h2-map);
			@include pfont-regular;
			text-align: center;
		}

		.v-collections__parallax-quote-cite {
			margin-top: map-get($spacing, large);
			text-align: center;
		}
	}


	&--venturi {
		.v-collections__parallax {
			position: relative;
			overflow: hidden;
		}

		.v-collections__parallax-img-1 {
			left: 0;
			bottom: 15%;
			position: absolute;
			opacity: 0.05;

			@media #{$sm-down}{
				left: 10%;
				width: 80%;
				opacity: 0.2;
				top: 25%;
			}

			@media #{$sm-only}{
				top: 23%;
			}

			@media #{$md-up}{
				opacity: 1;
				width: 30%;
				height: auto;
			}
		}

		.v-collections__parallax-img-2 {
			right: 0;
			top: 0;
			position: absolute;
			opacity: 0.05;

			@media #{$sm-down}{
				display: none;
			}

			@media #{$md-up}{
				opacity: 1;
				width: 27%;
				height: auto;
				top: -90px;
			}
		}

		.v-collections__parallax-decor-1 {
			left: 7%;
			top: 0px;
			position: absolute;
			max-width: 22%;
			height: auto;

			@media #{$sm-down}{
				display: none;
			}
		}

		.v-collections__parallax-decor-2 {
			right: 28%;
			top: -5%;
			transform: scale(0.4);
			position: absolute;
			transform-origin: right top;

			@media #{$sm-down}{
				display: none;
			}

		}

		.v-collections__parallax-decor-3 {
			bottom: 7%;
			right: 13%;
			transform: scale(1);
			transform-origin: bottom right;
			position: absolute;
			max-width: 24%;

			@media #{$sm-down}{
				display: none;
			}
		}

		.v-collections__parallax-quote {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 600px;
			margin: 0 auto;
			flex-direction: column;
			z-index: $zindex-lower;
			position: relative;
			padding: 90px 0;

			@media #{$sm-up}{
				min-height: 90vh;
			}
		}

		.v-collections__parallax-quote-text {
			color: map-get($brand-colors, gray-med);
			@include responsive-type($h2-map);
			@include pfont-regular;
			text-align: center;
		}

		.v-collections__parallax-quote-cite {
			margin-top: map-get($spacing, large);
			text-align: center;
		}
	}


	//KELEN COLLECTION CUSTOM PARALLAX
	&--kelen {
		.v-collections__parallax {
			position: relative;
			overflow: hidden;
		}

		.v-collections__parallax-img-1 {
			left: 0;
			bottom: 20%;
			position: absolute;
			opacity: 0.05;

			@media #{$sm-down}{
				display: none;
			}

			@media #{$md-up}{
				opacity: 1;
				width: 25%;
				height: auto;
			}
		}

		.v-collections__parallax-img-2 {
			right: 0;
			top: 0;
			position: absolute;
			opacity: 0.05;

			@media #{$sm-down}{
				display: none;
			}

			@media #{$md-up}{
				opacity: 1;
				width: 25%;
				height: auto;
				top: -90px;
			}
		}

		.v-collections__parallax-decor-1 {
			left: 15%;
			top: -40px;
			position: absolute;

			@media #{$sm-down}{
				top: 12%;
				left: 10%;
				width: 80%;
				opacity: 0.2;
			}
		}

		.v-collections__parallax-decor-2 {
			right: -50px;
			top: 50%;
			transform: scale(0.5);
			position: absolute;

			@media #{$sm-down}{
				display: none;
			}
		}

		.v-collections__parallax-decor-3 {
			bottom: 0;
			left: 20%;
			transform: scale(0.8) rotate(130deg);
			position: absolute;

			@media #{$sm-down}{
				display: none;
			}
		}

		.v-collections__parallax-quote {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 600px;
			margin: 0 auto;
			flex-direction: column;
			z-index: $zindex-lower;
			position: relative;
			padding: 90px 0;

			@media #{$sm-up}{
				min-height: 90vh;
			}
		}

		.v-collections__parallax-quote-text {
			color: map-get($brand-colors, gray-med);
			@include responsive-type($h2-map);
			@include pfont-regular;
			text-align: center;
		}

		.v-collections__parallax-quote-cite {
			margin-top: map-get($spacing, large);
			text-align: center;
		}
	}
}
