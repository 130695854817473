.v-support-article {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;

	&:last-child {
		border-bottom: 0;
	}


	&__title {
		@include pfont-bold;
		order: 1;
		color: map-get($brand-colors, blue-gray);
	}

	&__content {
		&--below {
			order: 2;
		}

		&--above {
			order: 3;
		}
	}

	&__media {
		margin: map-get($spacing, medium) 0;
		&--below {
			order: 3;
			margin: map-get($spacing, medium) 0 0 0;
		}

		&--above {
			order: 2;
			margin: map-get($spacing, tiny) 0 map-get($spacing, medium) 0;
		}

		&:empty {
			display: none;
		}
	}

	&__more-help {
		order: 4;
		margin-top: map-get($spacing, small);
	}

	&__video-img {
		display: flex;
		align-items: stretch;
		//max-height: 200px;
		//padding: map-get($spacing, small);
		//background: lighten(map-get($brand-colors, gray-lighter), 2%);
	}

	&__video-img-item {
		//background: map-get($brand-colors, true-white);
		flex: 1 1 calc(33.33% - 8px);
		//border: 1px solid map-get($brand-colors, gray-light);
		max-width: calc(33.33% - 8px);
		position: relative;
		display: flex;
		justify-content: center;
		text-align: center;

		&:first-child {
			margin-right: map-get($spacing, tiny);
		}

		&:nth-child(2):not(:last-child){
			flex: 1 1 calc(33.33% - 14px);
			margin: 0 map-get($spacing, tiny);
		}

		&:last-child {
			margin-left: map-get($spacing, tiny);
		}


	}

	&__section {
		padding: map-get($spacing, large) 0;

		h1, h2 {
			@include pfont-bold;
		}

		//margin-bottom: map-get($spacing, large);
		//padding-bottom: map-get($spacing, large);
		border-bottom: 1px solid map-get($brand-colors, gray-light);
	}

	&__image {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}
