.collection-feature {
	height: 220px;
	position: relative;
	background: map-get($brand-colors, gray-lightest);
	overflow: hidden;
	display: block;
	text-decoration: none;
	color: map-get($brand-colors, gray-dark);

	@media #{$sm-up} {
		height: 320px;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		z-index: 1;
		height: 50%;
		left: 0;
		right: 0;
		top: 0;
		@include gradient-vertical(rgba(map-get($brand-colors, black), 0.7), rgba(map-get($brand-colors, black), 0), 0, 100%);
	}

	&:hover {
		text-decoration: none;
		color: map-get($brand-colors, gray-dark);

		.collection-feature__img {
			transform: scale(1.1);
		}

		.collection-feature__finishes {
			opacity: 1;
		}
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: scale(1);
		transition: all 0.75s ease;
	}

	&__content {
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		right: 0;
		padding: map-get($spacing, medium) 0 0 map-get($spacing, medium);
	}	
	&__grade-logo {
		display: inline-block;		
	}

	&__flag {
		background: white;
		padding: map-get($spacing, tiny) map-get($spacing, tiny);
		display: block;
		position: absolute;
		@include pfont-bold;
		z-index: 2;
		left: 0;
		width: 31px;
		overflow:hidden;
		top: 50%;
		transform: translateY(-50%);
	}

	&__flag-text {
		display: inline-block;
		writing-mode: vertical-rl;
		writing-mode: tb-lr;
		transform: scale(-1);
		text-transform: uppercase;
		font-size: 0.8em;
		letter-spacing: 2px;
		color: map-get($brand-colors, gray-med);
	}	

	&__status-flag {
		color: white;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		font-size: x-small;
		text-transform: uppercase;
		font-weight: bold;

		&:before {
			position: absolute;
			top: 0;
			right: 0;
			content: "";
			width: 0;
			height: 0;
			overflow: hidden;
			border: 42px solid transparent;
			border-right-color: map-get($brand-colors, gray-dark);
			border-top-color: map-get($brand-colors, gray-dark);
		}

		span {
			display: block;
			position: absolute;
			top: 30px;
			right: -7px;
			color: white;
			transform-origin: 50% 50%;
			transform: rotateZ(45deg);
			line-height: .9em;
		}

		&--coming-soon {
			&:before {
				border-right-color: map-get($brand-colors, blue-gray);
				border-top-color: map-get($brand-colors, blue-gray);
			}

			span {
				top: 20px;
				right: 6px;
			}
		}

		&--new {
			&:before {
				border-right-color: map-get($brand-colors, red-dark);
				border-top-color: map-get($brand-colors, red-dark);
			}

			span {
				top: 25px;
				right: 12px;
			}
		}
	}

	&__desc {
		@include pfont-bold;
		font-size: 1em;
	}

	&__title {
		@include responsive-type($h2-map);
		color: white;
		@include pfont-bold;
		line-height: 1.1;
	}

	&__finishes {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		//max-width: 100%;
		margin: 0 auto;
		text-align: center;
		opacity: 0;
		padding: map-get($spacing, small) map-get($spacing, medium);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		transition: 0.25s ease;
		z-index: 1;
		flex-direction: column;

		@media #{$sm-up}{
			flex-direction: row;
			flex-wrap: wrap;
		}

		&:before {
			position: absolute;
			content: '';
			left: 0;
			right: 0;
			width: 100%;
			height: calc(100% + 20px);
			z-index: 0;
			margin-top: -10px;
			@include gradient-vertical(rgba(map-get($brand-colors, black), 0), rgba(map-get($brand-colors, black), 0.5), 0, 100%);
		}
	}

	&__finishes-text {
		flex: 0 0 auto;
		margin: 0 map-get($spacing, tiny);
		@include pfont-bold;
		color: white;
		z-index: 1;

		@media #{$xs-only} {
			flex: 0 0 100%;
		}
	}

	&__finishes-images {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;
		z-index: 1;
		max-width: 100%;
	}

	&__finishes-img {
		flex: 0 0 auto;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 2px solid white;
		margin: 0 map-get($spacing, tiny);
	}

	&--pro-grade {
		.collection-feature__content {
			padding: map-get($spacing, medium) 0 0 map-get($spacing, huge);
		}
	}

	&__sc {
		&:hover {
			border: 4px solid #e24747;			
		}
		.collection-feature__content {
			position: absolute;
			z-index: 2;
			 left: 0; 
			bottom: 0;
			 right: 0; 
			padding: 24px 0 0 24px;
		}					
	}			
}
