.table--compare {
	//shared table matrix styles
	thead tr th {
		text-align: center;
		background: white;
		padding: map-get($spacing, small);
		font-weight: bold;
	}

	tbody tr {
		td {
			text-align: center;
			background: map-get($brand-colors, gray-lighter);
			min-height: 40px;
			transition: all $transition-med ease;

			@media #{$xs-only}{
				border: 2px solid white;

				&:hover {
					background: map-get($brand-colors, tan);
				}
			}

			@media #{$sm-up}{
				border-bottom: 3px solid map-get($brand-colors, white);
				padding: map-get($spacing, small);
			}

			strong {
				display: block;
				@include responsive-type($h4-map);
			}

			&:nth-child(even) {
				background: map-get($brand-colors, gray-lighter);
			}

			&:nth-child(odd) {
				background: map-get($brand-colors, gray-light);
			}


			&:nth-child(even), &:nth-child(odd) {
				@media #{$xs-only}{
					&:hover {
						background: map-get($brand-colors, tan);
					}
				}
			}


			&:first-child {
				background: white;
				text-align: left;
				font-weight: bold;
				img {
					width: 100%;
					height: auto;
					max-width: 60px;
				}
			}
		}

		@media #{$sm-up}{
			&:hover {
				td {
					background: map-get($brand-colors, tan);
				}
			}
		}
	}

	//style overrides for mobile versions
	&.table--mobile-friendly {

		@media #{$xs-only}{
			//shared overrides
			tbody tr td {
				text-align: left;
				padding-right: map-get($spacing, small);

				&:first-child {
					text-align: center;
					padding:  map-get($spacing, small) 0 0;

					&:after {
						display: none;
					}
				}

				&:before {
					text-align: left;
				}
			}
		}
	}
}
