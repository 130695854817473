@mixin category-list-fade($fade-color: #fff) {
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		display: block;
		height: 100%;
		width: 70px;
		@include gradient-horizontal(rgba($fade-color, 1), rgba($fade-color, 0), 70%, 100%);
		z-index: 2;

		@media #{$xs-only}{
			display: none;
		}
	}

	&.state-beginning:before {
		display: none;
	}

	&:after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		display: block;
		height: 100%;
		width: 70px;
		@include gradient-horizontal(rgba($fade-color, 0), rgba($fade-color, 1), 0%, 30%);
		z-index: 1;

		@media #{$xs-only}{
			display: none;
		}
	}

	&.state-end:after {
		display: none;
	}
}

.category-list {
	position: relative;
	@include category-list-fade;
	width: 100%;
	height: 100%;

	&__list {
		//transition: opacity $transition-fast ease;

		align-items: stretch;

		&--vertical-card-content {
			.category-list__card-content {
				flex-direction: column;
				text-align: center;
				justify-content: center;
				max-width: 100%;
			}
		}
	}

	&__item {
		padding: 0 1px;
		width: 100px;

		@media #{$sm-up} {
			width: 135px;
		}

		&.swiper-slide {
			height: initial;
		}
	}

	&__card {
		height: 100%;

		&--white {
			background: #fff;
			margin: map-get($spacing, tiny);
		}
	}

	&__card-link {
		display: flex;
		flex-direction: column;
		color: $text-color;
		width: 100%;
		height: 100%;
		padding: map-get($spacing, tiny);
		border: 2px solid transparent;
		border-radius: 8px;
		transition: border $transition-med ease;
		margin: 0;
		font-size: 1em;
		letter-spacing: normal;
		font-weight: normal;
		text-transform: none;
		max-width: none;
		cursor: pointer;
		align-items: center;

		@media #{$sm-up} {
			padding: map-get($spacing, small);
		}

		&:hover {
			border: 2px solid darken(map-get($brand-colors, gray-light), 4%);
			text-decoration: none;
			color: $text-color;
		}

		&.state-selected {
			border: 2px solid darken(map-get($brand-colors, gray-light), 4%);
			background: white;
			box-shadow: inset 0px 0px 41px -5px rgba(0,0,0,0.11);
		}

		&.state-disabled {
			opacity: 0.5;
			pointer-events: none;
			cursor: not-allowed;
			filter: saturate(0);
		}

		&--square {
			border-radius: 0;
		}
	}

	&__card-img-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: map-get($spacing, small);
		max-width: 100%;
		min-height: 82px;
	}

	&__card-img {
		max-height: 50px;
		max-width: 100%;

		@media #{$sm-up}{
			max-height: 60px;
		}

		/*
		@media #{$md-up}{
			max-height: 80px;
		}*/
	}

	&__card-icon {
		color: map-get($brand-colors, gray-light);
		font-size: 60px;
	}

	&__card-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		max-width: 100px;
		font-size: 0.7em;
		align-items: center;
		text-align: center;
		overflow: hidden;

		@media #{$sm-up} {
			max-width: 100%;
			margin-left: -10px;
			margin-right: -10px;
			font-size: 0.8em;
		}

		@media #{$md-up}{
			max-width: 100%;
		}

		@media #{$lg-up}{
			font-size: 0.9em;
		}

	}

	&__card-title {
		font-weight: bold;
		padding-right: map-get($spacing, tiny);
		transition: all $transition-med ease;
		line-height: 1.2;
		max-width: 100%;

		.category-list__list--vertical-card-content & {
			padding-right: 0;
		}
	}

	&__card-count {
		max-width: 100%;
		font-weight: normal;
	}

	&__wrapper {
		position: relative;
		overflow: hidden;
		width: 100%;
		transition: width $transition-med ease;
	}

	&__btn {
		width: 45px;
		background: transparent;
		border: none;
		font-size: 2em;
		z-index: 3;
		flex: 0 0 auto;
		display: block;

		&, &:hover, &:active, &:focus {
			outline: none;
		}

		&--left {
			position: absolute;
			left: 0;
			right: auto;
			top: 50%;
			transform: translateY(-50%);

			@media #{$xs-only} {
				left: -15px;
			}
		}

		&--right {
			position: absolute;
			right: 0;
			left: auto;
			top: 50%;
			transform: translateY(-50%);

			@media #{$xs-only} {
				right: -15px;
			}
		}
	}

	/*------- [Modifiers] -------*/

	&--centered {
		.category-list__list {
			justify-content: center;
		}
	}

	&--expanding-panel {
		@include category-list-fade(map-get($brand-colors, gray-light));
	}

	&--dark {
		@include category-list-fade(map-get($brand-colors, gray-dark));
		color: #fff;
	}

	&--child-categories-panel {
		@include category-list-fade();
	}

	&.state-arrow-left-hidden {
		&:before {
			display: none;
		}
		.category-list__btn--left {
			display: none;
		}
	}
	&.state-arrow-right-hidden {
		&:after {
			display: none;
		}
		.category-list__btn--right {
			display: none;
		}
	}
}
