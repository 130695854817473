/*================================================================================
[Sidebar]
*/
.sidebar {
	background: map-get($brand-colors, gray-lighter);
	padding-top: map-get($spacing, medium);
	padding-bottom: map-get($spacing, medium);
	font-size: 0.9375em;

	@media #{$sm-up} {
		background: transparent;
		padding-right: $grid-gutter-width * 1.5;
		padding-top: map-get($spacing, large);
		padding-bottom: map-get($spacing, large);

		&:before {
			content: '';
			z-index: 0;
			background: map-get($brand-colors, gray-lighter);
			bottom: 0;
			top: 0;
			left: 0;
			right: ($grid-gutter-width / 2);
			//right: 0;
			position: absolute;
			margin-left: calc(-50vw + 50%);
		}
	}

	> * {
		position: relative;
		z-index: 1;
	}

	&__item.sidebar__item {
		margin-bottom: 0.5em;
	}

	&__heading {
		text-transform: uppercase;
		font-size: 1.125em;
	}

	&__link {
		color: map-get($brand-colors, gray-cool);
		text-decoration: underline;

		&.state-active {
			color: $text-color;
			text-decoration: none;
			cursor: default;
			@include pfont-bold;
		}
	}
}
