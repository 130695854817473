.collection-carousel {
	&__container {
		width: 100%;
		max-width: 1500px;
		position: relative;
		margin: 0 auto;
		padding: 0 map-get($spacing, large) map-get($spacing, huge) map-get($spacing, large);

		@media #{$sm-up}{
			padding: 0 map-get($spacing, giant) map-get($spacing, huge) map-get($spacing, giant);
		}

		&--product-detail {
			.collection-carousel {
				background: map-get($brand-colors, true-white);
				border-bottom: 1px solid map-get($brand-colors, gray-light);
			}
			.collection-carousel__collection {
				background: map-get($brand-colors, true-white);
				border-top: 1px solid map-get($brand-colors, gray-light);
				border-bottom: 1px solid map-get($brand-colors, gray-light);
			}
		}
	}

	&__collection {
		//background: white;
		padding: map-get($spacing, tiny) map-get($spacing, medium);
		display: flex;
		flex-direction: column;
		//border: 1px solid map-get($brand-colors, gray-light);
		align-items: center;

		@media #{$xs-only}{
			justify-content: center;
			text-align: center;
		}

		@media #{$sm-up}{
			flex-direction: row;
			align-items: flex-top;
		}
	}

	&__collection-title {
		@include responsive-type($h3-map);
		@include pfont-regular;

		span {
			@include pfont-bold;
		}

	}

	&__collection-link {
		text-decoration: none;
		@include pfont-bold;
		display: flex;
		align-items: center;

		&:hover {
			text-decoration: none;
		}

		&:after {
			font-family: icomoon;
			content: "\e913";
		}

		@media #{$sm-up}{
			margin-left: auto;
		}
	}

	&__item {
		height: auto;

		.product-card__body {
			height: 100%;
		}
	}
}
