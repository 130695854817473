//
// Typography
// --------------------------------------------------

// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	color: $headings-color;

	small,
	.small {
		font-weight: normal;
		line-height: 1;
		color: $headings-small-color;
	}

	@media #{$xs-only} {
		overflow-wrap: break-word;
	}
}

h1, .h1,
h2, .h2,
h3, .h3 {
	//margin-top: $line-height-computed;
	//margin-bottom: ($line-height-computed / 2);
	margin-top: 0.75em;
	margin-bottom: 0.5em;
	letter-spacing: -0.01em;

	small,
	.small {
		font-size: 65%;
	}

	&:first-child {
		margin-top: 0.5em;
	}

	&.section-lead {
		margin-top: 0;

		&:first-child {
			margin-top: 0;
		}
	}
}

h4, .h4,
h5, .h5,
h6, .h6 {
	margin-top: ($line-height-computed / 2);
	margin-bottom: ($line-height-computed / 2);
	//margin-bottom: 0.75em;

	small,
	.small {
		font-size: 75%;
	}
}

h1, .h1 {
	@include responsive-type($h1-map);
	@include pfont-bold;
	line-height: 1.143; // 42 / 48

	&.jumbo {
		@include responsive-type($jumbo-h1-map);
		line-height: 1.091; // 66 / 72

	}
}

h2, .h2 {
	@include responsive-type($h2-map);
	//@include pfont-bold;
	@include pfont-light;
	line-height: 1.25; // 32 / 36
	margin-bottom: 0.66em;

	&:not([class]) {
		color: lighten(map-get($brand-colors, gray-dark), 10%);
	}

	&.bold {
		@include pfont-bold;
	}

	&.super {
		@include responsive-type($super-h2-map);
		line-height: 1.16; // 36 / 42
	}
}

h3, .h3 {
	@include responsive-type($h3-map);
	@include pfont-bold;
	line-height: 1.25; // 24 / 30

	&:not([class]) {
		color: map-get($brand-colors, red-dark);
	}
}

h4, .h4 {
	@include responsive-type($h4-map);
	@include pfont-bold;
	line-height: 1.333; // 18 / 24
}

h5, .h5 {
	@include responsive-type($h5-map);
	@include pfont-bold;
}

h6, .h6 {
	font-size: $font-size-h6;
	@include pfont-bold;
	text-transform: uppercase;
	letter-spacing: 0.075em;
	color: map-get($brand-colors, red-light);
}

// Body text
// -------------------------

p {
	margin: 0 0 $line-height-computed;
	line-height: 1.876; // 16 / 30
	letter-spacing: -0.005em;
}

.lead {
	margin-bottom: $line-height-computed;
	font-size: 1.125em;
	font-weight: 400;
	line-height: 1.666;
}

// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
small,
.small {
	font-size: floor((100% * $font-size-small / $font-size-base));
}

mark,
.mark {
	background-color: $state-warning-bg;
	padding: .2em;
}

.thin {
	font-weight: 100;
}

.extra-light {
	font-weight: 200;
}

.light {
	font-weight: 300;
}

strong, .strong {
	font-weight: bold;
}

// Alignment
.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-justify {
	text-align: justify;
}

.text-nowrap {
	white-space: nowrap;
}

// Line-height
.text-tight {
	line-height: 1.33;
}

// Transformation
.text-lowercase {
	text-transform: lowercase;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-capitalize {
	text-transform: capitalize;
}

// Contextual colors
.text-muted {
	color: $text-muted;
}

@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $state-success-text);

@include text-emphasis-variant('.text-info', $state-info-text);

@include text-emphasis-variant('.text-warning', $state-warning-text);

@include text-emphasis-variant('.text-danger', $state-danger-text);

// Contextual backgrounds
// For now we'll leave these alongside the text classes until v4 when we can
// safely shift things around (per SemVer rules).
.bg-primary {
	// Given the contrast here, this is the only class to have its color inverted
	// automatically.
	color: #fff;
}

@include bg-variant('.bg-primary', $brand-primary);

@include bg-variant('.bg-success', $state-success-bg);

@include bg-variant('.bg-info', $state-info-bg);

@include bg-variant('.bg-warning', $state-warning-bg);

@include bg-variant('.bg-danger', $state-danger-bg);

// Page header
// -------------------------

.page-header {
	padding-bottom: (($line-height-computed / 2) - 1);
	margin: ($line-height-computed * 2) 0 $line-height-computed;
	border-bottom: 1px solid $page-header-border-color;
}

// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
	margin-top: 0;
	margin-bottom: ($line-height-computed / 2);
	ul,
	ol {
		margin-bottom: 0;
	}
}

// List options

// [converter] extracted from `.list-unstyled` for libsass compatibility
@mixin list-unstyled {
	padding-left: 0;
	list-style: none;
}

// [converter] extracted as `@mixin list-unstyled` for libsass compatibility
.list-unstyled {
	@include list-unstyled;
}

.list-ordered {
	list-style: none;
	padding: 0;
	margin: 0 0 map-get($spacing, small) map-get($spacing, small);
	font-size: $font-size-base;

	> li {
		padding: 0 0 0 24px;
		margin-bottom: 1.2em;
		position: relative;
		counter-increment: step-counter;

		&:before {
			content: counter(step-counter)'.';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			@include pfont-bold;
		}
	}

	&--large {

	}

	&--toc {
		margin-left: 0;
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;

		> li {
			padding: 0 map-get($spacing, medium) map-get($spacing, medium) 0;
			border-bottom: 1px solid map-get($spacing, gray-light);
			flex: 1 1 50%;
			max-width: 50%;
		}
	}
}


.list-bulleted {
	list-style: none;
	padding: 0;
	margin: 0 0 map-get($spacing, small) map-get($spacing, small);
	font-size: $font-size-base * 0.9;

	> li {
		padding: 0 0 0 15px;
		margin-bottom: 0.4em;
		position: relative;

		&:before {
			content: " ";
			display: block;
			width: 5px;
			height: 5px;
			background: map-get($brand-colors, red-light);
			position: absolute;
			left: 0;
			top: 7px;
		}
	}

	//a:not([class]) {
	//	color: $text-color;
	//}
}

// Menu-style list: extra padding between items
.list-menu {
	@include list-unstyled;

	> li {
		margin-bottom: 0.25em;
	}

	a:not([class]) {
		color: $text-color;
	}

	&__heading {
		font-weight: bold;

		a, a:not([class]) {
			&, &:hover, &:active, &:focus {
				color: map-get($brand-colors, blue-gray);
			}
		}
	}

	/*------- [Variant] -------*/
	&--compact {
		> li {
			margin-bottom: 0;
		}
	}

	&--2-col {
		display: inline-grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 1em;

		.list-menu__heading {
			grid-column-start: 1;
			grid-column-end: 3;
		}
	}

	&.custom {
		li:not(:first-child) {
			.footer-nav__heading {
				&, &:hover, &:active, &:focus {
					color: map-get($brand-colors, gray-lighter);
					@include pfont-regular;
				}
			}
		}
	}
}

// Inline turns list items into inline-block
.list-inline {
	@include list-unstyled;
	margin-left: -0.5em;

	> li {
		display: inline-block;
		padding-left: 0.5em;
		padding-right: 0.5em;
	}
}

// Inline menu with dividers
.list-menu-inline {
	@include list-unstyled;
	margin-left: -0.5em;

	> li {
		display: inline-block;
		padding-left: 0.5em;

		&:before {
			content: ' | ';
			padding-right: 0.5em;
		}

		&:first-child:before, &.dl-first-in-line:before {
			content: '';
			padding-right: 0;
		}
	}

	&--black-links {
		> li {
			> a, > a:hover {
				color: $text-color;
			}
		}
	}

	&--search-results {
		> li {
			@include pfont-bold;
			color: map-get($brand-colors, gray-med);
			text-transform: uppercase;
			font-size: 0.7em;
			letter-spacing: 1px;

			@media #{$md-up}{
				font-size: 0.8em;
			}
		}
	}
}

// Description Lists
dl {
	margin-top: 0; // Remove browser default
	margin-bottom: $line-height-computed;
}

dt,
dd {
	line-height: $line-height-base;
}

dt {
	font-weight: bold;
}

dd {
	margin-left: 0; // Undo browser default
}

// Horizontal description lists
//
// Defaults to being stacked without any of the below styles applied, until the
// grid breakpoint is reached (default of ~768px).

.dl-horizontal {
	dd {
		@include clearfix; // Clear the floated `dt` if an empty `dd` is present
	}

	@media (min-width: $dl-horizontal-breakpoint) {
		dt {
			float: left;
			width: ($dl-horizontal-offset - 20);
			clear: left;
			text-align: right;
			@include text-overflow;
		}
		dd {
			margin-left: $dl-horizontal-offset;
		}
	}
}

// Misc
// -------------------------

// Abbreviations and acronyms
abbr[title],
	// Add data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257
abbr[data-original-title] {
	cursor: help;
	border-bottom: 1px dotted $abbr-border-color;
}

.initialism {
	font-size: 90%;
	@extend .text-uppercase;
}

// Blockquotes
blockquote {
	padding: ($line-height-computed / 2) $line-height-computed;
	margin: 0 0 $line-height-computed;
	font-size: $blockquote-font-size;
	border-left: 5px solid $blockquote-border-color;

	p,
	ul,
	ol {
		&:last-child {
			margin-bottom: 0;
		}
	}

	// Note: Deprecated small and .small as of v3.1.0
	// Context: https://github.com/twbs/bootstrap/issues/11660
	footer,
	small,
	.small {
		display: block;
		font-size: 80%; // back to default font-size
		line-height: $line-height-base;
		color: $blockquote-small-color;

		&:before {
			content: '\2014 \00A0'; // em dash, nbsp
		}
	}
}

// Opposite alignment of blockquote
//
// Heads up: `blockquote.pull-right` has been deprecated as of v3.1.0.
.blockquote-reverse,
blockquote.pull-right {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid $blockquote-border-color;
	border-left: 0;
	text-align: right;

	// Account for citation
	footer,
	small,
	.small {
		&:before {
			content: '';
		}
		&:after {
			content: '\00A0 \2014'; // nbsp, em dash
		}
	}
}

// Addresses
address {
	margin-bottom: $line-height-computed;
	font-style: normal;
	line-height: $line-height-base;
}

// Links
a {
	transition: color $transition-med ease;
}

.link-subtle {
	color: inherit;
	text-decoration: underline;
}

.link-alt {
	@include link-alt;
}

.link-reversed {
	&, &:hover, &:active, &:focus {
		color: map-get($brand-colors, gray-lighter);
	}

	.text-white & {
		text-decoration: underline;
	}
}

.link-underline {
	text-decoration: underline;
}

.link-with-icon {
	position: relative;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}

	[class^="icon-"] {
		 line-height: inherit;
		 position: relative;
		 top: 50%;
		 transform: translateY(-50%);
		 font-size: 84%;
	 }

	[class^=icon-chevron-] {
		transform: translateY(-45%);
	}

	&--flex {
		display: flex;
		align-items: center;

		[class^="icon-"] {
			line-height: inherit;
			position: relative;
			top: initial;
			transform: none;
			font-size: 84%;
		}
	}

	&--alt {
		color: map-get($brand-colors, gray-dark);
		@include pfont-bold;

		[class^="icon-"] {
			line-height: inherit;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			font-size: 84%;
			color: map-get($brand-colors, red-light);
			margin-left: map-get($spacing, tiny);
		}
		&:hover, &:active, &:focus {
			text-decoration: none;
			color: map-get($brand-colors, red-light);
			@include pfont-bold;
		}
	}
}

.link-reversed-subtle {
	&, &:hover, &:active, &:focus {
		color: rgba(map-get($brand-colors, gray-lighter), 0.75);
	}
}

/*================================================================================
[Kicker]
*/
.kicker {
	font-weight: bold;
	@include responsive-type($h5-map);
	text-transform: uppercase;
	letter-spacing: 0.075em;
	margin-bottom: 0.25em;
	color: map-get($brand-colors, red-light);
	display: inline-block;
	line-height: 1.1;

	&--box {
		color: white;
		background: map-get($brand-colors, red-dark);
		padding: map-get($spacing, tiny);
	}

	&--support-article {
		margin-top: map-get($spacing, large);
		margin-bottom: -(map-get($spacing, small));
		display: block;
	}
}

/*================================================================================
[Buttons]
*/
button {
	padding: 0; // Eliminate default iOS button padding
}
