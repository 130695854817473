.image-right {
	@media #{$xs-only}{
		max-width: 100%;
		display: block;
		margin: 0 auto;
		max-height: 350px;
	}
	@media #{$sm-up}{
		float: right;
		max-width: 50%;
		margin: 0 0 map-get($spacing, large) map-get($spacing, large);
	}

}
.image-left {
	@media #{$xs-only}{
		max-width: 100%;
		display: block;
		margin: 0 auto;
		max-height: 350px;
	}
	@media #{$sm-up}{
		float: left;
		max-width: 50%;
		margin: 0 map-get($spacing, large) map-get($spacing, large) 0;
	}
}
