.v-support-detail {
	&__troubleshooting-and-video {
		@media #{$sm-up}{
			display: flex;
			align-items: stretch;
		}
	}

	&__troubleshooting {
		@media #{$xs-only}{
			padding-bottom: map-get($brand-colors, medium);
			border-bottom: 1px solid map-get($brand-colors, gray-light);
			margin-bottom: map-get($brand-colors, medium);
		}

		@media #{$sm-up}{
			flex: 1 1 50%;
			max-width: 50%;
			border-right: 2px solid map-get($brand-colors, gray-light);
			padding-right: map-get($spacing, huge);
		}

		@media #{$md-up}{
			padding-right: map-get($spacing, massive);
		}
	}

	&__videos {
		@media #{$xs-only}{
			margin-bottom: map-get($brand-colors, medium);
		}

		@media #{$sm-up}{
			flex: 1 1 50%;
			max-width: 50%;
			padding-left: map-get($spacing, huge);
		}

		@media #{$md-up}{
			padding-left: map-get($spacing, massive);
		}
	}

	&__hero-intro {
		@media #{$sm-up} {
			display: flex;
			align-items: center;
			min-height: 300px;
		}
	}

	&__hero-intro-media {
		text-align: center;
		@media #{$sm-up}{
			flex: 1 1 25%;
			max-width: 25%;
		}
	}

	&__hero-intro-img {
		margin: 0 auto;
		display: block;
		max-width: 100%;
		height: auto;
		max-height: 250px;

		@media #{$sm-up}{
			max-height: 400px;
		}
	}

	&__hero-intro-content {
		@media #{$sm-up}{
			flex: 1 1 75%;
			max-width: 75%;
			padding-left: map-get($spacing, large);
		}
	}

	&__hero-intro-heading {
		padding-bottom: map-get($spacing, small);
		border-bottom: 2px solid map-get($brand-colors, gray-light);

		@media #{$sm-up}{
			padding-bottom: map-get($spacing, medium);
		}
	}

	&__model {
		@media #{$sm-up}{
			display: flex;
			max-width: 100%;
			align-items: center;
		}
	}

	&__model-label {
		font-size: 1em;
		@include pfont-bold;

		@media #{$sm-up}{
			font-size: 0.7em;
			flex: 1 1 25%;
			max-width: 25%;
			padding-right: map-get($spacing, tiny);
		}

		@media #{$md-up}{
			flex: 1 1 20%;
			max-width: 20%;
		}
	}

	&__model-form {
		@media #{$sm-up}{
			flex: 0 0 40%;
		}
	}

	&__model-link {
		flex: 1 1 auto;
		font-size: 0.7em;
		display: block;

		@media #{$xs-only}{
			margin: map-get($spacing, tiny) 0 map-get($spacing, large) 0;
		}

		@media #{$sm-up}{
			padding-left: map-get($spacing, small);
		}
	}
}
