@import "mixins/responsive-type";
@import "mixins/multi-line-ellipsis";

@mixin link-alt {
	color: map-get($brand-colors, blue-gray);

	&:hover, &:active, &:focus {
		color: darken(map-get($brand-colors, blue-gray), 10%);
	}
}

@mixin mobile-full-screen-panel {
	display: flex;
	position: fixed;
	overflow: hidden;
	top: 86px;
	left: 0;
	width: 100%;
	height: 0;
	opacity: 0;
	background: map-get($brand-colors, gray-lighter);
	flex-direction: column;
	-webkit-overflow-scrolling: touch;
	z-index: $zindex-popover + 1;
	transition: height $transition-fast ease, opacity $transition-med ease;

	&.state-visible {
		overflow: auto;
		height: calc(100vh - 61px);
		opacity: 1;
	}
}

@mixin content-wrapper {
	max-width: $content-wrapper-width;
	margin: 0 auto;
	padding: 25px;// map-get($spacing, medium);
	position: relative;

	@media (min-width: $content-wrapper-width) {
		padding: 0;
	}
}
