html, body {
	background: #fff;
	//font-size: calc(12px + 0.25vw);
	//overflow-x: hidden;
	//position: relative;
	//-webkit-overflow-scrolling: touch;
	//height: 100%;
	@include pfont-regular;
	@include responsive-type($base-map);
	color: map-get($brand-colors, gray-dark);
	-ms-overflow-style: scrollbar;
}

body {
	overflow-x: hidden !important;

	&.state-nav-open, &.state-quick-search-open {
		touch-action: none;

		@media #{$xs-only} {
			position: fixed;
		}

		/*.site-main {
			@media #{$sm-up} {
				&:after {
					content: '';
					position: absolute;
					top: 107px;
					left: 0;
					bottom: 0;
					right: 0;
					pointer-events: none;
					background-color: rgba(#000, 0.33);
					z-index: 1;
				}
			}
		}*/
	}
}

.site-wrapper {
	//overflow-x: hidden !important;
	min-height: 100vh;
	max-width: 100vw !important;
	margin: 0 auto;
	background: #fff;
	display: flex;
	flex-direction: column;
	position: relative;

	.sfPageContainer & {
	  position: relative;
	}
}

// Align with header and footer
.content-wrapper {
	@include content-wrapper;
}

/*================================================================================
[Site header]
*/

.site-header {
	@media #{$xs-only} {
		height: 61px;
	}

	@media #{$sm-up} {
		margin-top: 30px;

		.ie11 & {
			height: 76px;
		}
	}

	&__inner {
		display: flex;
		align-items: center;
		padding: 12px 0;
		max-width: 1600px;
		//position: relative;
		margin: 0 auto;

		@media #{$xs-only} {
			border-top: 6px solid map-get($brand-colors, gray-dark);
		}

		@media #{$sm-up} {
			padding: 0;
		}
	}

	&__logo {
		order: 2;
		//background: map-get($brand-colors, red-dark);
		background: #fff;
		align-self: center;
		margin: -4px auto;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;

		@media #{$sm-up} {
			order: 1;
			position: static;
			transform: none;
			align-self: flex-start;
			margin: 0 0 0 15px;
			//z-index: $zindex-modal-background + 1; // Put this above the load indicator background

			.ie11 & {
				margin: -1px 0 0 15px;
			}
		}

		@media (min-width: 1600px) {
			margin-left: 0;
		}

		img {
			width: 110px;
			height: auto !important;
		}
	}

	&__logo-link {
		display: block;
		padding: 18px;

		@media #{$sm-up} {
			padding: 24px;
		}
	}

	&__nav-toggle {
		background: none;
		border: none;
		padding: 0;
		margin-top: -5px;
		margin-left: 18px;
		text-transform: uppercase;
		font-size: 0.75rem;
		letter-spacing: -0.02em;
		display: flex;
		align-items: center;
		line-height: 1;
		vertical-align: center;
		position: absolute;
		top: 28px;

		[class^=icon] {
			font-size: 1.75rem;
			line-height: 1;
		}

		&, &:hover, &:active, &:focus {
			outline: none;
		}

		// Change the "Menu" icon to a red Close icon when the menu is open
		.state-nav-open & [class^=icon] {
			color: #000;
			font-size: 1.25rem;
			&:before {
				content: '\e90e'; // .icon-close
				color: map-get($brand-colors, red-light);
			}
		}

		@media #{$sm-up} {
			display: none;
		}

		&--reversed {
			z-index: $zindex-high;

			[clasS^="icon-"] {
				color: map-get($brand-colors, white);
			}

			.site-header__nav-menu-label {
				color: map-get($brand-colors, white);
			}
		}
	}

	&__nav-menu-label {
		.state-nav-open & {
			opacity: 0;
		}
	}

	&__nav {
		order: 1;
		z-index: $zindex-medium;

		@media #{$sm-up} {
			order: 2;
			/*display: flex;*/
			align-self: stretch;
		}
	}

	&__search-box {
		&.site-header__search-box { // Specificity :(
			position: absolute;
		}
		width: 100%;
		max-width: 1600px;
		top: 0;

	}

	&__search {
		@media #{$sm-up} {
			z-index: $zindex-medium + 1;

			&.state-zlow {
				z-index: $zindex-medium - 1;
			}
		}
	}

	&__location-switcher {
		@media #{$sm-up} {
			&:hover, &:active, &:focus {
				color: #fff;
			}
		}
	}

	&__location-list {
		@media #{$xs-only} {
			padding-top: 0;
		}
	}
}

/*================================================================================
[Site main]
*/
.site-main {
	line-height: 1.5;
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;
	transition: background-color $transition-fast ease;

	&:after {
		transition: background-color $transition-fast ease;
	}
}

/*================================================================================
[Site footer]
*/
.site-footer {
	background: map-get($brand-colors, gray-dark);
	color: #fff;
	margin-top: auto;

	&__signup-form {
		background: map-get($brand-colors, gray-light);
		padding: map-get($spacing, medium) 0;
		color: $text-color;
	}

	&__logo {
		img {
			max-width: 160px;
		}
	}
}

/*================================================================================
[Mastfoot]
*/
.mastfoot {
	padding-top: map-get($spacing, large);
}

/*================================================================================
[balancetext plugin]
*/
.balance-text {
	text-wrap: balance;  /* Apply (proposed) CSS style */
}

/*================================================================================
[position: sticky; polyfill clearfix]
*/
.sticky, .js-sticky {
	position: sticky;
	top: -1px;
	z-index: $zindex-low;

	&:after {
		content: '';
		display: table;
	}

	&--bottom {
		top: auto;
		bottom: 0;
	}

	&--white-bg {
		background: rgba(#fff, 0.9);
		//box-shadow: 0 -2px 8px rgba(#000, 0.1);
	}

	&.state-stuck {
		position: fixed;
		left: 0;
		right: 0;
	}
}

.container-wide {
	max-width: 1630px;
	margin: 0 auto;
	width: 100%;
	padding: 0 15px;

	.main-nav & {
		margin: 0;
	}
}

.block {
	display: block;
}

.inline {
	display: inline;
}

.inline-block {
	display: inline-block;
}

/*================================================================================
[JIRA collector widget]
*/
.atlwdg-trigger.atlwdg-RIGHT {
	z-index: 9999;
}

.atlwdg-popup {
	max-width: 100% !important;
}

.text-small {
	font-size: 0.8em;
}
/*================================================================================
[File Input Close]
*/
.file-input-close {
	color: map-get($brand-colors, red-light);
	margin-left: map-get($spacing, tiny);
	position: relative;
	top: 2px;
	font-weight: bold;
	cursor: pointer;
}

@media all and (min-width: 480px) {
    .deskContent {display:block;}
    .phoneContent {display:none;}
}

@media all and (min-width: 768px) {
    .deskContent {display:block;}
    .phoneContent {display:none;}
}

@media all and (max-width: 767.9px) {
    .deskContent {display:none;}
    .phoneContent {display:block;}
	.phonemenu-width{width:100%}
}

@media all and (max-width: 479px) {
    .deskContent {display:none;}
    .phoneContent {display:block;}
	.phonemenu-width{width:100%}
}

@media (min-width: 768px) {
    .main-nav {
        margin: 0px 0px 0px 98px !important;
    }
}

.content-wrapper {
    padding: 0px !important;
}

.breadcrumbs {
    margin: 24px 0 24px 24px !important;
}

.quick-search-box__input {
    max-width: calc(100% - 220px) !important;
}


/*Make the Logo on Desktop a Little Bigger*/
.site-header__logo img {
    width: 125px !important;
}

/*Mobile*/
@media (max-width: 767.9px) {
    .site-header__logo-link {
        padding: 0px !important;
        padding-top: 1px;
    }

    .quick-search-box__input {
        max-width: calc(100% - 36px) !important;
    }
}

/* Update in the Template */
.site-header__logo.phoneContent img {
    content: url(//images.pfisterfaucets.com/is/image/Pfister/pfister%2Dlogo%2Dnew?qlt=100&fmt=png-alpha);
    margin-top: 7px;
}

//sharethis styling
html body .sharethis-inline-share-buttons {

	&.st-animated {
		transition: none;
		margin-top: 2px;
		z-index:100 !important;
	}
}

body #st-1 .st-btn {
	border-radius: 0;
	border: 0;
	cursor: pointer;
	display: inline-flex !important;
	font-style: normal;
    font-size: 0.875em;
	font-weight:bold;
	@include pfont-regular;
	align-items: center;
	height: auto;
	line-height: 1;
	margin-right: 0;
	padding: 0;
	position: relative;
	text-align: center;
	transition: none;
	top: 0;
	vertical-align: initial;
	white-space: nowrap;
}

body .pd #st-1 .st-btn {
	font-size: 13.5px;
	font-weight: 400;
	@include pfont-regular;
}


body #st-1 .st-btn[data-network='sharethis'] > span {
	color:  #000;;
	transition: none;
}

body #st-1 .st-btn > span {
	transition: none;
	color: #fff;
	display: inline-block;
	font-weight: bold;
	letter-spacing: 0.5px;
	min-width: 60px;
	opacity: 1;
	padding: 0 6px;
	position: relative;
	vertical-align: top;	
}

body .pd #st-1 .st-btn > span {
	font-weight: 400;
	margin-left: -3px;
}

body #st-1 .st-btn > img {
	display: inline-block;
	height: 16px;
	width: 16px;
	position: relative;
	top: initial;
}

body .pd #st-1 .st-btn > img {
	height: 13px;
	width: 13px;
}

body #st-1 .st-btn:hover {
	opacity: 1;
	top: initial;
}

body #st-1.st-has-labels .st-btn {
	min-width: initial;
}
//#st-2
body #st-2 .st-btn {
	border-radius: 0;
	border: 0;
	cursor: pointer;
	display: inline-flex !important;
	font-style: normal;
    font-size: 0.875em;
	font-weight:bold;
	@include pfont-regular;
	align-items: center;
	height: auto;
	line-height: 1;
	margin-right: 0;
	padding: 0;
	position: relative;
	text-align: center;
	transition: none;
	top: 0;
	vertical-align: initial;
	white-space: nowrap;
}

body .pd #st-2 .st-btn {
	font-size: 13.5px;
	font-weight: 400;
	@include pfont-regular;
}


body #st-2 .st-btn[data-network='sharethis'] > span {
	color:  #000;;
	transition: none;
}

body #st-2 .st-btn > span {
	transition: none;
	color: #fff;
	display: inline-block;
	font-weight: bold;
	letter-spacing: 0.5px;
	min-width: 60px;
	opacity: 1;
	padding: 0 6px;
	position: relative;
	vertical-align: top;	
}

body .pd #st-2 .st-btn > span {
	font-weight: 400;
	margin-left: -3px;
}

body #st-2 .st-btn > img {
	display: inline-block;
	height: 16px;
	width: 16px;
	position: relative;
	top: initial;
}

body .pd #st-2 .st-btn > img {
	height: 13px;
	width: 13px;
}

body #st-2 .st-btn:hover {
	opacity: 1;
	top: initial;
}

body #st-2.st-has-labels .st-btn {
	min-width: initial;
}

