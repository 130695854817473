.product-filtering {
	@media #{$sm-up} {
		display: flex;
		flex-wrap: nowrap;
	}

	&__product-filtering__filter {
		flex: 0 0 0;
		transition: all 0.25s ease-in-out;
	}

	&__product-filtering__grid {
		flex: 1 1 0;
		transition: all 0.25s ease-in-out;
	}

	&__trigger {
		text-decoration: none;
		@include pfont-bold;
		font-size: 21px;
		display: block;
		height: 100%;
		color: map-get($brand-colors, gray-dark);
		position: relative;
		width: 50px;
		border-left: 1px solid map-get($brand-colors, gray-light);
		transition: all 0.25s ease-in-out;

		&:hover, &:focus {
			text-decoration: none;
			color: map-get($brand-colors, red-light);
		}


		span {
			display: block;
			white-space: nowrap;
			transform: rotate(90deg);
			transform-origin: top left;
			position: relative;
			top: 0;
			left:43px;

			&:before {
				content: "\e914";
				position: relative;
				top:3px;
				font-family: icomoon;
				color: map-get($brand-colors, red-light);
				display: inline-block;
				margin-right: map-get($spacing, small);
			}
		}
	}
}