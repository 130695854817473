.table {
	width: 100%;

	th, td {
		padding: map-get($spacing,small) map-get($spacing,tiny);
		font-size: $font-size-base * 0.85;
		line-height: 1.25;
	}

	th {
		text-align: left;
	}

	td {
		vertical-align: middle;
	}

	&__sortable-header {
		cursor: pointer;
		white-space: nowrap;
	}

	&__sort-indicator {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		color: map-get($brand-colors, gray-cool);

		[class^=icon-] {
			line-height: 0;
			padding: 0.015em 0.1em;
		}

		&.state-asc {
			i.icon-sort-asc {
				color: map-get($brand-colors, red-light);
			}
		}

		&.state-desc {
			i.icon-sort-desc {
				color: map-get($brand-colors, red-light);
			}
		}
	}

	&--bordered {
		tbody {
			tr {
				border-bottom: 2px solid map-get($brand-colors, gray-light);
			}
		}
		thead {
			tr {
				border-top: 2px solid map-get($brand-colors, gray-light);
				border-bottom: 2px solid map-get($brand-colors, gray-light);
			}
		}

	}

	&--striped {
		tr {
			&:nth-child(odd) {
				td {
					background: map-get($brand-colors, gray-lighter);
				}
			}
		}
	}

	&--mobile-friendly {
		@media #{$xs-only} {
			display: block;
			/* Force table to not be like tables anymore */
			thead, tbody, tfoot, th, td, tr {
				display: block;
			}

			/* Hide table headers (but not display: none;, for accessibility) */
			thead tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}

			tr {
				border: 1px solid map-get($brand-colors, gray-light);
			}

			td {
				/* Behave  like a "row" */
				border: 0;
				position: relative;
				padding-left: 30%;
				text-align: left;
				width: auto;
				line-height: 1.3;
				font-size: 0.9em;

				&:before {
					/* Now like a table header */
					position: absolute;
					/* Top/left values mimic padding */
					top: 50%;
					left: 10px;
					width: 25%;
					padding-right: 10px;
					white-space: wrap;
					content: attr(data-column);
					color: map-get($brand-colors, gray-dark);
					font-weight: bold;
					transform: translateY(-50%);
					overflow-wrap: break-word;
					word-wrap: break-word;
					word-break: break-word;
					hyphens: auto;
				}

				&:after {
					content: "";
					display: block;
					height: 1px;
					border-bottom: 1px solid darken(map-get($brand-colors, gray-light), 1%);
					left: 10px;
					right: 10px;
					position: absolute;
					top: 100%;
					z-index:10;
				}

				&:last-child {
					&:after {
						border-bottom: 0;
					}
				}

				&.no-label {
					padding-left: 10px;
				}
			}
		}

		&.table--mobile-friendly-aligned-top {
			td {
				@media #{$xs-only} {
					&:before {
						top: 20px;
						content: attr(data-column);
					}
				}
			}
		}
	}

	&--layout-fixed {
		table-layout: fixed;
	}
}


.table-responsive {
	overflow-x: auto;
}
