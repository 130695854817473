.list-large-icon {
	@include list-unstyled;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;

	&__item {
		display: flex;
		align-items: center;
		margin: map-get($spacing, tiny) 0;

		&:first-child {
			margin-top: 0;
		}
	}

	&__icon-wrapper {
		flex: 0 0 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__icon {
		display: block;
		max-width: 40px;
		max-height: 40px;
	}

	&__text {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		text-decoration: none;
		color: map-get($brand-colors, gray-dark);

		&:hover {
			text-decoration: none;
			color: map-get($brand-colors, gray-dark);
		}

	}
}
