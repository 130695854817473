pff-product-spec-card {
	/*.state-reordering & {
		pointer-events: none;
	}*/
}

.product-spec-card {
	border: 1px solid map-get($brand-colors, gray-light);
	background: map-get($brand-colors, gray-lighter);
	position: relative;

	&__image {
		display: block;
		width: auto;
		max-width: 240px;
		//height: 28vw;
		max-height: 240px;
		object-fit: contain;
		margin: map-get($spacing, medium) auto 0;
		transition: transform $transition-med ease;
		//min-height: 25vh;

		@media #{$sm-up} {
			height: 16vw;
		}
	}

	&__controls {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}

	&__control {
		position: absolute;
		background: #fff;
		padding: 0.5em;
		border: none;
		border-radius: 0;
		font-size: 1.33em;

		&--move {
			left: 0;
			pointer-events: none;
		}

		&--delete {
			right: 0;
		}
	}

	&__family {
		display: block;
		text-transform: uppercase;
		@include responsive-type($h3-map);
		@include pfont-bold;
	}

	&__model {
		font-size: 0.7em;
		font-weight: bold;
		padding-top: map-get($spacing, tiny);
	}

	&__description {
		text-decoration: none;
		margin-bottom: 4px;
		font-size: $font-size-base * 0.8;
	}

	&__related-products {
		margin-top: map-get($spacing, medium);
		font-size: 0.9em;
	}

	&__body, &__footer {
		padding: map-get($spacing, medium);
	}

	&__footer {
		padding-bottom: map-get($spacing, large);
		border-top: 1px solid map-get($brand-colors, gray-light);
		background: #fff;
	}
}