//
// Forms
// --------------------------------------------------

// Normalize non-controls
//
// Restyle and baseline non-control form elements.

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
	// Chrome and Firefox set a `min-width: min-content;` on fieldsets,
	// so we reset that to ensure it behaves more like a standard block element.
	// See https://github.com/twbs/bootstrap/issues/12359.
	min-width: 0;
}

legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: $line-height-computed;
	font-size: ($font-size-base * 1.5);
	line-height: inherit;
	color: $legend-color;
	border: 0;
	border-bottom: 1px solid $legend-border-color;
}

label, .faux-label {
	display: inline-block;
	max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
	margin-bottom: 5px;
	font-weight: bold;
	font-size: 0.8125em;
	text-transform: uppercase;
	margin-left: map-get($spacing, tiny);
	letter-spacing: 0.05em;

	&.label--light,
	> .label--light {
		font-weight: normal;
	}

	&.label--normal,
	> .label--normal {
		text-transform: none;
		letter-spacing: 0.001em;
	}
}

// Normalize form controls
//
// While most of our form styles require extra classes, some basic normalization
// is required to ensure optimum display with or without those classes to better
// address browser inconsistencies.

// Override content-box in Normalize (* isn't specific enough)
input[type="search"] {
	@include box-sizing(border-box);
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9
; // IE8-9
	line-height: normal;
}

// File input

.file-input {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&_case-attach
	{
		border-color: #c5c5c5;
        border-style: solid;
        padding: 0.8em;
	}

	input[type="file"] {
		display: block;
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	label {
		margin-right: 1em;
	}

	&__value {
		margin: 0.5em 0;
		font-size: 0.8125em;
		line-height: 1.25;
		word-break: break-word;
	}
}


// Make range inputs behave like textual form controls
input[type="range"] {
	display: block;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
	height: auto;
}

// Focus for file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	@include tab-focus;
}

// Adjust output element
output {
	display: block;
	padding-top: ($padding-base-vertical + 1);
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $input-color;
}

// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]

.form-control {
	display: block;
	width: 100%;
	 // Make inputs at least the height of their button counterpart (base line-height + padding + border)
	padding: 0 16px;
	font-size: $font-size-base * 0.75;
	line-height: 46px;
	height: 46px;
	color: $input-color;
	@include pfont-regular;
	font-weight: 500;
	background-color: $input-bg;
	background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	border: 1.75px solid map-get($brand-colors, gray-light);
	border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
	@include transition(border-color ease-in-out .15s);

	// Customize the `:focus` state to imitate native WebKit styles.
	@include form-control-focus;

	// Placeholder
	@include placeholder;

	// Unstyle the caret on `<select>`s in IE10+.
	&::-ms-expand {
		border: 0;
		background-color: transparent;
	}

	@media #{$xs-only} {
		.coarsepointer & {
			// Stop iOS from zooming in when a form input is focused
			font-size: 16px;
		}
	}

	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&[disabled],
	fieldset[disabled] & {
		background-color: $input-bg-disabled;
		opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
	}

	&[readonly] {
		opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
		pointer-events: none;
	}

	&[disabled],
	fieldset[disabled] & {
		cursor: $cursor-disabled;
	}

	// [converter] extracted textarea& to textarea.form-control
}

// Reset height for `textarea`s
textarea.form-control {
	height: auto;
	min-height: 120px;
	padding-top: map-get($spacing, small);
	padding-bottom: map-get($spacing, small);
	line-height: 1.5;
}

// Date overrides
input[type="date"] {
	&.form-control {
		appearance: none;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAQdJREFUOI3l1LFKw0AYwPFfSguCq51qwMHFTRysg+Bm8whu4uDqU/gWndx8hMPJQpc+hWJ1qmuhg4hDr3gkrRjIIPiHkO8+7vtfcsl9NEwGL4NBOb+HD7xuqNtFG8+Qh7CUZZnWhoInjH94kHGcU6GdxFs4wyzJnWKnVPOexEfoTotilIewKAsf0cddkruNi6SMkvgGl5jgJBX2ogyOo/QT52veah8PaMW50J8WRQ9vK2EnKTiI1yZ6uFqT74irNErjwnZpPMFFTce97/2vCBfiz1qDRTr4+3v4D4WrrzyP90PLM12Hw9SxEs4wxLVqM/gNwzyEmSyrNNgutmvK5nkIM5YNtnG+AJ+CLu91U0G3AAAAAElFTkSuQmCC);
		background-repeat: no-repeat;
		background-position: calc(100% - 16px) 50%;
		background-size: 16px 16px;

		&::-webkit-inner-spin-button {
			display: none;
			//outline: 1px solid red;
		}

		&::-webkit-calendar-picker-indicator {
			//display: none;
			margin-right: 24px;
		}

		&::-webkit-clear-button {
			margin-right: 8px;
			position: relative;
			//top: -1px;
		}

		&::moz-clear-button {
			margin-right: 12px;
		}
	}
}

// Search inputs in iOS
//
// This overrides the extra rounded corners on search inputs in iOS so that our
// `.form-control` class can properly style them. Note that this cannot simply
// be added to `.form-control` as it's not specific enough. For details, see
// https://github.com/twbs/bootstrap/issues/11586.

input[type="search"] {
	-webkit-appearance: none;
}

// Special styles for iOS temporal inputs
//
// In Mobile Safari, setting `display: block` on temporal inputs causes the
// text within the input to become vertically misaligned. As a workaround, we
// set a pixel line-height that matches the given height of the input, but only
// for Safari. See https://bugs.webkit.org/show_bug.cgi?id=139848
//
// Note that as of 9.3, iOS doesn't support `week`.

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="date"],
	input[type="time"],
	input[type="datetime-local"],
	input[type="month"] {
		&.form-control {
			line-height: $input-height-base;
		}

		&.input-sm,
		.input-group-sm & {
			line-height: $input-height-small;
		}

		&.input-lg,
		.input-group-lg & {
			line-height: $input-height-large;
		}
	}
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
	margin-bottom: $form-group-margin-bottom;
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.radio,
.checkbox {
	position: relative;
	display: block;
	margin-top: map-get($spacing, small);
	margin-bottom: map-get($spacing, small);

	label, .faux-label {
		margin-right: 1em;
		margin-bottom: 0;
	}

	label {
		font-weight: normal;
		text-transform: none;
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		letter-spacing: 0;

		&:last-child {
			margin-right: 0;
		}

		&.disabled {
			opacity: 0.66;
			cursor: not-allowed;
		}
	}

	input {
		position: relative;
		flex: 0 0 auto;
	}

	&__text {
		font-size: $font-size-base * 0.80;
		padding-left: 8px;
		flex: 1 1 auto;
	}

	&--compact {
		margin-top: map-get($spacing, tiny);
		margin-bottom: map-get($spacing, tiny);
	}

	&--image {
		label {
			align-items: flex-start;
			margin-left: 0;

			// Specificity :(
			input[type="radio"], input[type="checkbox"] {
				margin-right: map-get($spacing, tiny);
			}
		}
	}
}

.checkbox input[type="checkbox"] {
	border: 1.75px solid map-get($brand-colors, gray-cool);
	appearance: none;
	box-shadow: none;
	border-radius: 2px;
	background: white;
	margin: 0;
	padding: 0;
	width: 16px;
	height: 16px;
	position: relative;
	outline: 0;
	line-height: 16px;

	&:checked {
		//border-color: map-get($brand-colors, red-light);
		outline: 0;
		width: 16px;
		height: 16px;
		box-shadow: none;

		&:before {
			content: "\e91b";
			font-family: icomoon;
			color: map-get($brand-colors, red-light);
			position: absolute;
			top: -1px;
			//left: -1px;
			display: block;
			font-size: 12px;
			outline: 0;
			border: 0;
			cursor: pointer;
		}
	}

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

.radio input[type="radio"] {
	border: 1.75px solid map-get($brand-colors, gray-cool);
	appearance: none;
	box-shadow: none;
	background: white;
	margin: 0;
	padding: 0;
	min-width: 16px;
	min-height: 16px;
	border-radius: 50%;
	flex: 0 0 auto;
	outline: 0;
	line-height: 16px;

	&:checked {
		//border-color: map-get($brand-colors, red-light);
		outline: 0;
		box-shadow: none;

		&:before {
			content: "";
			background: map-get($brand-colors, red-light);
			position: absolute;
			display: block;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			outline: 0;
			border: 0;
			border-radius: 50%;
			text-align: center;
			width: 8px;
			height: 8px;
			cursor: pointer;
		}
	}
}

.checkbox input[type="checkbox"]::-ms-check,
.radio input[type="radio"]::-ms-check {
	color: map-get($brand-colors, red-light);
}

.radio + .radio,
.checkbox + .checkbox {
	margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
	margin-top: 0;
	margin-left: 10px; // space out consecutive inline controls
}

// Apply same disabled cursor tweak as for inputs
// Some special care is needed because <label>s don't inherit their parent's `cursor`.
//
// Note: Neither radios nor checkboxes can be readonly.
input[type="radio"],
input[type="checkbox"] {
	&[disabled],
	&.disabled,
	fieldset[disabled] & {
		cursor: $cursor-disabled;
	}
}

// These classes are used directly on <label>s
.radio-inline,
.checkbox-inline {
	&.disabled,
	fieldset[disabled] & {
		cursor: $cursor-disabled;
	}
}

select {
	&.form-control {
		appearance: none;
		background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkRFNTdDRTI1MUNCRTExRThBRTQyRjk4NTUwQkI1RjQ2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkRFNTdDRTI2MUNCRTExRThBRTQyRjk4NTUwQkI1RjQ2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6REU1N0NFMjMxQ0JFMTFFOEFFNDJGOTg1NTBCQjVGNDYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6REU1N0NFMjQxQ0JFMTFFOEFFNDJGOTg1NTBCQjVGNDYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4zw1txAAAAoUlEQVR42mL8//8/AzUBEwOVwaiBg9BAFnSBxx4eCUDKH4gbZXfsuIBNE1CNAZCqB+KNQDULCLkQZGAAEO+HasRm2H6omgRivFwAxB+BWADdUCTDBKBqCtA1M2LLKVCNB4CYH4g/ALEjVArZMAdsQcKIK+thMZSBkGF4YxmqwQGIH0INAuGL+AzD60IklwpAXcoANewDPvWMo6XNCDAQIMAAUZJB7U/B0LkAAAAASUVORK5CYII=) 0 0 no-repeat;
		background-position: calc(100% - 20px) 50%;
		background-size: 16px 16px;
		padding-right: 35px;
	}

	&::-ms-expand {
		display: none;
	}
}

// These classes are used on elements with <label> descendants
.radio,
.checkbox {
	&.disabled,
	fieldset[disabled] & {
		label {
			cursor: $cursor-disabled;
		}
	}
}

// Static form control text
//
// Apply class to a `p` element to make any string of text align with labels in
// a horizontal form layout.

/*.form-control-static {
	// Size it appropriately next to real form controls
	padding-top: ($padding-base-vertical + 1);
	padding-bottom: ($padding-base-vertical + 1);
	// Remove default margin from `p`
	margin-bottom: 0;
	min-height: ($line-height-computed + $font-size-base);

	&.input-lg,
	&.input-sm {
		padding-left: 0;
		padding-right: 0;
	}
}*/

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.

@include input-size('.input-sm', $input-height-small, $padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $input-border-radius-small);
.form-group-sm {
	.form-control {
		height: $input-height-small;
		padding: $padding-small-vertical $padding-small-horizontal;
		font-size: $font-size-small;
		line-height: $line-height-small;
		border-radius: $input-border-radius-small;
	}
	select.form-control {
		height: $input-height-small;
		line-height: $input-height-small;
	}
	textarea.form-control,
	select[multiple].form-control {
		//height: auto;
	}
	.form-control-static {
		height: $input-height-small;
		min-height: ($line-height-computed + $font-size-small);
		padding: ($padding-small-vertical + 1) $padding-small-horizontal;
		font-size: $font-size-small;
		line-height: $line-height-small;
	}
}

@include input-size('.input-lg', $input-height-large, $padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $input-border-radius-large);
.form-group-lg {
	.form-control {
		height: $input-height-large;
		padding: $padding-large-vertical $padding-large-horizontal;
		font-size: $font-size-large;
		line-height: $line-height-large;
		border-radius: $input-border-radius-large;
	}
	select.form-control {
		height: $input-height-large;
		line-height: $input-height-large;
	}
	textarea.form-control,
	select[multiple].form-control {
		//height: auto;
	}
	.form-control-static {
		height: $input-height-large;
		min-height: ($line-height-computed + $font-size-large);
		padding: ($padding-large-vertical + 1) $padding-large-horizontal;
		font-size: $font-size-large;
		line-height: $line-height-large;
	}
}

// Form control feedback states
//
// Apply contextual and semantic states to individual form controls.

.has-feedback {
	// Enable absolute positioning
	position: relative;

	// Ensure icons don't overlap text
	.form-control {
		padding-right: ($input-height-base * 1.25);
	}
}

// Feedback icon (requires .glyphicon classes)
.form-control-feedback {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2; // Ensure icon is above input groups
	display: block;
	width: $input-height-base;
	height: $input-height-base;
	line-height: $input-height-base;
	text-align: center;
	pointer-events: none;
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
	width: $input-height-large;
	height: $input-height-large;
	line-height: $input-height-large;
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
	width: $input-height-small;
	height: $input-height-small;
	line-height: $input-height-small;
}

// Feedback states
.has-success {
	@include form-control-validation($state-success-text, $state-success-text, $state-success-bg);
}

.has-warning {
	@include form-control-validation($state-warning-text, $state-warning-text, $state-warning-bg);
}

.has-error {
	@include form-control-validation($state-danger-text, $state-danger-text, $state-danger-bg);

	&.checkbox input[type="checkbox"], &.radio input[type="radio"] {
		border-color: map-get($brand-colors, red-light);
	}

	.form-control, .radio__text, .checkbox__text {
		color: map-get($brand-colors, red-light);
		@include placeholder(rgba(map-get($brand-colors, red-dark), 0.75));
	}

	/*label {
		color: map-get($brand-colors, red-light);
	}*/
}

.form-error-message {
	color: map-get($brand-colors, red-light);
	font-size: 0.875em;

	input.form-control + &, label + &, select + &, .input-group + & {
		margin-top: 0.5em;
	}
}

// Reposition feedback icon if input has visible label above
.has-feedback label {

	& ~ .form-control-feedback {
		top: ($line-height-computed + 5); // Height of the `label` and its margin
	}
	&.sr-only ~ .form-control-feedback {
		top: 0;
	}
}

// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.

.help-block {
	display: block; // account for any element using help-block
	margin-top: 5px;
	margin-bottom: 10px;
	color: lighten($text-color, 25%); // lighten the text some for contrast
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).
//
// Heads up! This is mixin-ed into `.navbar-form` in navbars.less.

// [converter] extracted from `.form-inline` for libsass compatibility
@mixin form-inline {

	// Kick in the inline
	@media (min-width: $screen-sm-min) {
		// Inline-block all the things for "inline"
		.form-group {
			display: inline-block;
			margin-bottom: 0;
			vertical-align: middle;
		}

		// In navbar-form, allow folks to *not* use `.form-group`
		.form-control {
			display: inline-block;
			width: auto; // Prevent labels from stacking above inputs in `.form-group`
			vertical-align: middle;
		}

		// Make static controls behave like regular ones
		.form-control-static {
			display: inline-block;
		}

		.input-group {
			display: inline-table;
			vertical-align: middle;

			.input-group-addon,
			.input-group-btn,
			.form-control {
				width: auto;
			}
		}

		// Input groups need that 100% width though
		.input-group > .form-control {
			width: 100%;
		}

		.control-label {
			margin-bottom: 0;
			vertical-align: middle;
		}

		// Remove default margin on radios/checkboxes that were used for stacking, and
		// then undo the floating of radios and checkboxes to match.
		.radio,
		.checkbox {
			display: inline-block;
			margin-top: 0;
			margin-bottom: 0;
			vertical-align: middle;

			label {
				padding-left: 0;
			}
		}
		.radio input[type="radio"],
		.checkbox input[type="checkbox"] {
			position: relative;
			margin-left: 0;
		}

		// Re-override the feedback icon.
		.has-feedback .form-control-feedback {
			top: 0;
		}
	}
}

// [converter] extracted as `@mixin form-inline` for libsass compatibility
.form-inline {
	@include form-inline;
}

// Horizontal forms
//
// Horizontal forms are built on grid classes and allow you to create forms with
// labels on the left and inputs on the right.

/*.form-horizontal {

	// Consistent vertical alignment of radios and checkboxes
	//
	// Labels also get some reset styles, but that is scoped to a media query below.
	.radio,
	.checkbox,
	.radio-inline,
	.checkbox-inline {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: ($padding-base-vertical + 1); // Default padding plus a border
	}
	// Account for padding we're adding to ensure the alignment and of help text
	// and other content below items
	.radio,
	.checkbox {
		min-height: ($line-height-computed + ($padding-base-vertical + 1));
	}

	// Make form groups behave like rows
	.form-group {
		@include make-row;
	}

	// Reset spacing and right align labels, but scope to media queries so that
	// labels on narrow viewports stack the same as a default form example.
	@media (min-width: $screen-sm-min) {
		.control-label {
			text-align: right;
			margin-bottom: 0;
			padding-top: ($padding-base-vertical + 1); // Default padding plus a border
		}
	}

	// Validation states
	//
	// Reposition the icon because it's now within a grid column and columns have
	// `position: relative;` on them. Also accounts for the grid gutter padding.
	.has-feedback .form-control-feedback {
		right: floor(($grid-gutter-width / 2));
	}

	// Form group sizes
	//
	// Quick utility class for applying `.input-lg` and `.input-sm` styles to the
	// inputs and labels within a `.form-group`.
	.form-group-lg {
		@media (min-width: $screen-sm-min) {
			.control-label {
				padding-top: ($padding-large-vertical + 1);
				font-size: $font-size-large;
			}
		}
	}
	.form-group-sm {
		@media (min-width: $screen-sm-min) {
			.control-label {
				padding-top: ($padding-small-vertical + 1);
				font-size: $font-size-small;
			}
		}
	}
}*/

// Required field indicator
.required {
	color: map-get($brand-colors, red-light);
}


/*------- [The code below is used for the range input on Thermoforce] -------*/
input[type=range] {
	-webkit-appearance: none; /* Hides the slider so that custom slider can be made */
	background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	visibility: hidden;
}

input[type=range]:focus {
	outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */

	* {
		display: none;
	}
}

input[type=range]::-ms-track {
	width: 100%;
	cursor: pointer;

	/* Hides the slider so custom styles can be added */
	background: transparent;
	border-color: transparent;
	color: transparent;
}


input::-webkit-slider-runnable-track { background: transparent; color: transparent;}
input::-moz-range-track { background: transparent; color: transparent;}
input::-ms-track { background: transparent; color: transparent;}
input::-ms-thumb { background: transparent; color: transparent;}
input::-ms-fill-upper { background: transparent; color: transparent;}
input::-ms-fill-lower { background: transparent; color: transparent;}
input[type=range]:focus::-ms-fill-lower {  background: transparent;  }
input[type=range]:focus::-ms-fill-upper {  background: transparent;  }
/* Special styling for WebKit */
input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: 0;
	height: 250px;
	width: 16.66%;
	border-radius: 3px;
	background: transparent;
	cursor: pointer;
	margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
	//box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

input[type=range] * {
	visibility: hidden;
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
	//box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	border: 0;
	height: 250px;
	width: 16.66%;
	border-radius: 3px;
	background: transparent;
	cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
	//box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
	border: 0;
	height: 250px;
	width: 16.66%;
	border-radius: 3px;
	background: transparent;
	cursor: pointer;
}
