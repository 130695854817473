.product-carousel {
	width: 100%;

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		background: white;
		height: 100vh;
		padding: map-get($spacing, medium);

		@media #{$xs-only} {
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;
			//width: 300px;
			width: 100vw;
		}
	}

	&__left-col {
		flex: 0 0 auto;
		height: 100%;

		.single-element & {
			display: none;
		}
		.same-slide & {
			visibility: hidden;
		}
		
		@media #{$xs-only} {
			width: 92%;
			order: 2;
			position: absolute;
			padding: 0 30px;
			flex: 1 1 92%;
			max-width: 92%;
			max-height: 100px;
			left: 50%;
			top: initial;
			bottom: 20px;
			transform: translateX(-50%);
		}

		@media #{$sm-up} {
			> div {
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	&__left-col-content {
		@media #{$xs-only}{
			height: 100%;
		}		
	}

	&__zoom-wrap {
		@media #{$xs-only}{
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
		}
	}

	&__right-col {
		flex: 1 1 auto;
		position: relative;
		padding: 0 8%;
		height: 100%;

		.single-element & {
			padding: 0;
		}	

		.embed-responsive {
			top: 50%;
			position: relative;
			transform: translateY(-50%);
		}

		@media #{$xs-only} {
			height: calc(80vh - 110px);
			width: 100%;
			flex: 1 1 auto;
			min-height: calc(80vh - 110px);
			max-height: calc(80vh - 110px);
			max-width: 100%;
			order: 1;
			padding: 0;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
		}
	}


	&__list {
		@include list-unstyled;
		margin: 0;
		padding: 0;

		@media #{$xs-only}{
			.product-carousel__left-col & {
				top: 50%;
				transform: translateY(-50%);
				&.swiper-container--short {
					height: 100%;
				}
			}
		}
	}

	&__list-item {
		position: relative;
		border: 1px solid map-get($brand-colors, gray-med);
		max-width: 100px;

		.js-carousel-link-threesixty {
			.icon-image360 {
				position: absolute;
				bottom: 4px;
				right: 4px;
			}
		}
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}


	&__arrow-left, &__arrow-right {
		width: 50px;
		height: 50px;
		z-index: 10;
		cursor: pointer;
		background-size: 50px 50px;
		background-position: center;
		background-repeat: no-repeat;
		text-align: center;
		transition: opacity $transition-slow ease;
		position: absolute;
		top: 48%;
		margin-top: -25px;

		@media #{$sm-up}{
			top: 50%;
		}

		&.disabled {
			opacity: 0.05;
		}

		.single-element & {
			display: none;
		}
		.same-slide & {
			display: none;
		}
	}

	&__arrow-left {
		left: -30px;
		@media #{$sm-up}{
			left: 10px;
		}


		&:before {
			content: "\e90c";
			font-family: icomoon;
			font-size: 45px;
			line-height: 50px;
		}
	}

	&__arrow-right {
		right: -30px;
		@media #{$sm-up}{
			right: 10px;
		}

		&:before {
			content: "\e90b";
			font-family: icomoon;
			font-size: 45px;
			line-height: 50px;
		}
	}
}
