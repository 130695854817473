.filter-group {
	width: 100%;
	margin-bottom: map-get($spacing, small);

	&__title {
		@include responsive-type($h5-map);
		color: map-get($brand-colors, blue-gray);
		text-transform: uppercase;
		@include pfont-bold;
	}

	&__clear {
		margin-left: map-get($spacing, tiny);
		@include pfont-regular;
		font-size: 0.9em;
		text-transform: none;
		text-decoration: underline;
		color: $text-color;
	}

	&__contents {
		position: relative;
	}

	&--two-col {
		.filter-group__item {
			width: 100%;

			@media #{xsl-up} {
				float: left;
				width: 15em;
			}
		}
	}
}
