.select2-selection--single {
	//background-color: #fff;
	//border: 1px solid #aaa;
	border: 1.75px solid map-get($brand-colors, gray-light);
	border-radius: $input-border-radius;
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkRFNTdDRTI1MUNCRTExRThBRTQyRjk4NTUwQkI1RjQ2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkRFNTdDRTI2MUNCRTExRThBRTQyRjk4NTUwQkI1RjQ2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6REU1N0NFMjMxQ0JFMTFFOEFFNDJGOTg1NTBCQjVGNDYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6REU1N0NFMjQxQ0JFMTFFOEFFNDJGOTg1NTBCQjVGNDYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4zw1txAAAAoUlEQVR42mL8//8/AzUBEwOVwaiBg9BAFnSBxx4eCUDKH4gbZXfsuIBNE1CNAZCqB+KNQDULCLkQZGAAEO+HasRm2H6omgRivFwAxB+BWADdUCTDBKBqCtA1M2LLKVCNB4CYH4g/ALEjVArZMAdsQcKIK+thMZSBkGF4YxmqwQGIH0INAuGL+AzD60IklwpAXcoANewDPvWMo6XNCDAQIMAAUZJB7U/B0LkAAAAASUVORK5CYII=) 0 0 no-repeat;
	background-position: calc(100% - 20px) 50%;
	background-size: 16px 16px;

	.select2-selection__rendered {
		//color: #444;
		color: $input-color;
		font-size: $font-size-base * 0.75;
		padding: 0 16px;
		//line-height: 28px;
		line-height: 46px;
	}

	.select2-selection__clear {
		cursor: pointer;
		float: right;
		font-weight: bold;
	}

	.select2-selection__placeholder {
		color: #999;
	}

	.select2-selection__arrow {
		display: none;
		/*height: 26px;

		position: absolute;

		top: 1px;
		right: 1px;

		width: 20px;

		b {
			border-color: #888 transparent transparent transparent;
			border-style: solid;
			border-width: 5px 4px 0 4px;

			height: 0;
			left: 50%;

			margin-left: -4px;
			margin-top: -2px;

			position: absolute;

			top: 50%;
			width: 0;
		}*/
	}
}

&[dir="rtl"] {
	.select2-selection--single {
		.select2-selection__clear {
			float: left;
		}

		.select2-selection__arrow {
			left: 1px;
			right: auto;
		}
	}
}

&.select2-container--disabled {
	.select2-selection--single {
		background-color: #eee;
		cursor: default;

		.select2-selection__clear {
			display: none;
		}
	}
}

&.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			b {
				border-color: transparent transparent #888 transparent;
				border-width: 0 4px 5px 4px;
			}
		}
	}
}
