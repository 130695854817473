.v-favorites {
	&__email {
		position: relative;
	}

	&__email-tooltip {
		z-index: 999;
		width: 300px;
		position: absolute;
		bottom: 2em;
		right: 0;
		pointer-events: all;
		transition-duration: $transition-fast;
	}

	&__sharing {
		@media print {
			display: none;
		}
	}
}