/*================================================================================
[Load indicator]
*/
.load-indicator {
	@include pfont-bold;
	font-size: 1.25em;
	background: map-get($brand-colors, red-light);
	color: #fff;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	position: fixed;
	padding: 0.75em;
	border-radius: 10px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: $zindex-modal;
	opacity: 0;
	pointer-events: none;
	transition: opacity $transition-fast ease;

	&--absolute {
		position: absolute;
	}

	&__indicator {
		font-size: 1.75rem;
		width: 60px;
		height: 60px;
		margin-bottom: 0.33em;
		//transition: opacity 0.75s ease 0.5s;
		animation: load-indicator-rotate 1s linear;
		animation-iteration-count: infinite;
		border-radius: 50%;
		border: 3px solid #fff;
		border-right-color: transparent;
	}

	&.state-loading {
		opacity: 1;
	}
}

.load-indicator-cover {
	position: fixed;
	z-index: $zindex-modal-background;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(#fff, 0.94);

	&.state-hidden {
		opacity: 0;
		pointer-events: none;
	}
}

@keyframes load-indicator-rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
