.literature-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	flex-wrap: wrap;
	text-align: center;
	background: map-get($brand-colors, gray-lightest);
	padding: map-get($spacing, medium);

	&__link {
		text-decoration: none;
		max-width: 100%;

		&:hover {
			text-decoration: none;

			.literature-card__title {
				color: map-get($brand-colors, red-light);
			}
		}
	}

	&__title {
		@include responsive-type($h6-map);
		margin: 0;
		padding: 0 map-get($spacing, small);
		line-height: 1.3;
		color: $text-color;
		transition: all $transition-med ease;
		width: 100%;
	}

	&__img {
		padding-bottom: map-get($spacing, small);
		height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__thumb {
		max-height: 100%;
		max-width: 150px;
	}

	&__meta {
		display: flex;
		flex-direction: row;
		margin: map-get($spacing, small) 0 0 0;
		font-size: 0.8em;

		> [class^='literature-card__meta-'] {
			border-left: 1px solid map-get($brand-colors, gray-med);

			&:first-child {
				border-left: 0;
			}
		}
	}

	&__meta-filetype {
		padding: 0 map-get($spacing, small);
	}

	&__meta-filesize {
		padding: 0 map-get($spacing, small);
	}

	&__flipbook {
		margin-top: map-get($spacing, tiny);
		font-size: 0.8em;
		font-weight: bold;
	}
}
.literature-card-no-padding{
	padding: 0px !important;
}

.literature-card-checkbox{
	margin-left:5px !important;
}

.cover-image-list-border {
	border: 1px solid black; 
	margin-bottom: 5px;
}