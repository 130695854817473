.video {
	position: relative;
	max-height: 550px;
	overflow: hidden;
	display: block;
	color: white;
	max-width: 100%;
	background: map-get($brand-colors, gray-dark); // TEMPORARY


	&:hover {
		text-decoration: none;

		.video__icon {
			transform: scale(1.2);
		}

		.video__icon--lg, &.video--full-width .video__icon {
			@media #{$md-up}{
				transform: scale(2.4);
			}
		}
	}

	&__poster {
		position: relative;
		width: 100%;
		height: auto;
		object-fit: cover;

		&--fill {
			height: 100%;
		}

		&--prod-detail {
			object-position: center center;
			height: 100%;
		}
	}

	&__content {
		position: absolute;
		left: 0;
		right: 0;
		top: -10px;
		bottom: 0;
		margin: 0 auto;
		max-width: $container-lg;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		z-index:5;
		width: 100%;
		height: 100%;

		@media #{$lg-up}{
			top: 0;
		}
	}

	&__title {
		font-size: 4vw;
		color: white;
		max-width: 80%;
		line-height: 1.1;
		text-align: center;
		text-shadow: 0 0 10px rgba(20, 20, 20, 0.5);
		@include pfont-bold;

		@media #{$sm-up} {
			font-size: 1.5vw;
		}
	}

	&__icon {
		@include responsive-type($jumbo-h1-map);
		color: white;
		text-shadow: 0 0 10px rgba(20, 20, 20, 0.5);
		transform-origin: center center;
		transform: scale(1);
		text-decoration: none;
		transition: all 0.25s ease;
		margin-top: 15px;

		@media #{$sm-down}{
			font-size: 36px;
		}

		&--lg {
			transform: scale(2);
		}
	}

	&--full-width {
		max-width: none;
		height: 250px;

		@media #{$sm-up}{
			height: 370px;
		}
		@media #{$md-up}{
			height: 450px;

			.video__icon {
				transform: scale(2);
			}
		}
		@media #{$lg-up}{
			height: 550px;
		}
	}

	&--parallax {
		.video__poster {
			min-height: 450px;
			top: -200px;

			@media #{$sm-up}{
				min-height: 570px;
			}
			@media #{$md-up}{
				min-height: 650px;
			}
			@media #{$lg-up}{
				min-height: 750px;
			}
			&--alt{
				@media #{$lg-up}{
					min-height: 850px;
				}
			}
		}
	}

	&--force-16-9 {
		height: 0;
		padding-top: 56.25%;

		.video__poster {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			&--alt{
				
			      top:-50%;
				
			}
		}
		
	}
}
