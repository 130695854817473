.click-to-zoom {
	position: relative;
	text-decoration: none;
	display: block;

	&__img {
		display: block;
		width: 100%;
		height: auto;
		border: 1px solid map-get($brand-colors, gray-light);
	}

	&__content {
		background: rgba(map-get($brand-colors, black), 0.5);
		border-radius:8px;
		color: map-get($brand-colors, white);
		padding: map-get($spacing, tiny);
		display: flex;
		flex-wrap: nowrap;
		transition: all $transition-med ease;
		position: absolute;
		top: 50%;
		align-items: center;
		left: 50%;
		transform: translate(-50%);
		white-space: nowrap;
		margin-top: -(map-get($spacing, small));
	}

	&__icon {
		flex: 0 0 auto;
	}

	&__text {
		text-transform: uppercase;
		@include pfont-bold;
		flex: 1 1 auto;
		margin-left: map-get($spacing, tiny);
		font-size: 0.7em;
		letter-spacing: 1px;
	}

	&:hover {
		.click-to-zoom__content {
			background: rgba(map-get($brand-colors, black), 0.8);
			color: map-get($brand-colors, white);
		}
	}
}
