.info-window {
	background-color: map-get($brand-colors, white);
	border-radius: 5px;
	box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15); /* I could not find the value in the psd for this */
	width: 350px;
	position: absolute;
	transform: translate(50%, -100%);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 18px map-get($spacing, large) 0 18px;

	&:after {
		//Because this element appears to have a shadow I am not sure this is the best way to create this
		content: "";
		border-width: 15px;
		border-style: solid;
		border-color: map-get($brand-colors, gray-light) transparent transparent;
		position: absolute;
		right: 50%;
		bottom: -30px;
		transform: translateX(50%);
	}

	&__close {
		position: absolute;
		top: 5px;
		right: 5px;
		color: map-get($brand-colors, red-light);
		font-size: $font-size-h2;
		line-height: $font-size-h2 / 2;

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: map-get($brand-colors, red-dark);
		}
	}

	&__details {
		padding-bottom: map-get($spacing, small);
	}
	&__title {
		font-size: $font-size-h6;
		font-weight: bold;
	}
	&__address, &__phone, &__distance {
		font-size: $font-size-small;
	}

	&__directions {
		margin-left: -18px;
		margin-right: map-get($spacing, large) * -1;
		padding: map-get($spacing, small) 18px map-get($spacing, small);
	}
}