.autocomplete-list {
	display: none;
	position: absolute;
	width: 100%;
	@include list-unstyled;
	z-index: $zindex-low;
	background: white;
	box-shadow: 0 0 10px rgba(map-get($brand-colors, gray-dark), 0.3);
	transition: visibility $transition-fast ease, opacity $transition-fast ease;
	max-height: 15em;
	overflow: auto;

	&.state-visible {
		display: block;
	}

	&__item {
		padding: map-get($spacing, small);
		border-bottom: 1px solid map-get($brand-colors, gray-light);
		cursor: pointer;

		&.state-hidden {
			display: none;
		}

		&.state-selected, &:hover {
			background: map-get($brand-colors, gray-lighter);
		}
	}
}
