.v-where-to-buy {
	&__iframe {
		min-height: 2261px;
		height: auto;
		max-height: initial;
	}

	&__location-bar {
		padding-top: map-get($spacing, small);
		padding-bottom: map-get($spacing, small);

		label {
			margin-right: 0.5em;
		}
	}

	&__my-location {
		position: relative;
	}

	&__my-location-btn {
		position: absolute;
		padding: 0 10px;
		height: 100%;
		line-height: $input-height-base;
		background: transparent;
		border: none;
		z-index: 4; // Ensure this stays above the input in an input-group, which has a z-index of 2 normally and 3 when focused
		font-size: 1.5em;

		&:hover, &:active, &:focus {
			outline: none;
		}
	}

	&__my-location-input {
		padding-left: 40px;
	}

	&__results,
	&__map,
	&__retailers-container {
		@media #{$sm-up} {
			max-height: 675px;
			overflow: auto;
		}
	}

	&__map {
		margin-top: 1px;
	}
}

