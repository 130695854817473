/* mixin for multiline */
@mixin multiLineEllipsis($font-size: $paragraph-font-size, $line-height: 1.4, $lines-to-show: 3, $excerpt-bg: transparent) {
	background: $excerpt-bg;
	display: block; /* Fallback for non-webkit */
	display: -webkit-box;
	max-height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
	font-size: $font-size;
	line-height: $line-height;
	-webkit-line-clamp: $lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	@-moz-document url-prefix() {
		overflow: hidden;
		position: relative;
		&:before {
			background: $excerpt-bg;
			bottom: 0;
			position: absolute;
			right: 0;
			float: right;
			content: '\2026';
			margin-left: -3rem;
			width: 3rem;
		}
		&:after {
			content: '';
			background: $excerpt-bg;
			position: absolute;
			height: 50px;
			width: 100%;
			z-index: 1;
		}
	}
  }
  