.hidden-opacity {
	opacity: 0;
}

.fade-in {
	animation: fade-in 0.35s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in-top {
	animation: fade-in-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.pulsate-bck {
	animation: pulsate-bck 0.5s ease-in-out 2 both;
}

.pulsate-bck-extra {
	animation: pulsate-bck 0.5s ease-in-out 3 both;
}

.slide-in-bck-center {
	animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-blurred-bottom {
	animation: slide-in-blurred-bottom 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.slide-in-elliptic-right-fwd {
	animation: slide-in-elliptic-right-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-elliptic-bottom-fwd {
	animation: slide-in-elliptic-bottom-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-right {
	animation: slide-in-right 0.66s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.wobble-hor-bottom {
	animation: wobble-hor-bottom 0.8s both;
	animation-iteration-count: 2;
	animation-delay: 1s;
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@keyframes fade-in-top {
	0% {
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@keyframes pulsate-bck {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.925);
	}
	100% {
		transform: scale(1);
	}
}

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@keyframes pulsate-bck-extra {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.875);
	}
	100% {
		transform: scale(1);
	}
}


/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
@keyframes slide-in-bck-center {
	0% {
		transform: translateZ(600px);
		opacity: 0;
	}
	100% {
		transform: translateZ(0);
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
	0% {
		transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
		transform-origin: 50% 100%;
		filter: blur(40px);
		opacity: 0;
	}
	100% {
		transform: translateY(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		filter: blur(0);
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-bottom-fwd
 * ----------------------------------------
 */
@keyframes slide-in-elliptic-bottom-fwd {
	0% {
		transform: translateY(600px) rotateX(30deg) scale(0);
		transform-origin: 50% 100%;
		opacity: 0;
	}
	100% {
		transform: translateY(0) rotateX(0) scale(1);
		transform-origin: 50% -1400px;
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-right-fwd
 * ----------------------------------------
 */
@keyframes slide-in-elliptic-right-fwd {
	0% {
		transform: translateX(800px) rotateY(-30deg) scale(0);
		transform-origin: -100% 50%;
		opacity: 0;
	}
	100% {
		transform: translateX(0) rotateY(0) scale(1);
		transform-origin: -1800px 50%;
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
	0% {
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}


/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@keyframes wobble-hor-bottom {
	0%,
	100% {
		transform: translateX(0%);
		transform-origin: 50% 50%;
	}
	15% {
		transform: translateX(-30px) rotate(-6deg);
	}
	30% {
		transform: translateX(15px) rotate(6deg);
	}
	45% {
		transform: translateX(-15px) rotate(-3.6deg);
	}
	60% {
		transform: translateX(9px) rotate(2.4deg);
	}
	75% {
		transform: translateX(-6px) rotate(-1.2deg);
	}
}
