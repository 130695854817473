/*================================================================================
[Hero feature]
*/
.hero-feature {
	position: relative;
	height: 90vh;
	min-height: 400px;
	max-height: 550px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	@media #{$sm-up} {
		//max-height: 800px;
	}

	/*------- [Elements] -------*/
	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;

		&--left {
			object-fit: scale-down;
			object-position: 0 100%;
			left: 0;
		}

		&--right {
			object-fit: scale-down;
			object-position: 100% 100%;
			right: 0;
		}

		&--half {
			width: 80%;

			@media #{$sm-up} {
				width: 50%;
			}
		}

		&--two-thirds {
			width: 90%;

			@media #{$sm-up} {
				width: 66%;
			}
		}

		&[data-object-position="center center"] {
			object-position: center center;
		}
		&[data-object-position="top left"] {
			object-position: top left;
		}
		&[data-object-position="top right"] {
			object-position: top right;
		}
		&[data-object-position="bottom left"] {
			object-position: bottom left;
		}
		&[data-object-position="bottom right"] {
			object-position: bottom right;
		}
		&[data-object-position="top center"] {
			object-position: top center;
		}
		&[data-object-position="bottom center"] {
			object-position: bottom center;
		}
	}

	&__body {
		color: #fff;
		text-align: center;
		z-index: 5;
		width: 90%;
		max-width: 50em;

		&--narrow {
			@media #{$sm-up} {
				width: 50%;
				max-width: 40em;
			}
		}

		> * {
			max-width: 100%;
		}

		.hero-feature__container & {
			position: relative;

			.hero-feature__title {
				@include responsive-type($h2-map);
			}

			.hero-feature__cta {
				margin-top: map-get($spacing, small);
			}
		}

		&--left {
			@media #{$sm-up}{
				text-align: left;
			}
		}

		&--right {
			@media #{$sm-up}{
				text-align: right;
			}
		}
	}

	&__icon {
		margin-bottom: map-get($spacing, small);
	}

	&__title {
		margin: map-get($spacing, small) 0;
	}

	&__title-text {
		@include responsive-type($super-h2-map);
		@include pfont-bold;

	}

	&__description {
		color: white;

		.hero-feature--light & {
			color: $text-color;
		}
	}

	&__extended-content {
		color: white;

		.hero-feature--light & {
			color: $text-color;
		}
	}

	&__feature-icon {
		img {
			max-height: 80px;
			max-width: 100%;
		}

		.hero-feature__body--left & {
			@media #{$sm-up}{
				align-self: flex-start;
			}

		}

		.hero-feature__body--right & {
			@media #{$sm-up}{
				align-self: flex-end;
			}
		}
	}

	&__cta {
		margin-top: map-get($spacing, large);
	}

	&__review {
		line-height: 1.33;
	}

	&__review-text {
		@include pfont-bold;
		line-height: 1.5;
	}

	&__footer-link {
		text-align: center;
		display: block;
		margin: map-get($spacing, medium) 0 0 0;
	}

	&__cta-audio {
		display: inline-flex;
		margin: 0 auto;
		max-width: 400px;
		justify-content: flex-start;
		text-align: left;
		align-items: center;
		text-decoration: none;
		color: white;

		&:hover {
			text-decoration: none;
			color: white;
		}
	}

	&__cta-audio-icon {
		color: map-get($brand-colors, orange);
		flex: 0 0 auto;
		margin: 0 auto;
		position: relative;
	}

	&__container {
		margin: 0 auto;
		position: relative;
		z-index: 5;
		height: 100%;
		width: 100%;

		@media #{$sm-up}{
			width:100%;
			max-width: $container-sm;
		}
		@media #{$md-up}{
			width:100%;
			max-width: $container-md;
		}
		@media #{$lg-up}{
			width: 100%;
			max-width: $container-lg;
		}

	}

	/*------- [Variants] -------*/
	&--parallax {
		.hero-feature__bg {
			width: auto;
			height: 180%;
			//object-fit: none;
			top: -40%;
		}
	}

	&--light {
		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(map-get($brand-colors, true-white), 0.7);
			z-index:2;
		}

		@media #{$sm-up}{
			&:after {
				background: rgba(map-get($brand-colors, true-white), 0.0);
			}
		}
		.hero-feature__body {
			position: relative;
			color: $text-color;

			&:before {
				content: "";
				display: block;
				opacity: 0.8;
				box-shadow: 0px 0px 125px 80px rgba(255,255,255,0.8);
				background: rgba(255,255,255,0.8);
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				z-index: -1;
			}
		}
	}

	&--dark {
		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(map-get($brand-colors, black), 0.7);
			z-index:2;
		}

		@media #{$sm-up}{
			&:after {
				background: rgba(map-get($brand-colors, black), 0.5);
			}
		}

		&.hero-feature--remove-overlay {
			@media #{$sm-up}{
				&:after {
					display: none;
				}
			}
		}
	}

	&--left {
		align-items: flex-start;
	}

	&--right {
		align-items: flex-end;

		.hero-feature__body {
			margin-right: 5%;
		}
	}

	&--upperleft {
		.hero-feature__body {
			max-width: none;
			position: relative;
			z-index: 6;

			@media #{$xs-only}{
				width: 90%;
				left: 0;
				right: 0;
				top: 50%;
				margin: 0 auto;
				transform: translateY(-50%);
			}

			@media #{$sm-up}{
				top: 15%;
				width: 50%;
			}
			@media #{$lg-up}{
				width: 36%;
			}
		}
	}


	&--centered {
		.hero-feature__body {
			max-width: none;
			position: relative;
			z-index: 6;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			top: 50%;
			transform: translateY(-50%);
			margin: 0 auto;
			left: 0;
			right: 0;

			@media #{$xs-only}{
				width: 90%;
			}

			@media #{$sm-up}{
				width: 50%;
			}

			> * {
				flex: 0 0 auto;
			}
		}
	}

	&--upperright {
		.hero-feature__body {
			max-width: none;
			position: relative;
			z-index: 6;

			@media #{$xs-only}{
				width: 90%;
				left: 0;
				right: 0;
				top: 50%;
				margin: 0 auto;
				transform: translateY(-50%);
			}

			@media #{$sm-up}{
				top: 15%;
				left: 50%;
				width: 50%;
			}

			@media #{$lg-up}{
				width: 36%;
				left: 62%;
			}
		}
	}

	&--left {
		.hero-feature__body {
			transform: translateY(-50%);
			position: relative;
			z-index: 6;

			@media #{$xs-only}{
				width: 90%;
				left: 0;
				right: 0;
				top: 50%;
				margin: 0 auto;
				transform: translateY(-50%);
			}

			@media #{$sm-up}{
				top: 50%;
				width: 50%;
			}

			@media #{$lg-up}{
				width: 36%;
			}
		}
	}

	&--right {
		.hero-feature__body {
			transform: translateY(-50%);
			position: relative;
			z-index: 6;

			@media #{$xs-only}{
				width: 90%;
				left: 0;
				right: 0;
				top: 50%;
				margin: 0 auto;
				transform: translateY(-50%);
			}

			@media #{$sm-up}{
				left: 50%;
				top: 50%;
				width: 50%;
			}

			@media #{$lg-up}{
				width: 36%;
				left: 62%;
			}
		}
	}

	&--tall {
		min-height: 300px;
		max-height: 700px;

		&.hero-feature--centered {
			@media #{$xs-only}{
				max-height: 500px;
			}
		}

		@media #{$sm-up}{
			min-height: 500px;

		}

		@media #{$md-up}{
			min-height: 600px;
		}
	}

	&--short {
		min-height: 300px;
		max-height: 400px;

		&.hero-feature--centered {
			@media #{$xs-only}{
				max-height: 400px;
			}
		}

		@media #{$sm-up}{
			min-height: 400px;
		}
	}
}
