/*================================================================================
[Icon alignment classes]
*/

.icon-left {
	padding-right: 0.5em;

	&-sm {
		padding-right: 0.25em;
	}
}


.icon-right {
	padding-left: 0.5em;

	&-sm {
		padding-left: 0.25em;
	}
}

.icon-sm {
	font-size: 0.75em;
}

.icon-lg {
	font-size: 1.5em;
}

.icon-xlg {
	font-size: 2em;
}

//================================================================================
// Icomoon icons

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-image360:before {
	content: "\e959";
}
.icon-literature-type:before {
	content: "\e954";
}
.icon-faq-type:before {
	content: "\e955";
}
.icon-page-type:before {
	content: "\e956";
}
.icon-press-release-type:before {
	content: "\e957";
}
.icon-video-type:before {
	content: "\e958";
}
.icon-heart:before {
	content: "\e943";
}
.icon-heart-outlined:before {
	content: "\e944";
}
.icon-circle-arrow-right:before {
	content: "\e93f";
}
.icon-circle-arrow-left:before {
	content: "\e940";
}
.icon-exclamationcircle:before {
	content: "\e936";
}
.icon-informationcircle:before {
	content: "\e938";
}
.icon-checkmarkcircle:before {
	content: "\e939";
}
.icon-exclamationtriangle:before {
	content: "\e93a";
}
.icon-stop:before {
	content: "\e92d";
}
.icon-audio:before {
	content: "\e92c";
}
.icon-double-arrow-left:before {
	content: "\e923";
}
.icon-double-arrow-right:before {
	content: "\e924";
}
.icon-chevron-right-paging:before {
	content: "\e925";
}
.icon-chevron-left-paging:before {
	content: "\e926";
}
.icon-star:before {
	content: "\e92f";
}
.icon-star-outlined:before {
	content: "\e930";
}
.icon-heart-outlined3:before {
	content: "\e908";
}
.icon-globe:before {
	content: "\e907";
}
.icon-search:before {
	content: "\e906";
}
.icon-zoomin:before {
	content: "\e948";
}
.icon-pause:before {
	content: "\e90f";
}
.icon-spinner4:before {
	content: "\e97d";
}
.icon-reload:before {
	content: "\ea2e";
}
.icon-play:before {
	content: "\e91a";
}
.icon-location:before {
	content: "\e918";
}
.icon-phone:before {
	content: "\e917";
}
.icon-instagram:before {
	content: "\e902";
}
.icon-youtube:before {
	content: "\e903";
}
.icon-twitter:before {
	content: "\e904";
}
.icon-documents:before {
	content: "\e95b";
}
.icon-one-finger-tap:before {
	content: "\e93e";
}
.icon-arrow-thin-right:before {
	content: "\e951";
}
.icon-chevron-down:before {
	content: "\e911";
}
.icon-chevron-left:before {
	content: "\e912";
}
.icon-chevron-right:before {
	content: "\e913";
}
.icon-chevron-up:before {
	content: "\e914";
}
.icon-controller-record:before {
	content: "\e91c";
}
.icon-dots-three-horizontal:before {
	content: "\e922";
}
.icon-download:before {
	content: "\e92a";
}
.icon-select-arrows:before {
	content: "\e95f";
}
.icon-triangle-down:before {
	content: "\e91d";
}
.icon-upload:before {
	content: "\e946";
}
.icon-calendar:before {
	content: "\e937";
}
.icon-check:before {
	content: "\e91b";
}
.icon-code:before {
	content: "\e919";
}
.icon-heart3:before {
	content: "\e916";
}
.icon-help-with-circle:before {
	content: "\e92b";
}
.icon-home:before {
	content: "\e953";
}
.icon-list:before {
	content: "\e93b";
}
.icon-mail:before {
	content: "\e927";
}
.icon-mobile:before {
	content: "\e928";
}
.icon-new-message:before {
	content: "\e947";
}
.icon-edit:before {
	content: "\e947";
}
.icon-print:before {
	content: "\e945";
}
.icon-share:before {
	content: "\e929";
}
.icon-text-document:before {
	content: "\e935";
}
.icon-file:before {
	content: "\e935";
}
.icon-plus2:before {
	content: "\e920";
}
.icon-chevron-thin-up:before {
	content: "\e905";
}
.icon-chevron-thin-right:before {
	content: "\e90b";
}
.icon-chevron-thin-left:before {
	content: "\e90c";
}
.icon-chevron-thin-down:before {
	content: "\e90d";
}
.icon-menu:before {
	content: "\e90a";
}
.icon-chevron-small-down:before {
	content: "\e909";
}
.icon-arrow-left:before {
	content: "\e95e";
}
.icon-user-plus:before {
	content: "\e95d";
}
.icon-move:before {
	content: "\e95a";
}
.icon-arrow-right:before {
	content: "\e952";
}
.icon-delete:before {
	content: "\e950";
}
.icon-minus:before {
	content: "\e92e";
}
.icon-external-link:before {
	content: "\e921";
}
.icon-plus3:before {
	content: "\e915";
}
.icon-delete1:before {
	content: "\e95c";
}
.icon-my-location:before {
	content: "\e942";
}
.icon-touch_app:before {
	content: "\e93d";
}
.icon-close:before {
	content: "\e90e";
}
.icon-plus-outline:before {
	content: "\e91e";
}
.icon-plus:before {
	content: "\e91f";
}
.icon-sort-asc:before {
	content: "\e960";
}
.icon-sort-desc:before {
	content: "\e961";
}
.icon-file-image-o:before {
	content: "\e949";
}
.icon-file-powerpoint-o:before {
	content: "\e94a";
}
.icon-file-excel-o:before {
	content: "\e94b";
}
.icon-file-word-o:before {
	content: "\e94c";
}
.icon-file-pdf-o:before {
	content: "\e94d";
}
.icon-file-text-o:before {
	content: "\e94e";
}
.icon-file-o:before {
	content: "\e94f";
}
.icon-filter:before {
	content: "\e941";
}
.icon-fax:before {
	content: "\e93c";
}
.icon-recycle:before {
	content: "\e934";
}
.icon-calendar2:before {
	content: "\e933";
}
.icon-circle:before {
	content: "\e932";
}
.icon-adjust:before {
	content: "\e910";
}
.icon-play-circle:before {
	content: "\e931";
}
.icon-pinterest:before {
	content: "\e900";
}
.icon-facebook:before {
	content: "\e901";
}
.icon-linkedin2:before {
	content: "\eaca";
  }