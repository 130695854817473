.news-list {
	@include list-unstyled;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;

	&:before, &:after {
		width: 0;
	}

	@media #{$sm-up} {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		padding: map-get($spacing, medium) 2em map-get($spacing, medium) 0;
		border-bottom: 1px solid map-get($brand-colors, gray-light);

		&:last-child {
			border-bottom: 0;
		}

		@media #{$sm-up} {
			width: 50%;
			border-bottom: none;
		}
	}
}

.news {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__date {
		color: map-get($brand-colors, gray-med);
		@include pfont-bold;
		line-height: 1;
		text-transform: uppercase;
		font-size: 0.9em;
		margin: 0 0 map-get($spacing, tiny) 0;
	}

	&__title {
		text-decoration: none;
		font-size: 1.1rem;
		line-height: 1.3;
		padding: 0;
		margin: 0 0 map-get($spacing, tiny) 0;
		@include pfont-bold;
		display: block;
		width: 100%;

		a {
			color: map-get($brand-colors, black);

			&:hover {
				color: map-get($brand-colors, red-light);
				text-decoration: none;
			}
		}
	}

	&__desc {
		color: map-get($brand-colors, gray-med);
		width: 100%;
	}

	&__cta {
		line-height: 1;
		text-decoration: none;
		width: 100%;
		@include pfont-bold;
		margin-top: map-get($spacing, small);
		font-size: 0.875em;

		[class^="icon-"] {
			font-size: 1.1em;
			position: relative;
			top: 3px;
			left: 0;
			transition: left 0.15s ease;
		}

		&:hover {
			text-decoration: none;
			[class^="icon-"] {
				left: 3px;
			}
		}
	}
}

