.product-media {
	/*visibility: hidden;
	opacity: 0;*/
	position: relative;
	display: inline-block;
	//transition: opacity $transition-med ease;
	margin-top: map-get($spacing, small);


	/*&.state-visible {
		visibility: visible;
		opacity: 1;
	}*/

	&__list {
		@include list-unstyled;
		margin: 0;
		padding: 0;
		display: inline-flex;
		flex-wrap: wrap;
		flex: 0 0 auto;
	}

	&__item {
		margin: 0 map-get($spacing, tiny) map-get($spacing, tiny) 0;

		&.state-hidden {
			display: none;
		}
	}

	&__link {
		width: 50px;
		height: 50px;
		overflow: hidden;
		display: block;
	}

	&__thumb {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border: 1px solid map-get($brand-colors, gray-light);
	}

	&__video {
		.icon-play {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}


	&__more, &__video, &__360 {
		width: 50px;
		height: 50px;
		position: relative;
		background: rgba(map-get($brand-colors, black), 0.5);
		color: map-get($brand-colors, white);
		@include pfont-bold;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		transition: all $transition-med ease;
		font-size: 1.3em;

		&:hover {
			background: rgba(map-get($brand-colors, black), 0.7);
			text-decoration: none;
			color: map-get($brand-colors, white);
		}
	}

	&__more {
		//position: absolute;
		//left: 100%;
		//top: 0;
	}
}
