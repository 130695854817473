// Added this because we seem to be unable to change the markup of the Sitefinity pagination widget
.pagination {
	display: flex;
	border-top: 1px solid map-get($brand-colors, gray-lighter);
	padding: map-get($spacing, medium);
	align-items: center;
	justify-content: center;
	@include list-unstyled;
	margin: 0;

	&--no-border {
		border: none;
	}

	li {
		flex: 0 0 auto;
		color: map-get($brand-colors, gray-med);
		@include pfont-bold;
	}

	a {
		display: block;
		padding: map-get($spacing, tiny) map-get($spacing, small);
		text-decoration: none;

		li.active & {

		}

		[class^="icon-"] {
			font-size: 0.8em;
		}

		&:hover {
			background: map-get($brand-colors, gray-light);
			text-decoration: none;
		}
	}

	li.active a {
		color: white;
		background: map-get($brand-colors, red-light);
		cursor: default;

		&:hover {
			color: white;
			background: map-get($brand-colors, red-light);
		}
	}

	li.disabled a {
		color: map-get($brand-colors, gray-med);
		cursor: not-allowed;
		&:hover {
			background: transparent;
		}
	}
}

// @DEPRECATED
// Leaving this here so the style guide doesn't break but we will eventually want to update it to match the Sitefinity markup
.paging-list {
	display: flex;
	align-items: center;
	justify-content: center;
	@include list-unstyled;
	margin: 0;
	border-top: 1px solid map-get($brand-colors, gray-lighter);
	padding-top: map-get($spacing, medium);

	&__item {
		flex: 0 0 auto;
		color: map-get($brand-colors, gray-med);
		@include pfont-bold;


		&:nth-child(3) {
			padding: map-get($spacing, tiny) map-get($spacing, small);
		}
	}

	&__link {
		display: block;
		padding: map-get($spacing, tiny) map-get($spacing, small);
		text-decoration: none;

		.state-active & {
			color: white;
			background: map-get($brand-colors, red-light);

			&:hover {
				color: white;
				background: map-get($brand-colors, red-light);
			}
		}

		.disabled & {
			color: map-get($brand-colors, gray-med);
			cursor: not-allowed;
			&:hover {
				background: transparent;
			}
		}

		[class^="icon-"] {
			font-size: 0.8em;
		}

		&:hover {
			background: map-get($brand-colors, gray-light);
			text-decoration: none;
		}
	}
}
