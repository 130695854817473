$z-layer2: 2;
$z-layer1: 1;

pff-product-card {
	height: 100%;
}

.product-card {
	position: relative;
	overflow: hidden;
	height: 100%;

	@media print {
		page-break-inside: avoid;
	}

	&:after {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
		display: block;
		content: "";
		border: 4px solid transparent;
		z-index: $z-layer2;
		transition: all $transition-fast ease;
	}

	&__flag {
		color: white;
		position: absolute;
		top: 0;
		left: 0;
		z-index: $z-layer1;
		font-size: x-small;
		text-transform: uppercase;
		font-weight: bold;
		width: 84px;
		height: 84px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			width: 0;
			height: 0;
			overflow: hidden;
			border-color: transparent;
			border-width: 42px;
			border-style: solid;
			border-left-color: map-get($brand-colors, gray-dark);
			border-top-color: map-get($brand-colors, gray-dark);
		}

		span {
			display: block;
			//position: absolute;
			//top: 30px;
			//left: -7px;
			color: white;
			transform-origin: 50% 50%;
			transform: rotateZ(-45deg) translateY(-20px);
			line-height: .9em;
			max-width: 65px;
			word-wrap: break-word;
			hyphens: auto;
		}

		&--discontinued {
			&:before {
				border-left-color: map-get($brand-colors, gray-dark);
				border-top-color: map-get($brand-colors, gray-dark);
			}

			span {
				max-width: 85px;
				transform: rotateZ(-45deg) translateY(-12px);
			}

			html[lang=es] & {
				span {
					font-size: 0.9em;
				}
			}
		}

		&--coming-soon {
			&:before {
				border-left-color: map-get($brand-colors, blue-gray);
				border-top-color: map-get($brand-colors, blue-gray);
			}
		}

		&--new {
			&:before {
				border-left-color: map-get($brand-colors, red-dark);
				border-top-color: map-get($brand-colors, red-dark);
			}
		}
	}

	&__grade {
		@media #{$xs-only}{
			font-size: 0.9em;
		}

		position: absolute;
		top: 0;
		left: 15px;
		bottom: 0;
		padding: 0 0 0 0;
		text-transform: uppercase;
		color: map-get($brand-colors, red-dark);
		font-weight: bold;
		letter-spacing: .1em;
		z-index: $z-layer1;
		white-space: nowrap;
		pointer-events: none;
		text-align: right;
		font-size: 1em;
	}

	&__grade-inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	}
	&__grade-logo {
		position: absolute;
		top: 50%;
		left: 50%;
		width:60px;
			
		@media (max-width:$screen-xs) {
			top: 30%;   
            width: 50px;
		}
	}

	&__finishes {
		padding-bottom: 18px;
		opacity: 0;
		transition: opacity $transition-med ease-in;
		//overflow: hidden;

		.no-coarsepointer & {
			max-height: 65px;
			transition: max-height $transition-med ease;
		}

		.no-coarsepointer .product-card__body:hover & {
			max-height: 195px;
		}

	}

	&__faucet-info {
		text-align: left;
		padding: 0 0 0 map-get($spacing, small);
		text-decoration: none;

		color: map-get($brand-colors, gray-dark);

		a {
			color: map-get($brand-colors, gray-dark);
			&:hover, .coarsepointer & {
				text-decoration: none;
				color: map-get($brand-colors, gray-dark);
			}
		}
	}

	&__family {
		display: block;
		text-transform: uppercase;
		@include responsive-type($h3-map);
		@include pfont-bold;
	}

	&__image {
		width: auto;
		max-width: 240px;
		height: 28vw;
		max-height: 240px;
		object-fit: contain;
		margin: 0 auto;
		transition: transform $transition-med ease;
		margin-bottom: map-get($spacing, medium);
		//filter: drop-shadow(1px 2px 2px rgba(#000, 0.25));
		min-height: 25vh;

		@media #{$sm-up} {
			height: 16vw;
		}

		.product-card__body:hover & {
			transform: scale(1.1);
		}
	}

	&__msrp-and-ratings {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: map-get($spacing, tiny);
	}

	&__wishlist-toggle {
		position: absolute;
		top: map-get($spacing, small);
		right: map-get($spacing, small);
		//overflow: hidden;
		outline: none;
		opacity: 0;
		text-decoration: none;
		transition: all $transition-med ease;

		&:hover, .coarsepointer &{
			text-decoration: none;
		}

		&.state-favorited {
			opacity: 1;

			.favorite-toggle {
				//opacity: 0;
			}

			.favorite-toggle.state-checked {
				opacity: 1;
			}
		}
	}

	&__ratings {
		text-align: left;
		display: inline-block;
		position: relative;
		top: -2px;
	}

	&__msrp {
		font-size: 0.7em;
		font-weight: bold;
		display: inline-block;
		padding-right: map-get($spacing, tiny);
	}

	&__compare {
		position: absolute;
		right: 0;
		bottom: 0;
		margin-bottom: 0;
		display: none;
		font-size: 0.9em;
		z-index: $z-layer1;

		label {
			text-transform: none;
			font-weight: normal;
			display: flex;
			padding: map-get($spacing, medium) map-get($spacing, small) map-get($spacing, small) map-get($spacing, large);
			margin: 0;
			align-items: center;
		}

		input {
			margin-right: map-get($spacing, tiny);
		}

		&.state-selected {
			display: block;
		}
	}

	&__finish {
		display: inline-block;
		position: relative;
		padding: 0 3px;
		width: 24px;
		height: 24px;
		box-sizing: content-box;
		outline: none;
	}

	&__finish-image {
		display: block;
		width: 24px;
		height: 24px;
		border-radius: 12px;
		object-fit: cover;
		outline: none;
		transition: box-shadow $transition-fast ease, transform $transition-fast ease;
	}

	&__model {
		font-size: 0.7em;
		font-weight: bold;
		padding-top: map-get($spacing, tiny);
	}

	&__description {
		text-decoration: none;
		margin-bottom: 4px;
		font-size: $font-size-base * 0.8;
	}

	&__body {
		background: map-get($brand-colors, gray-lighter);
		min-height: 200px;
		padding: map-get($spacing, medium) 18px;
		display: flex;
		position: relative;
		box-sizing: border-box;
		flex-direction: column;
		transition: background-color $transition-fast linear;
		text-align: center;
		font-size: 1em;
		line-height: 1.2;

		.product-grid & {
			height: 100%;
		}

		&:hover {
			//background: rgba(map-get($brand-colors, gray-lighter), 0.4);
		}

		&:hover, .coarsepointer & {
			.product-card__wishlist-toggle {
				opacity: 1;
			}

			.product-card__add-to-spec-toggle {
				opacity: 1;
			}

			.product-card__compare {
				display: block;
			}

			.product-card__finishes {
				opacity: 1;
			}
		}
	}

	&__footer {
		margin: auto map-get($spacing, medium) * -1 map-get($spacing, medium) * -1;
		padding-top: map-get($spacing, small);

		&--padded {
			margin: auto map-get($spacing, small) * -1 map-get($spacing, small) * -1;
		}
	}

	&__footer-link {
		background: map-get($brand-colors, gray-lightest);
		padding: map-get($spacing, small) map-get($spacing, medium);
		color: map-get($brand-colors, gray-dark);
		text-align:center;
		display: block;
		transition: background $transition-fast ease;

		&, &:hover, &:active, &:focus {
			text-decoration: none;
		}

		&:hover, &:active, &:focus {
			color: darken(map-get($brand-colors, gray-dark), 10%);
		}

		&.state-in-spec {
			background: map-get($alert-colors, success);
			color: #fff;

			&:hover, &:active, &:focus {
				background: darken(map-get($alert-colors, success), 10%);
			}
		}
	}

	&.state-selected {
		&:after {
			border: 4px solid map-get($brand-colors, red-light);
		}

		.product-card__body {
			background: map-get($brand-colors, gray-lightest);
		}
	}

	&--product-detail {
		background: rgba(map-get($brand-colors, gray-lighter), 0.8);
	}
	
}
