/*================================================================================
[Pulldown menu]
*/

.pulldown {
	&__menu {
		position: absolute;
		display: none;
		background: map-get($brand-colors, gray-lighter);
		padding: map-get($spacing, small) map-get($spacing, medium);
		z-index: $zindex-navbar-reversed + 1;

		&.state-visible {
			display: block;

			.secondary-nav__item & {
				@media #{$xs-only} {
					position: relative;
				}
			}
		}
	}

	&__trigger {
		&:after {
			content: '\e911';
			font-family: 'icomoon';

			@media #{$xs-only}{
				display: none;
			}

		}
	}

	&__option {
		& {
			color: $text-color;
		}

		&:hover, &:active, &:focus {
			color: red;
		}
	}

	.secondary-nav__item & {
		@media #{$xs-only}{
			position: relative;

			&:before {
				content: "";
				position: absolute;
				height: 2px;
				display: block;
				left: map-get($spacing, medium);
				right: map-get($spacing, medium);
				border-top: 2px solid map-get($brand-colors, gray-light);
			}
			&:after {
				content: "";
				position: absolute;
				display: block;
				height: 2px;
				left: map-get($spacing, medium);
				right: map-get($spacing, medium);
				border-bottom: 2px solid map-get($brand-colors, gray-light);
			}
		}
	}
}
