.media-contact {
	&__title {
		@include responsive-type($h4-map);
	}

	&__company {
		text-transform: uppercase;
		margin-top: map-get($spacing, tiny);
	}

	&__position {
		color: map-get($brand-colors, gray-med);
	}

	&__phone {
		margin-top: map-get($spacing, tiny);
	}

	&__email {

	}
}
