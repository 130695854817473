.v-support {
	&__search-wrapper {
		border-top: 1px solid map-get($brand-colors, gray-light);
		padding: map-get($spacing, tiny) 0 map-get($spacing, tiny) 0;
		margin-top: map-get($spacing, tiny);

		@media #{$sm-up}{
			display: flex;
			align-items: center;
			padding: map-get($spacing, tiny) map-get($spacing, large) 0 map-get($spacing, large);
		}
	}

	&__sayt-results {
		position: relative;
		overflow: visible;
	}

	&__sayt-dropdown {
		background: map-get($brand-colors, white);
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		width: 100%;
		display: block;
		z-index: $zindex-high;
		box-shadow: 0 0 20px 0 rgba(0,0,0,0.41);
	}

	&__sayt-menu {
		@include list-unstyled;
		text-align: left;
	}

	&__sayt-menu-item {
		border-top: 1px solid map-get($brand-colors, gray-light);
		&:first-child {
			border-top: 0;
		}

		&.state-no-results {
			padding: map-get($spacing, tiny) map-get($spacing, small);
		}
	}

	&__sayt-menu-link {
		display: block;
		text-decoration: none;
		padding: map-get($spacing, tiny) map-get($spacing, small);
		color: $text-color;

		&:hover {
			color: $text-color;
			text-decoration: none;
			background: map-get($brand-colors, gray-lighter);
		}

		em {
			@include pfont-bold;
			font-style: normal;
		}
	}

	&__sayt-hero {
		overflow: visible;
	}

	&__sayt-form {
		position:relative;
		z-index: $zindex-higher;
	}

	&__view-all-button {
		display: block;
		padding: map-get($spacing, tiny);
	}
}

.v-support-ts {
	&__troubleshooting-part-search {
		visibility: hidden;
		opacity: 0;
		transition: all $transition-fast ease;

		&.state-active {
			visibility: visible;
			opacity: 1;
		}
	}

	&__num {
		.content-and-media__title-text {
			margin-top: -33px;

			@media #{$md-down}{
				padding-left: 50px;
			}

			&:before {
				content: "";
				width: 40px;
				height: 40px;
				display: flex;
				position: relative;
				top: 33px;
				align-items: center;
				justify-content: center;
				color: map-get($brand-colors, true-white);
				background: map-get($brand-colors, red-dark);
				text-align: center;
				border-radius: 50%;
				@include pfont-bold;
				margin-left: -50px;
			}
		}

		&.v-support-ts__num--inside {
			.content-and-media__title-text {
				padding-left: 50px;
			}
		}


		$num: 15;
		@while $num > 0 {
			&.v-support-ts__num--#{$num} {
				.content-and-media__title-text {
					&:before {
						content: "#{$num}";
					}
				}
			}

			$num: $num - 1;
		}

		&.v-support-ts__num--2 {
			.content-and-media__title-text {
				margin-top: -45px;
				&:before {
					@media #{$sm-only}{
						top: 45px;
					}
				}
			}
		}
	}
}
