/*================================================================================
[Site navigation]
*/
.site-nav {
	//z-index: $zindex-popover + 1;

	@media #{$xs-only} {
		@include mobile-full-screen-panel;
	}
}

/*================================================================================
[Main navigation]
*/
.main-nav {
	@include list-unstyled;
	padding: 0;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	margin-top: 1em;

	@media #{$sm-up} {
		display: flex;
		margin: 0 0 0 0;
		background: transparent;
		transition: all $transition-med ease $transition-med;
	}

	/*------- [States] -------*/
	&.state-out {
		@media #{$sm-up} {
			opacity: 0;
			transition: all $transition-med ease;
			visibility: hidden;
		}
	}

	/*------- [Navigation list item] -------*/
	&__item {
		@media #{$xs-only} {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				height: 0;
				bottom: 0;
				left: map-get($spacing, medium);
				right: map-get($spacing, medium);
				border-bottom: 1px solid rgba(map-get($brand-colors, gray-med), 0.2);
			}
		}

		@media #{$sm-up} {
			display: flex;
		}
	}

	&__trigger-wrapper {
		@media #{$sm-up} {
			position: relative;
		}
	}

	/*------- [Navigation trigger link] -------*/
	&__trigger {
		display: block;
		padding: map-get($spacing, medium) map-get($spacing, medium);
		border-bottom: 2px solid transparent;
		transition: background-color $transition-med ease, border-color $transition-med ease;
		line-height: 1.1;
		font-size: 0.875em;
		font-weight: bold;

		html[lang=fr] &, html[lang=es] & {
			font-size: 0.825em;

			@media #{$lg-up} {
				font-size: 0.875em;
			}
		}

		.main-nav--reversed & {
			background-color: transparent;
		}

		&, &:hover, &:active, &:focus {
			color: $text-color;
			text-decoration: none;

			.main-nav--reversed & {
				@media #{$sm-up} {
					color: map-get($brand-colors, white);
				}
			}
		}

		@media #{$xs-only} {
			position: relative;
			font-size: 1.25rem;
			padding: map-get($spacing, small) map-get($spacing, medium);
		}

		@media #{$sm-only} {
			font-size: 0.825em;
			padding: 18px map-get($spacing, small);
			white-space: nowrap;
		}

		/*------- [Desktop treatment: hover block with underline] -------*/
		@media #{$sm-up} {
			display: flex;
			height: 100%;
			flex-direction: column;
			justify-content: flex-end;
			position: relative;

			&:hover, &:active, &:focus, &.state-sub-nav-open {
				background: map-get($brand-colors, gray-lighter);

				.main-nav--reversed & {
					color: $text-color;
				}
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				height: 0;
				bottom: 0.66em;
				left: map-get($spacing, medium);
				right: map-get($spacing, medium);
				border-top: 3px solid map-get($brand-colors, red-light);
				opacity: 0;
				transition: opacity $transition-med ease $transition-fast;
			}
		}

		@media #{$md-up} {
			padding: map-get($spacing, medium) map-get($spacing, large);
			&:after {
				left: map-get($spacing, large);
				right: map-get($spacing, large);
			}
		}

		/*------- [Hover / selected state] -------*/
		&:hover, &:active, &:focus, &.state-selected, &.state-sub-nav-open {
			position: relative;

			&:after {
				opacity: 1;
			}
		}
	}

	/*------- [Tap-to-close link: shown on larger touch screens with tap-on/tap-off operation] -------*/
	&__tap-close {
		display: none;
		position: absolute;
		top: 0;
		font-size: 0.55em;
		font-weight: bold;
		width: 100%;
		text-align: center;
		padding: 15px 0 3px;
		transition: opacity $transition-fast ease;

		@media #{$sm-up} {
			display: block;
			opacity: 0;
			pointer-events: none;
		}

		html.coarsepointer .main-nav__trigger.state-sub-nav-open + & {
			@media #{$sm-up} {
				opacity: 1;
				pointer-events: all;
			}
		}

		[class^=icon] {
			font-size: 0.66em;
			top: -0.15em;
			position: relative;
		}
	}

	/*------- [Mobile trigger] -------*/
	&__mobile-trigger {
		font-family: 'icomoon';
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		position: absolute;
		top: 50%;
		width: 50px;
		height: 40px;
		right: map-get($spacing, medium);
		transform: translateY(-50%);
		transition: transform $transition-fast ease;
		border-left: 1px solid map-get($brand-colors, gray-light);

		&:after {
			content: "\e915"; // Chevron-thin-right
			color: map-get($brand-colors, gray-dark);
			font-size: 1.5rem;

			.main-nav--reversed & {
				@media #{$sm-up}{
					color: map-get($brand-colors, white);
				}
			}
		}

		.state-sub-nav-open & {
			transform: translateY(-50%);

			&:after {
				content: "\e92e"; // Chevron-thin-right
			}
		}

		@media #{$sm-up} {
			display: none;
		}
	}

	&__mobile-icon {
		position: relative;
		color: map-get($brand-colors, blue-gray);
		top: 2px;

		@media #{$sm-up}{
			display: none;
		}

		.main-nav--reversed & {
			color: map-get($brand-colors, white);
		}
	}

	&--reversed {
		//z-index:
	}
}

/*================================================================================
[Secondary navigation]
*/
.secondary-nav {
	@include list-unstyled;
	font-size: 0.875em;
	font-weight: 500;
	//z-index: $zindex-navbar-fixed;

	@media #{$sm-up} {
		font-size: 0.8em;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		background: map-get($brand-colors, gray-dark);
	 	height: 30px;
	}

	&__container {
		max-width: 1600px;
		margin: 0 auto;

	  @media #{$sm-up} {
		display: flex;
		justify-content: flex-end;
	  }
	}

	&__list {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 0;
		padding: 0;

		@media #{$xs-only} {
			list-style: none;
			display: block;
		}

	  @media #{$sm-up} {
		height: 30px;
	  }	  
	}

	&__item {
		@media #{$sm-up} {
			display: inline-block;
			border-left: 1px solid map-get($brand-colors, gray-cool);

			&:first-child {
				border-left: none;
			}
		}
	}

  	&__list + &__list {
	  .secondary-nav__item {
		@media #{$sm-up} {
		  display: inline-block;
		  border-left: 1px solid map-get($brand-colors, gray-cool);
		}
	  }
	}

	&__trigger {
		display: block;
		padding: map-get($spacing, small) map-get($spacing, medium);

		&, &:hover, &:active, &:focus {
			color: $text-color;
			text-decoration: none;
		}

		@media #{$xs-only} {
			font-size: 1.1em;
		}

		@media #{$sm-up} {
			padding: map-get($spacing, tiny) 18px;

			&, &:hover, &:active, &:focus {
				color: #fff;
			}

			&:hover, &:active, &:focus {
				color: darken(#fff, 10%);
				color: map-get($brand-colors, red-light);
			}
		}
	}

	&__nav-icon {
		font-family: 'icomoon';
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		position: absolute;
		top: 4px;
		width: 50px;
		height: 36px;
		right: map-get($spacing, medium);
		//transition: transform $transition-fast ease;
		border-left: 1px solid map-get($brand-colors, gray-light);

		&:after {
			content: "\e915"; // Chevron-thin-right
			color: map-get($brand-colors, gray-dark);
			font-size: 1.5rem;
		}

		.state-open & {
			&:after {
				content: "\e92e"; // Chevron-thin-right
			}
		}

		@media #{$sm-up}{
			display: none;
		}
	}
}

/*================================================================================
[Sub-navigation]
*/

.sub-nav {
	padding: 0 map-get($spacing, medium);
	text-transform: none;
	letter-spacing: -0.02em;
	max-height: 0;
	transition: all $transition-med ease;
	pointer-events: none;
	opacity: 0;
	overflow: hidden;
	z-index: $zindex-popover;

	@media #{$sm-up} {
		position: absolute;
		left: 0;
		right: 0;
		top: 132px;
		background: map-get($brand-colors, gray-lighter);
		border-bottom: 6px solid map-get($brand-colors, gray-light);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding-top: map-get($spacing, medium);
	}

	&.state-visible {
		pointer-events: all;
		max-height: 600vh;
		opacity: 1;
		transition: all $transition-fast ease;

		@media #{$sm-up} {
			padding: map-get($spacing, medium);
			transition: all $transition-med ease $transition-faster;
		}
	}

	/*------- [Special case: editing navigation pages in CMS] -------*/
	.sfPageContainer > & {
		position: static;
		opacity: 1;
		pointer-events: all;
		overflow: visible;
	}

	&__inner {
		max-width: 1200px;

		@media #{$sm-up} {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding-top: map-get($spacing, medium);
			flex: 1 1 auto;
			width: 100%;
			align-items: flex-start;
		}

		a {
			&:not(.btn) {
				color: $text-color;
				text-decoration: underline;

				&:hover {
					color: map-get($brand-colors, red-light);
					text-decoration: underline;
				}
			}
		}
	}

	&__panel {
		@media #{$xs-only} {
			margin-bottom: 24px;
		}

		@media #{$sm-up} {
			margin-left: 18px;
			padding-left: 18px;
			margin-bottom: map-get($spacing, medium);
			border-left: 1px solid rgba(map-get($brand-colors, gray-cool), 0.25);
			flex-basis: calc(33.33% - 37px);
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:first-child {
				margin-left: 0;
				padding-left: 0;
				border: none;
				width: 100%;
			}
		}

		&--extra-padded {
			@media #{$md-up} {
				padding-left: 30px;
				padding-right: 12px;
			}
		}

		&--optional-wrap {
			@media #{$sm-only} {
				flex-basis: 100%;
				margin-left: 0;
				padding-left: 0;
				border-left: 0;
			}
		}

		&--narrow {
			@media #{$sm-up} {
				flex: 0 0 calc(25% - 37px);
				max-width: calc(25% - 37px);
			}
		}

		&--half {
			@media #{$sm-up} {
				flex: 0 0 50%;
				margin-left: 0;
				padding: 0 18px;
				box-sizing: border-box;
				max-width: 50%;
			}
		}

		&--wide {
			@media #{$sm-up} {
				flex: 1 1 auto;
			}

			@media #{$md-up} {
				flex: 0 0 calc(66.666% - 37px);
				max-width: calc(66.666% - 37px);
			}
		}

		&--full {
			@media #{$sm-up} {
				flex: 1 1 100%;
				margin: 0 auto;
				padding: 0;
				border: none;
				max-width: 100%;
				width: 100%;
			}
		}

		hr {
			width: 100%;
			margin: 1em 0;
		}
	}

	&__list {
		@include list-unstyled;
		margin: 0 auto;
		width: 100%;

		@media #{$xsl-only} {
			column-count: 2;
			margin-bottom: map-get($spacing, medium);
		}

		@media #{$sm-up} {
			display: flex;
			flex-wrap: wrap;
			max-width: 1600px;
			//justify-content: space-between;
			justify-content: center;
		}

		&--vertical {
			@media #{$md-up} {
				flex: 1 1 auto;
				flex-direction: column;
				align-items: flex-start;
				flex-wrap: nowrap;

				.sub-nav__item {
					margin-left: 0;
				}
			}

			@media #{$sm-only}{
				justify-content: flex-start;
			}
		}
		//applies to both kitchen and bath
		&--landing, &--nav {
			position: relative;

			.sub-nav__heading {
				margin: map-get($spacing, small) 0;
			}
		}

		&--bath {
			.sub-nav__body {
				width: 100%;
				align-self: center;
			}

			@media #{$xs-only}{
				display: flex;
				flex-direction: column;
			}

			@media #{$sm-up}{
				width: 75%;
				justify-content: flex-start;
				border-right: 1px solid map-get($brand-colors, gray-light);
				margin: 0;
			}

			.sub-nav__item--sidebar {
				@media #{$sm-up}{
					height: 75px;
					overflow: hidden;

					.sub-nav__img {
						img {
							width: 75px;
							max-width: 100%;
							max-height: 75px;
						}
					}
				}
			}
		}

		&--kitchen {
			@media #{$xs-only}{
				display: flex;
				flex-direction: column;
			}

			@media #{$sm-up}{
				width: 66.66%;
				justify-content: flex-start;
				border-right: 1px solid map-get($brand-colors, gray-light);
				margin: 0;
			}

			.sub-nav__item--sidebar {
				@media #{$sm-up}{
					height: 75px;
					overflow: hidden;
					margin-top: -35px;

					.sub-nav__img {
						img {
							width: 75px;
							max-width: 100%;
							max-height: 75px;
						}
					}
				}
			}
		}
	}

	&__item {
		display: flex;
		margin: 0 0 map-get($spacing, medium);
		break-inside: avoid;

		@media #{$sm-up} {
			flex-basis: calc(33.333% - 24px);
			max-width: calc(33.333% - 24px);
			margin: 0 12px map-get($spacing, medium);
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;
			//justify-content: center;

			&:first-child {
				margin-left: 0;
			}
		}

		@media #{$sm-only} {
			align-items: flex-start;
		}

		&--narrow {
			@media #{$sm-up} {
				flex-basis: calc(25% - 24px);
				max-width: calc(25% - 24px);
			}
		}

		.sub-nav__list--vertical & {
			@media #{$sm-up} {
				flex-direction: row;
				align-items: flex-start;
				flex-wrap: nowrap;
				max-width: 100%;
			}

			@media #{$md-up} {
				width: 100%;
			}
		}

		&--sidebar {
			@media #{$sm-up}{
				position: absolute;

				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				margin: 0;

			}
		}
	}

	/*------- [Standard display: image + body side by side] -------*/
	&__img {
		//mix-blend-mode: multiply; // TEMPORARY: Need better assets
		width: 25%;
		text-align: right;
		max-width: 100%;

		a {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			margin: 0 auto;
		}

		@media #{$sm-up} {
			width: 120px;
			height: 120px;
			text-align: center;
			max-width: 100%;
		}

		.sub-nav__list--vertical & {
			@media #{$sm-up} {
				width: 80px;
				height: 60px;
				justify-content: center;
			}
		}
	}

	&__body {
		margin-left: 0;
		width: calc(75% - #{map-get($spacing, small) * 2});
		max-width: 100%;

		.sub-nav__img+& {
			margin-left: map-get($spacing, small);
		}

		@media #{$sm-up} {
			margin-left: 0;
			width: auto;
			max-width: 100%;
		}

		.sub-nav__list--vertical & {
			@media #{$sm-up} {
				padding-left: map-get($spacing, small);
				width: calc(100% - 80px - #{map-get($spacing, small) * 2});
			}
		}
	}

	&__super-heading {
		font-weight: 800;
		@include responsive-type($super-h2-map);
		line-height: 1;
		margin-bottom: 0.33em;
	}

	&__heading {
		letter-spacing: 0;
		margin-bottom: 0.25em;
		font-weight: bold;
		@include responsive-type($h5-map);
		line-height: 1.1;

		a {
			@include link-alt;
		}
	}

	&__sub-list {
		@include list-unstyled;

		&--lg {
			.sub-nav__sub-item {
				margin-bottom: 0.5em;

				@media #{$md-up} {
					font-size: 1em;
				}

				a {
					&, &:hover, &:active, &:focus {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__sub-item {
		line-height: 1.33em;
		margin-bottom: 0.25em;
		font-size: 0.9375em;
		font-weight: 500;
		//opacity: 0.9;
		letter-spacing: -0.02em;

		a:not([class]) {
			color: $text-color;
			position: relative;
			display: inline-block;
			transition: color $transition-med ease;

			&:hover, &:active, &:focus {
				//color: $link-hover-color;
				color: map-get($brand-colors, red-light);
			}
		}
	}
}

/*================================================================================
[Footer navigation]
*/
.footer-nav {
	&__list {
		@include list-unstyled;
		margin-bottom: map-get($spacing, medium);
		font-size: 0.875em;

		@media #{$xs-only} {
			column-count: 2;
		}

		@media #{$xsl-up} {
			column-count: 1;
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__section {
		margin-bottom: map-get($spacing, medium);

		@media #{$xs-only} {
			flex-basis: 50%;
			break-inside: avoid;
		}

		@media #{$xsl-up} {
			flex-basis: calc(33.333% - #{map-get($spacing, medium) / 2});
			margin-right: map-get($spacing, medium) / 2;
		}

		@media #{$sm-up} {
			flex-basis: calc(33.333% - #{map-get($spacing, medium) / 2});
		}

		@media #{$lg-up} {
			flex-basis: calc(16.6667% - #{map-get($spacing, medium) / 2});
		}
	}

	&__heading {
		display: block;
		font-weight: bold;
		margin-bottom: 0.33em;
		line-height: 1.1;

		&, &:hover, &:active, &:focus {
			color: map-get($brand-colors, blue-gray);
		}
	}
}

/*================================================================================
[Footer subnavigation]
*/
.footer-subnav {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 0.875em;
	text-align: center;
	color: rgba(map-get($brand-colors, gray-light), 0.75);

	@media #{$md-up} {
		flex-direction: row;
		justify-content: space-between;
		text-align: left;
	}

	&__social {
		font-size: 1.33rem;
		white-space: nowrap;

		a {
			transition: color $transition-med ease;

			&, &:hover, &:active, &:focus {
				text-decoration: none;
			}

			&:hover, &:active, &:focus {
				color: map-get($brand-colors, blue-gray);
			}
		}
	}
}
/*================================================================================ */

.lang-nav a {
    color: #fff;
    padding-right: 10px;
    line-height: 33px;
    font-weight: 500;
    text-decoration: none;
}

@media (max-width: 767.9px) {
.lang-nav a {
    padding: 12px 24px;
    color: #3d3d3c;
    font-size: 1.5em;
    line-height: 3;
  }
}

.alert-nav {
	@include list-unstyled;
	font-size: 0.875em;
	font-weight: 500;	

	@media #{$sm-up} {
		font-size: 0.8em;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		background: map-get($brand-colors, gray-dark);
	 	height: 30px;
	}

	&__container {
		max-width: 1600px;
		margin: 0 auto;

	  @media #{$sm-up} {
		display: flex;
		justify-content: flex-end;
	  }
	}

	&__list {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;

		@media #{$xs-only} {
			list-style: none;
			display: block;
		}

	  @media #{$sm-up} {
		height: 30px;
	  }	  
	}

	&__item {
		@media #{$sm-up} {
			display: inline-block;
			border-left: 1px solid map-get($brand-colors, gray-cool);

			&:first-child {
				border-left: none;
			}
		}
	}

  	&__list + &__list {
	  .alert-nav__item {
		@media #{$sm-up} {
		  display: inline-block;
		  border-left: 1px solid map-get($brand-colors, gray-cool);
		}
	  }
	}

	&__trigger {
		display: block;
		padding: map-get($spacing, small) map-get($spacing, medium);

		&, &:hover, &:active, &:focus {
			color: $text-color;
			text-decoration: none;
		}

		@media #{$xs-only} {
			font-size: 1.1em;
		}

		@media #{$sm-up} {
			padding: 0px 18px;

			&, &:hover, &:active, &:focus {
				color: #3d3d3c;
			}

			&:hover, &:active, &:focus {
				color: darken(#3d3d3c, 10%);
				color: map-get($brand-colors, red-light);
			}
		}
	}	
}
