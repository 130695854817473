//BUILD DATA
//THIS DATA IS USED BY THE SASS FUNCTION BELOW TO
//RUN A LOOP AND CREATE ALL THE NECESSARY HELPER CLASSES FOR PULLS

//CSS WILL OUTPUT LIKE SO:
//.pull-{direction}
//.pull-{direction}--{breakpoint}-{scope}

//EXAMPLES
//.pull-left
//.pull-left--sm-only
//...etc

//Bootstrap by default does not provide responsive pulls which is why this is needed


@each $pull-direction-key, $pull-direction-value in $pull-directions {
	.pull-#{$pull-direction-key} {
		float: $pull-direction-value !important;
	}

	//RESPONSIVE MODIFIERS
	@each $bpkey, $bp in $breakpoints {
		@media #{$bp} {
			.pull-#{$pull-direction-key}-#{$bpkey} {
				float: $pull-direction-value !important;
			}
		}
	}
}