/*================================================================================
[Favorite toggle ("Add to Favorites" heart)]
*/
.favorite-toggle {
	width: 30px;
	height: 22px;
	position: relative;
	font-style: normal;
	font-size: 30px;
	line-height: 1;
	display: inline-block;

	&:before {
		display: block;
		content: '';
		background: url(../img/ui/heart.svg) no-repeat;
		width: 30px;
		height: 24px;
		left: 0.5px;
		top: 0;
		position: relative;
		transition: transform 0.25s ease-in;
		transform: scale(0);
	}

	&:after {
		color: map-get($brand-colors, gray-cool);
		font-family: 'icomoon';
		content: '\e944';
		width: 30px;
		height: 30px;
		display: inline-block;
		font-size: 23.25px;
		position: absolute;
		left: 0;
		top: 0;
	}

	/*------- [States] -------*/
	&.state-checked {
		&:before {
			transform: scale(1);
		}

		&:after {
			animation: expand-heart 0.25s ease-in;
			color: map-get($brand-colors, red-light);
			transition: color 0s ease 0.25s;
		}
	}

	&.state-reverse {
		&:after {
			animation: expand-heart 0.25s ease-in;
		}
	}

	&--mini {
		width: 20px;
		height: 16px;
		position: relative;
		font-style: normal;
		font-size: 16px;
		line-height: 1;
		display: inline-block;
		margin: -5px map-get($spacing, tiny) -5px 0;

		&:before {
			height: 15.75px;
			width: 20px;
		}

		&:after {
			width: 16px;
			height: 16px;
			font-size: 16px;
			line-height: 16px;
		}
	}
}

@keyframes expand-heart {
	0% {
		transform: scale(1);
	}

	30% {
		transform: scale(1.15);
	}

	75% {
		border-width: 2px;
	}

	100% {
		transform: scale(1);
		border-width: 1px;
	}
}
