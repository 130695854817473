.search-as-you-type {
	&__results {
		position: relative;
		overflow: visible;
	}

	&__dropdown {
		background: map-get($brand-colors, white);
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		width: 100%;
		display: block;
		z-index: $zindex-high;
		box-shadow: 0 0 20px 0 rgba(0,0,0,0.41);
	}

	&__menu {
		@include list-unstyled;
		margin-bottom: 0;
		text-align: left;
	}

	&__menu-item {
		border-top: 1px solid map-get($brand-colors, gray-light);
		&:first-child {
			border-top: 0;
		}

		&.state-no-results {
			padding: map-get($spacing, tiny) map-get($spacing, small);
		}
	}

	&__menu-item-img {
		max-width: 50px;
		margin-right: map-get($spacing, small);
	}

	&__menu-link {
		display: block;
		text-decoration: none;
		padding: map-get($spacing, tiny) map-get($spacing, small);
		color: $text-color;

		&:hover {
			color: $text-color;
			text-decoration: none;
			background: map-get($brand-colors, gray-lighter);
		}

		em {
			@include pfont-bold;
			font-style: normal;
		}

		&--flex {
			display: flex;
			align-items: center;
		}

		&--compact {
			line-height: 1.25;
			font-size: 0.9em;
		}
	}

	&__form {
		position:relative;
		z-index: $zindex-higher;
	}

	&__button {
		height: 46px;
	}

	&__view-all-button {
		display: block;
		padding: map-get($spacing, tiny);
		transition: background $transition-fast ease;

		&:hover, &:active, &:focus {
			background: map-get($brand-colors, gray-lighter);
		}
	}

	&__search-icon {
		font-size: 26px;
		line-height: 0.7;

		.state-open &:before {
			content: '\e90e';
		}
	}
}