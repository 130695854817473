.background-rotator {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		z-index: 1;
		display: block;
		background: rgba(20, 20, 20, 0.5);
	}

	//TOP LEVEL ELEMENTS
	&__title {
		color: white;
		@include responsive-type($h1-map);
		text-align: center;
		padding: 0 0 map-get($spacing, medium) 0;
		position: relative;
		line-height: 1;


		@media #{$xs-only}{
			&:first-child {
				margin: 0;
			}
		}

		@media #{$sm-up}{
			margin: 0 auto map-get($spacing, medium) auto;
		}
	}

	&__content {
		color: white;
		max-width: 80%;
		margin: map-get($spacing, medium) auto 0 auto;
	}

	&__bg {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		opacity: 1;
		transition: all 600ms ease;

		&.state-fading {
			opacity: 0;
		}
	}

	&__bg-cloned {
		opacity: 1;
		transition: all 600ms ease;
	}

	&__container {
		position: relative;
		margin: 0 auto;
		max-width: $container-lg;
		z-index: 2;
		padding: map-get($spacing, massive) 0;

		.background-rotator--no-title.background-rotator--tall & {
			@media #{$sm-up}{
				padding: 127px 0;
			}
		}
	}

	//SLIDE LEVEL ELEMENTS
	&__card {
		background: white;
		overflow: visible;
		position: relative;
		text-decoration: none;
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		padding: map-get($spacing, huge);
		transform: scale(0.99);

		@media #{$sm-up} {
			flex-direction: row;
			text-align: center;
			min-height: 330px;
		}

		@media #{$xs-only} {

		}
	}

	&__card-description {
		color: $text-color;
		margin-top: map-get($spacing, large);
		max-width: 500px;

		@media #{$sm-up}{
			margin-top: map-get($spacing, medium);
		}
	}

	&__card-text {
		padding: map-get($spacing, medium) 0 map-get($spacing, medium) 0;
		text-decoration: none;
		text-align: left;
		transition: all 300ms ease;
		transform: translateY(0);
		max-width: 100%;
		display: flex;
		flex-direction: column;

		@media #{$sm-up}{
			text-align: center;
			padding: map-get($spacing, large);
			align-content: center;
			justify-content: center;
		}

	}

	&__kicker {
		margin: 0 0 map-get($spacing, tiny) 0;
	}

	&__card-title {
		@include responsive-type($h2-map);
		@include pfont-bold;
		position: relative;
		line-height: 1.1;
		color: $text-color;
		max-width: 500px;

		&:after {
			content: "";
			display: block;
			height: 4px;
			background: map-get($brand-colors, red-light);
			width: 30%;
			position: absolute;
			top: 100%;
			transition: all $transition-med ease;

			@media #{$xs-only}{
				top: calc(100% + 10px);
			}

			@media #{$sm-up}{
				left: 50%;
				transform: translateX(-50%);
			}
		}

		@media #{$sm-up}{
			padding: 0 0 map-get($spacing, small) 0;
		}
	}

	&__card-img-wrap {
		text-align: center;
		overflow:hidden;
		position: absolute;
		top: -20px;
		left: 20px;
		right: 20px;

		@media #{$sm-up}{
			top: auto;
			flex: 1 1 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 0;
			min-height: 300px;
		}
	}

	&__card-img {
		min-height: 100%;
		max-height: 100%;
		transition: all 300ms ease;
		margin: 0 auto;
		position: relative;

		@media #{$xs-only}{
			max-height: 150px;
			display: block;
		}

		@media #{$sm-up}{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

	}

	&__list {
		margin: 0;
		padding: 0;
	}

	&__list-item {
		height: auto; // Overrides height: 100% in .swiper-slide
		padding-top: map-get($spacing, large);

		@media #{$sm-up}{
			padding: map-get($spacing, huge) 0 map-get($spacing, small) 0;
		}

		&--img-left, &--img-right {
			.background-rotator__card {
				@media #{$xs-only}{
					justify-content: flex-start;
					padding: 100px map-get($spacing, medium) map-get($spacing, medium) map-get($spacing, medium);
				}

				@media #{$sm-up}{
					text-align: left;
					min-height: 330px;
					padding: map-get($spacing, medium);
				}
			}

			.background-rotator__card-title {
				&:after {
					left: 0;
					transform: none;
				}
			}
		}

		&--img-left {
			.background-rotator__card {
				@media #{$xs-only}{
					justify-content: flex-start;
				}
				justify-content: flex-end;
			}

			.background-rotator__card-text {
				order: 1;
				text-align: left;
				align-items: left;

				@media #{$sm-up}{
					flex: 0 0 55%;
					max-width: 55%; //ie11
					order: 2;
					text-align: left;
					padding: map-get($spacing, large) map-get($spacing, huge) map-get($spacing, large) map-get($spacing, large);
				}

				@media #{$md-up}{
					padding: map-get($spacing, huge) map-get($spacing, massive) map-get($spacing, huge) map-get($spacing, huge);
				}
			}

			.background-rotator__card-img-wrap {
				order: 2;

				@media #{$sm-up}{
					left: 0;
					bottom: -48px;
					top: -48px;
					width: 50%;
					order: 1;
					min-height: 250px;
				}
			}
		}

		&--img-right {
			.background-rotator__card {
				justify-content: flex-start;
			}

			.background-rotator__card-text {
				order: 1;
				text-align: left;
				align-items: left;

				@media #{$sm-up}{
					flex: 0 0 55%;
					max-width: 55%; //ie11
					text-align: left;
					padding: map-get($spacing, large) map-get($spacing, large) map-get($spacing, large) map-get($spacing, huge);
				}

				@media #{$md-up}{
					padding: map-get($spacing, huge) map-get($spacing, huge) map-get($spacing, huge) map-get($spacing, massive);
				}
			}

			.background-rotator__card-img-wrap {
				order: 2;

				@media #{$sm-up}{
					right: 0;
					bottom: -48px;
					top: -48px;
					width: 50%;
					left: auto;
				}

				@media #{$md-up}{
					right: 40px;
					width: 44%;
				}
			}
		}
	}

	&__elements {
		position: relative;
		padding: 0 map-get($spacing, large);

		@media #{$sm-up}{
			padding: 0 map-get($spacing, massive);
		}

	}

	&__prev {
		left: 0;
		top:50%;
		position: absolute;

		&:before {
			color: white;
			content: "\e940";
		}

		@media #{$sm-down}{
			&:before {
				font-size: 35px;
				width: 40px;
				height: 40px;
			}
		}
	}

	&__next {
		top: 50%;
		right: 0;
		position: absolute;
		&:before {
			color: white;
			content: "\e93f";
		}

		@media #{$sm-down}{
			&:before {
				font-size: 35px;
				width: 40px;
				height: 40px;
			}
		}
	}


	&--tall {
		position: relative;
		@media #{$sm-up}{
			min-height: 682px;
			max-height: 682px;
		}
		@media #{$md-up}{
			min-height:707px;
		}
		@media #{$lg-up}{
			min-height: 725px;
		}
	}
}
