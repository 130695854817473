.collection-highlights {
	&__title {
		@include responsive-type($h2-map);
		text-align: center;
		margin: 0 0 map-get($spacing, small) 0;
		@include pfont-bold;

		@media #{$md-up}{
			margin: 0 0 map-get($spacing, medium) 0;
		}
	}

	&__description {
		font-weight: bold;
		text-align: center;
		padding-bottom: map-get($spacing, huge);
	}

	&__list {
		@include list-unstyled;
		margin: initial auto;
		padding: 0;

		@media #{$sm-up}{
			display: flex;
			align-items: stretch;
		}
	}

	&__item {
		flex: 1 1 100%;
		border-bottom: 1px solid map-get($brand-colors, gray-lighter);
		margin-bottom: map-get($spacing, large);
		padding-bottom: map-get($spacing, large);
		text-align: center;
		max-width: 100%;

		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: 0;
		}

		@media #{$sm-up}{
			border-bottom: 0;
			padding: 0;
			margin: 0;
		}
	}

	&__img-wrap {
		position: relative;
		display: inline-flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		margin: 0 auto;

		@media #{$sm-up}{
			display: flex;
		}

		.ie11 & {
			overflow: visible !important; //have to override the objectfit polyfill in this instance
		}
	}

	&__img {
		object-fit: contain;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: $zindex-lower;

		.ie11 & {
			//the js object fit polyfill doesnt work exactly the same as
			//modern browsers so overwrite js styles with custom fallback for ie11
			@media #{$sm-up}{
				width: auto !important;
				top: 50% !important;
				right: 0 !important;
				bottom: 0 !important;
				left: 0 !important;
				margin: 0 auto !important;
				max-width: 100% !important;
				max-height: 100% !important;
				transform: translateY(-50%);
			}
		}
	}

	&__img-proportional {
		position: relative;
		width: 100%;
		height: auto;
		z-index: 1;
		max-height:250px;

		@media #{$sm-up}{
			max-height:300px;
		}

		@media #{$md-up}{
			max-height:405px;
		}
		@media #{$lg-up}{
			max-height:520px;
		}

	}

	&__content {
		text-align: center;
		margin-top: map-get($spacing, medium);
		padding: 0 map-get($spacing, medium);
	}

	&__item-title {

	}

	&__item-description {
		color: map-get($brand-colors, gray-med);
	}
}
