.content-and-media {
	display: block;

	&__body {
		@media #{$xs-only}{
			text-align: center;
		}
		@media #{$sm-up} {
			display: flex;
			align-items: center;
			flex-direction: row;
			max-width:100%;
		}
	}

	&__content {
		flex: 1 1 0;
		order: 2;
		position: relative;

		@media #{$xs-only}{
			text-align: left;
		}

		@media #{$sm-up}{
			order: 1;
			padding: 0 map-get($spacing, large) 0 0;
		}
	}

	&__feature-icon {

	}

	&__media {
		flex: 0 0 auto;
		order: 1;
		position: relative;
		margin-bottom: map-get($spacing, large);


		@media #{$xs-only}{
			display: inline-block;
			margin: 0 auto;
		}

		@media #{$sm-up}{
			flex: 0 0 50%;
			order: 2;
			margin-bottom: 0;
		}


		.ie11 & {
			@media #{$xs-only}{
				min-height: 250px;
				display: block;
			}

			@media #{$sm-up}{
				min-height: 300px;
			}

			@media #{$md-up}{
				min-height:340px;
			}

			@media #{$lg-up}{
				min-height: 380px;
			}
		}


		// Special case, in use on the Product Family page - allows the media element to shrink to accommodate a longer title
		&--shrink {
			flex-shrink: 1;
		}
	}

	&__image {
		position: relative;

		@media #{$xs-only}{
			height: auto;
			max-width:100%;
			margin: 0 auto;
			display: inline-block;
		}

		@media #{$sm-up}{
			width: initial;
			height: 100%;
			max-height: 500px;
			object-fit: contain;
			margin: 0 auto;
			max-width: 100%;
			display: block;
		}
	}

	&__video {
		max-width: 100%;
		height: auto;
		display: block;
		max-height: 350px;
		margin: 0 auto;
		object-fit: cover;

		@media #{$md-up}{
			max-height: 550px;
		}
	}

	&__play-pause {
		@include responsive-type($jumbo-h1-map);
		color: white;
		text-shadow: 0 0 10px rgba(20, 20, 20, 0.5);
		transform-origin: center center;
		text-decoration: none;
		position: relative;
		line-height: 1;
		display: block;
		transition: all 0.25s ease;
		cursor: default;

		/*&:before {
			content: "\e91a";
			font-family: icomoon;
			opacity: 1;
			left: 50%;
			top: 50%;
			position: absolute;
			display: block;
			z-index: $zindex-high;
			text-align: center;
			text-decoration: none;
			transition: all 0.25s ease;
			transform: scale(1) translate(-50%, -50%);
		}
		*/

		.state-playing & {
			&:before {
				content: "\e90f";
				opacity: 0;
			}
		}

		&:hover {
			color: white;
			text-decoration: none;

			&:before {
				transform: scale(1.3) translate(-50%, -50%);
				left: calc(50% + 7px);
				top: calc(50% + 7px);
			}

			.state-playing & {
				&:before {
					content: "\e90f";
					opacity: 0.6;
				}
			}
		}
	}

	&__heading {
		@include responsive-type($h2-map);
		font-weight: bold;
		padding-bottom: 1em;
		margin-bottom: 1em;
		position: relative;
		text-align: center;

		&:after {
			content: "";
			display: block;
			height: 2px;
			border-top: 2px solid map-get($brand-colors, gray-light);
			width: 180px;
			top: 100%;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__blob {
		position: relative;

		.ie11 & {
			min-height: 220px;

			@media #{$sm-up}{
				min-height: 280px;
			}

			@media #{$md-up}{
				min-height: 340px;
			}

			@media #{$lg-up}{
				min-height: 350px;
			}
		}
	}

	&__blob-center {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		height: 100%;
		width: 0;
		z-index: $zindex-navbar;
	}

	&__title {
		margin: 0 0 0.75em 0;

		&--small {
			.content-and-media__title-text {
				@include responsive-type($h3-map);
			}
		}
	}

	&__title-text {
		@include responsive-type($h2-map);
		@include pfont-bold;
	}

	//---[MODIFIERS]----//
	&--media-left {
		.content-and-media__media {
			order: 1;

			@media #{$sm-up}{
				order: 1;
			}
		}

		.content-and-media__content {
			order: 2;

			@media #{$sm-up}{
				order: 2;
				padding: 0 0 0 map-get($spacing, large);
			}
		}
	}

	&--media-right {
		.content-and-media__media {
			order: 1;

			@media #{$sm-up}{
				order: 2;
			}
		}

		.content-and-media__content {
			order: 2;

			@media #{$sm-up}{
				order: 1;
				padding: 0 map-get($spacing, large) 0 0;
			}
		}
	}

	&--short {
		.content-and-media__media {
			.ie11 & {
				@media #{$lg-up}{
					min-height: 350px;
				}
			}
		}


		.content-and-media__image {

			@media #{$xs-only}{
				max-height: 220px;
				max-width: 220px;
			}

			@media #{$sm-up}{
				max-height: 350px;
			}
		}
	}
}
