//
// Alerts
// --------------------------------------------------

// Base styles
// -------------------------

.alert {
	padding: $alert-padding;
	margin-bottom: $line-height-computed;
	border: 1px solid transparent;
	border-radius: $alert-border-radius;

	// Headings for larger alerts
	h4 {
		margin-top: 0;
		// Specified for the h4 to prevent conflicts of changing $headings-color
		color: inherit;
	}

	// Provide class for links that match alerts
	.alert-link {
		font-weight: $alert-link-font-weight;
	}

	// Improve alignment and spacing of inner content
	> p,
	> ul {
		margin-bottom: 0;
	}

	> p + p {
		margin-top: 5px;
	}
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissable, // The misspelled .alert-dismissable was deprecated in 3.2.0.
.alert-dismissible {
	padding-right: ($alert-padding + 20);

	// Adjust close link position
	.close {
		position: relative;
		top: -2px;
		right: -21px;
		color: inherit;
	}
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
	@include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-info {
	@include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-warning {
	@include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
	@include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}

/*================================================================================
[Pfister custom additions]
*/

.alert {
	padding: map-get($spacing, medium) 0;
	align-items: center;
	background: map-get($brand-colors, gray-lighter);

	// You have two options depending on the desired width of the content inside this alert
	// .alert__wrapper = 100%, max-width: 1600px;
	// .alert__container = width of a Bootstrap container
	&__wrapper, &__container {
		display: flex;
		align-items: center;
		font-size: 0.875em;
	}

	&__wrapper {
		@include content-wrapper;
	}

	&__container {
		@include container-fixed;

		@media (min-width: $screen-sm-min) {
			width: $container-sm;
		}
		@media (min-width: $screen-md-min) {
			width: $container-md;
		}
		@media (min-width: $screen-lg-min) {
			width: $container-lg;
		}

	}

	&__close {
		background: transparent;
		border: none;
		flex: 0 0 12%;
		justify-self: flex-end;
		text-align: right;
		max-width: 12%;
	}

	&__kicker {
		@include responsive-type($h3-map);
		@include pfont-bold;
		padding-right: map-get($spacing, medium);
		@media #{$xs-only}{
			margin-bottom: map-get($spacing, tiny);
			display: none;
		}

		@media #{$sm-up}{
			flex: 0 0 160px;
			max-width: 160px;
		}

		@media #{$md-up}{
			flex: 0 0 180px;
			max-width: 180px;
		}

		@media #{$lg-up}{
			flex: 0 0 200px;
			max-width: 200px;
		}
	}

	&__icon {
		@include responsive-type($h2-map);
		@include pfont-bold;
		padding-right: map-get($spacing, medium);
		display: flex;
		align-items: center;

		@media #{$xs-only}{
			flex: 0 0 40px;
			padding-right: 0;
			max-width: 40px;
		}

		@media #{$sm-up}{
			flex: 0 0 80px;
			max-width: 80px;
		}

		@media #{$md-up}{
			flex: 0 0 90px;
			max-width: 90px;
		}

		@media #{$lg-up}{
			flex: 0 0 100px;
			max-width: 100px;
		}
	}

	&__text {
		flex: 1 1 auto;

		.alert__kicker, .alert__icon {
			display: block;

			@media #{$sm-up}{
				display: none;
			}
		}
	}

	/*------- [Variants] -------*/
	&--global {
		margin-bottom: 0;
	}

	&--success {
		background: map-get($alert-colors, success);
	}

	&--danger {
		background: map-get($alert-colors, danger);
		color: white;
	}

	&--danger-alt {
		background: map-get($brand-colors, red-dark);
		color: white;
	}

	&--info {
		background: map-get($alert-colors, info);
		color: white;
	}

	&--warning {
		background: map-get($alert-colors, warning);
	}

	&--compact {
		padding: map-get($spacing, small);
	}

	&--fixed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: $zindex-popover;
	}

	&--bottom {
		top: auto;
		bottom: 0;
		margin-bottom: 0;
	}
}
