.literature-grid {
	@include list-unstyled;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	flex-wrap: wrap;
	height: 100%;

	&__item {
		flex: 0 0 50%;
		overflow: hidden;
		box-sizing: border-box;
		position: relative;
		margin-bottom: 0;

		&:after {
			content: "";
			width: map-get($spacing, tiny);
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			height: 100%;
			z-index: 10;
		}

		&:first-child {
			&:after {
				display: none;
			}
		}

		@media #{$sm-down}{
			&:nth-child(odd) {
				&:after {
					display: none;
				}
			}
		}


		@media #{$md-up}{
			flex: 0 0 33.33%;

			&:nth-child(3n + 3) {
				&:after {
					display: none;
				}
			}
		}

		/*@media #{$lg-up}{
			flex: 0 0 25%;

			&:nth-child(4n + 4) {
				&:after {
					display: none;
				}
			}
		}*/
	}

	@supports(display: grid) {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-auto-rows: minmax(auto, auto);
		grid-gap: map-get($spacing, tiny);
		list-style: none;
		margin: 0;
		padding: 0 0 map-get($spacing, huge) 0;
		grid-auto-flow: dense;

		&__item {
			position: relative;
			overflow: hidden;
			margin: 0;

			&:after {
				display: none;
			}
		}

		@media #{$xsl-up}{
			grid-template-columns: repeat(2, 1fr);
		}

		@media #{$md-up}{
			grid-template-columns: repeat(3, 1fr);
		}

		//@media #{$lg-up}{
		//	grid-template-columns: repeat(4, 1fr);
		//}
	}
}
