.finish-switcher {
	&__container {
		padding: 0 map-get($spacing, small);
		max-width: $container-lg;
		display: flex;
		flex-direction: column;
		margin: 0 auto;

		@media #{$sm-up}{
			flex-direction: row;
			align-items: center;
		}
	}

	&__col-1 {
		@include make-xs-column(12);
		@include make-sm-column(7);
		@include make-md-column(8);
		height: 0;
		padding-top: 56.25%;
		overflow:hidden;
		position: relative;

		&:after {
			content: none;
		}
	}

	&__col-2 {
		@include make-xs-column(12);
		@include make-sm-column(5);
		@include make-md-column(4);

		&:after {
			content: none;
		}
	}

	&__title {
		@include responsive-type($h2-map);
		@include pfont-bold;
		line-height: 1.2;
		margin-bottom: map-get($spacing, large);
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 22px;
			height: 38px;
			position: absolute;
			right: 100%;
			top: 97%;
			background: url(../img/ui/downarrow.png) no-repeat left top;
		}
	}

	&__list {
		@include list-unstyled;
		margin: 0;
		padding: 0;
	}

	&__item {

	}

	&__link {
		display: flex;
		align-items: center;
		margin-bottom: map-get($spacing, small);
		text-decoration: none;
		color: $text-color;
	}



	&__item-swatch {
		flex: 1 1 48px;
		max-width: 48px;
		min-width: 48px;
	}

	&__item-label {
		flex: 1 1 auto;
		max-width: calc(100% - 48px);
		padding-left: map-get($spacing, small);
	}

	&__item-label-text {
		display: inline-block;

		.finish-switcher__link.state-selected & {
			padding-bottom: map-get($spacing, tiny);
			margin-bottom: -(map-get($spacing, tiny));
			border-bottom: 2px solid map-get($brand-colors, red-light);
		}
	}

	&__swatch {
		width: 100%;
		height: auto;
	}

	&__primary-image {
		height: auto;
		top: 50%;
		left: 50%;
		max-width: 100%;
		max-height: 100%;
		transform: translate(-50%, -50%);
		position: absolute;
		display: block;
		transition: all $transition-med ease;
		visibility: hidden;
		opacity: 0;

		&.state-visible {
			visibility: visible;
			opacity: 1;
			z-index: 2;
		}
	}


	&--wtb {
		.finish-switcher__container {
			display: flex;
			flex-direction: column;
		}
		.finish-switcher__links {
			display: none;
			flex: 0 0 0;
			margin: 0 auto;

			&.state-visible {
				display: block;
			}
		}

		.finish-switcher__links-wrap {
			position: relative;
			display: flex;
			align-items: center;
		}

		.finish-switcher__row-1 {
			position: relative;
			min-height: 240px;
			width: 100%;

			@media #{$md-up}{
				min-height: 300px;
			}
		}

		.finish-switcher__row-2 {
			width: 100%;
		}

		.finish-switcher__title {
			@include responsive-type($h4-map);
			@include pfont-bold;
			line-height: 1.2;
			margin-bottom: map-get($spacing, tiny);
			position: relative;
			text-align: center;

			&:before {
				display: none;
			}
		}
	}
}
