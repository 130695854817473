/*================================================================================
[Finish family result for custom finish family select element]
*/
.finish-family-result {

	&__title {
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
	}

	&__item {
		width: 12.5em;
		margin: 0 0.5em 1em 0;
		display: flex;
		align-items: center;
		font-size: 0.8125em;
		line-height: 1.25em;
	}

	&__item-img {
		max-width: 30px;
		margin-right: 0.5em;
		//border-radius: 50%;
	}
}