.table-of-contents {
	list-style: none;
	padding: 0;
	margin: 0 0 map-get($spacing, small) 0;
	font-size: $font-size-base;


	@media #{$sm-up}{
		columns: 2;
	}


	&__item {
		padding: 0 map-get($spacing, tiny) map-get($spacing, tiny) map-get($spacing, medium);
		position: relative;
		margin-top: map-get($spacing, tiny);
		max-width: 100%;

		@media #{$sm-up}{
			padding: 0 map-get($spacing, small) map-get($spacing, tiny) map-get($spacing, medium);
		}

		&:before {
			content: " ";
			display: block;
			width: 6px;
			height: 6px;
			background: map-get($brand-colors, red-light);
			position: absolute;
			left: 0;
			top: 8px;
		}
	}

	&__trigger {
		display: block;
		color: $text-color;
		text-decoration: underline;
	}
}
