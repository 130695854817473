/*================================================================================
[Finish swatch list]
*/
.finish-swatch-list {
	list-style: none;
	margin: 0 0 map-get($spacing, medium);
	padding-left: 0;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	&--left {
		justify-content: flex-start;
	}

	&--page-support {
		margin-top: map-get($spacing, medium);
	}

	&--wide {
		flex-basis: 33.33%;
		min-width: 150px;
	}

	/*------- [Modifiers] -------*/
	&--mini {
		margin-bottom: map-get($spacing, tiny);
		.finish-swatch__img {
			max-width: 30px;
		}

		.finish-swatch {
			&:hover {
				.finish-swatch__img {
					transform: scale(1.7);
				}
			}
		}

		.finish-swatch__link {
			margin: 0 5px;
		}

		.finish-swatch__name {
			line-height: 0.8;
			font-size: 0.4em;
			padding: 0.75em 0.75em 0.5em 0.75em;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
		}
	}

	/*------- [Modifiers] -------*/
	&--small {
		margin-bottom: map-get($spacing, tiny);
		.finish-swatch__img {
			max-width: 60px;
			max-height: 60px;
		}

		.finish-swatch {
			&:hover {
				.finish-swatch__img {
					transform: scale(1.2);
				}
			}
		}

		.finish-swatch__link {
			margin: 0 5px;
		}

		.finish-swatch__name {
			line-height: 0.8;
			font-size: 0.4em;
			padding: 0.75em 0.75em 0.5em 0.75em;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
		}
	}

	&--static {
		.finish-swatch {
			pointer-events: none;
		}

		.finish-swatch__name {
			opacity: 1;
			white-space: normal;
			text-transform: none;
			transform: translateX(-50%);
			//position: static;
			text-align: center;
		}
	}

	&--compact {
		.finish-swatch {
			margin-bottom: map-get($spacing, tiny);
		}
	}

	&--inline {
		.finish-swatch {
			flex-direction: row;
			align-items: center;
		}
	}
}

/*================================================================================
[Finish swatch]
*/
.finish-swatch {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 10px;

	&:hover {
		.finish-swatch__img {
			border: 2px solid transparent;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
			transform: scale(1.3);
		}

		.finish-swatch__link.state-selected {
			.finish-swatch__img {
				border: 2px solid map-get($brand-colors, red-dark);
			}
		}
	}

	&__link {
		display: block;
		margin: 0 4px;
	}

	&__img {
		display: block;
		max-width: 40px;
		max-height: 30px;
		border: 0 solid transparent;
		border-radius: 50%;
		transform: scale(1);
		transition: all $transition-med ease;
		position: relative;
		z-index:15;

		.finish-swatch__link.state-selected & {
			border: 2.5px solid map-get($brand-colors, red-dark);
		}

		@media #{$sm-up}{
			max-width: 50px;
			max-height: 40px;
		}
	}

	&__name {
		position: absolute;
		top: calc(100% + #{map-get($spacing, tiny)});
		left: 50%;
		transform: translateX(-50%) translateY(-8px) scale(0.5);
		background: #fff;
		text-transform: uppercase;
		font-weight: 700;
		color: $text-color;
		white-space: nowrap;
		opacity: 0;
		pointer-events: none;
		font-size: 0.7em;
		transition: opacity $transition-fast ease, transform $transition-fast ease;
		z-index: $zindex-tooltip;

		.finish-swatch:hover & {
			opacity: 1;
			font-size: 0.7em;
			transform: translateX(-50%) translateY(11px) scale(1);
			transition: opacity $transition-fast ease $transition-fast, transform $transition-fast ease $transition-fast;
		}
	}

	&--all {
		.finish-swatch__img, &:hover .finish-swatch__img {
			border: 2px solid #000;
		}
	}
}
