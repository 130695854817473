.contact-card-list {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	display: block;

	@media #{$xsl-up} {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		display: block;
		margin-bottom: map-get($spacing, small);

		@media #{$xsl-up} {
			display: flex;
			width: calc(50% - #{map-get($spacing, small)});
			margin-right: map-get($spacing, small);
		}

		@media #{$md-up} {
			width: calc(33% - #{map-get($spacing, small)});
		}
	}
}

.contact-card {
	background: map-get($brand-colors, gray-lighter);
	padding: map-get($spacing, medium);
	font-size: 1.075em;
	width: 100%;

	&__controls {
		float: right;
		text-align: right;
		position: relative;
		z-index: 1;
	}

	&__body {
		position: relative;
		//top: -1.25em;
		//margin-bottom: -1.25em;
	}

	&__label {
		@include pfont-bold;
		margin-bottom: map-get($spacing, tiny);
		margin-right: 4.5em; // Leave some room so we don't bump into the controls
	}

	&__img {
		margin: map-get($spacing, tiny) 0;
		max-width: calc(100% - 50px);
		max-height: 80px;
	}

	&__email {
		display: inline-block;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}