/*================================================================================
[Breadcrumbs]
*/
.breadcrumbs {
	margin: map-get($spacing, medium) 0;
	font-size: 0.875em;
	line-height: 1.66;

	&__list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: inline-block;
		margin-right: 0.75em;

		&:after {
			content: '>';
			font-size: 0.75em;
			margin-left: 0.75em;
			display: inline-block;
		}

		&:last-child {
			margin-right: 0;

			&:after {
				content: '';
				display: none;
			}
		}

		a {
			color: $text-color;

			&, &:hover, &:active, &:focus {
				text-decoration: underline;
			}
		}
	}

	&--absolute {
		left: 0;
		right: 0;
		max-width: $content-wrapper-width;
		margin: 0 auto;
		top: map-get($spacing, large);
		z-index: $zindex-low;
		color: white;
		position: absolute;
		padding-left: $grid-gutter-width;

		@media (min-width: $content-wrapper-width) {
			padding-left: 0;
		}

		.breadcrumbs__item {
			a {
				color: white;

				&:hover {
					color: white;
				}
			}
		}
	}

	&--blog {
		.breadcrumbs__item {
			max-width: 400px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@media #{$xs-only}{
				max-width: 180px;
			}
		}

		@media #{$xs-only}{
			margin: map-get($spacing, tiny) 0 map-get($spacing, small) 0;
		}
	}
}
