.sitemap {

	&__list {

	}

	&__item {
		&--1 {
			> .sitemap__link {
				@include pfont-bold;
			}
		}
	}

	&__link {
		text-decoration: none;
		color: map-get($brand-colors, gray-dark);
		display: block;
	}
}
