.hero-rotator {
	@include clearfix;
	position: relative;
	margin: 0 auto;
	max-width: $container-lg;
	min-height: 548px;

	@media #{$sm-up}{
		display: flex;
		justify-content: flex-end;
	}


	@media #{$md-up}{
		min-height: 795px;
	}

	&__img-wrap {
		margin: auto 20%;
		position: relative;
		display: flex;
		justify-content: center;

		@media #{$sm-up}{
			margin: auto 5%;
		}
	}

	&__callouts {
		position: relative;
	}

	&__img {
		max-width: 100%;
		height: auto;
		display: block;
		visibility: hidden;
		opacity: 0;
		position: relative;
		transition: opacity 0.25s ease;

		@media #{$xs-only}{
			width: auto;
			max-height: 200px;
			margin: 0 auto;
		}

		@media #{$sm-only}{
			max-height: 500px;
		}

		@media #{$md-up}{
			max-height: 700px;
		}

		&--cloned {
			opacity: 0;
			visibility: hidden;
			transition: all 0.25s ease;
			z-index: 2;
			position: absolute;
			margin:0;

			&.state-visible {
				opacity: 1;
				visibility: visible;
			}
		}

		.hero-rotator--left & {
			&.state-visible {
				visibility: visible;
				opacity: 1;
			}
		}

		.hero-rotator--right & {
			&.state-visible {
				visibility: visible;
				opacity: 1;
			}
		}

	}

	&__top {
		width: 100%;
		text-align: center;

		@media #{$xs-only}{
			margin: map-get($spacing, medium) 0;
		}

		@media #{$sm-up} {
			position: absolute;
			top: 0;
			text-align: left;
			left: map-get($spacing,large);
			width: auto;
			right: 0;
		}
	}

	&__bottom {
		width: 100%;

		@media #{$sm-up}{
			max-width: 50%;
			position: absolute;
			bottom: 13%;
			left: map-get($spacing,small);
		}
	}

	&__heading {
		@include responsive-type($super-jumbo-h1-map);
		@include pfont-bold;

		line-height: 1.2;

		@media #{$md-up}{
			letter-spacing: -5px;
		}
	}

	&__desc {
		color: map-get($brand-colors, red-light);
		@include pfont-bold;
	}

	&__icon {
		position: relative;
		top: 2px;
	}

	&__kicker {
		max-width: 100%;
	}

	&__title {
		@include responsive-type($h3-map);
		@include pfont-bold;
		line-height: 1.2;
		margin-bottom: map-get($spacing, small);
		max-width: 100%;

		@media #{$sm-up}{
			@include responsive-type($h2-map);
		}
	}

	&__list {
		@include list-unstyled;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			display: block;
			height: 100%;
			width: 50px;
			@include gradient-horizontal(rgba(map-get($brand-colors, white), 1), rgba(map-get($brand-colors, white), 0), 70%, 100%);
			z-index: 2;

			@media #{$sm-up}{
				width: 60px;
			}
		}

		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			display: block;
			height: 100%;
			width: 50px;
			@include gradient-horizontal(rgba(map-get($brand-colors, white), 0), rgba(map-get($brand-colors, white), 1), 0%, 30%);
			z-index: 2;

			@media #{$sm-up}{
				width: 60px;
			}
		}
	}

	&__list-item {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 map-get($spacing, huge);
		min-height: 150px;

		@media #{$sm-up} {
			padding: 0 map-get($spacing, giant);
			min-height: 200px;
		}

		@media #{$md-up}{
			min-height: 250px;
		}
	}

	&__extended-content {
		max-width: 100%;
	}

	&__callout {
		display: none;

		@media #{$sm-up}{
			transition: opacity 0.25s ease-in;
			opacity: 0;
			visibility: hidden;
			position: absolute;
			display: flex;
			@include claire_handregular;
			line-height: 1.1;
			font-size: 0.8em;
			min-width: 180px;

			&.state-visible {
				visibility:visible;
				opacity: 1;
			}
		}

		@media #{$md-up}{
			font-size: 1em;
			min-width: 200px;
		}
	}

	&__arrow {
		position: absolute;
		top: 5px;
		width: 60px;
		height: 60px;
		z-index: 1;
		animation-duration: 0.35s;
		animation-timing-function: steps(9);
		animation-iteration-count: 3;
		background-image: url(../img/ui/arrow2_black_30fps.svg);
		background-repeat: no-repeat;
		opacity: 1;
		transition: opacity $transition-med ease;

		/*------- [Modifiers] -------*/
		&--left {
			transform: rotate(-100deg);
			left: calc(100% - 50px);
		}

		&--right {
			transform: translateY(-50%) rotate(460deg);
			right: calc(100% - 50px);
			top: 50%;
			margin-top: 15px;

			/*transform: scaleX(-1) rotate(270deg);
			right: calc(100% - 50px);
			filter: FlipH;
			-ms-filter: "FlipH";*/
		}

		/*------- [States] -------*/
		&.state-playing {
			animation-name: arrow-jump;
		}

		&.state-hidden {
			opacity: 0;
		}
	}

	&--right {
		justify-content: flex-start;

		.hero-rotator__top {
			@media #{$sm-up}{
				right: 0;
				width: auto;
				text-align: right;
				justify-self: flex-end;
			}
		}

		.hero-rotator__bottom {
			@media #{$sm-up}{
				left: 50%;
			}
		}
	}
}

@keyframes arrow-jump {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -540px 0;
	}
}
