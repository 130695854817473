.rating {
	display: inline-flex;
	align-items: center;

	/*------- [Modifiers] -------*/
	&--mini {
		font-size: 0.75em;

		.rating__star {
			&:before, &:after {
				font-size: 1em;
				letter-spacing: 0.2em;
			}
		}
	}

	&--large {
		font-size: 1.25em;

		.rating__star {
			margin: 0 0.075em;
		}
	}

	/*------- [Elements] -------*/
	&__stars {
		display: flex;
		flex: 0 0 auto;
		@include list-unstyled;
		margin: 0;
		padding: 0;

		/*------- [States] -------*/
		&.state-hidden {
			@media #{$sm-up} {
				opacity: 0;
			}
		}
	}

	&__star {
		flex: 0 0 auto;
		perspective: 1000px;
		transform: scale(1);
		transition: all $transition-fast ease-in-out;
		position: relative;

		&:before, &:after {
			content: "\e92f";
			font-family: icomoon;
			display: inline-block;
			font-size: 1.5em;
			color: darken( map-get($brand-colors, gray-light), 5%);
		}

		.rating--large & {
			&:before, &:after {
				font-size: 28px;
			}
		}

		&:after {
			display: none;
		}

		&--filled, &--half, &--quarter, &--three-quarters {
			&:before {
				z-index: 0;
			}

			&:after {
				display: block;
				color: map-get($brand-colors, yellow);
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
			}
		}

		&--filled {
			&:before {
				opacity: 0;
			}
		}

		&--half, &--quarter, &--three-quarters {
			&:after {
				overflow: hidden;
			}
		}

		&--quarter:after {
			width: 0.35em;
		}

		&--half:after {
			width: 0.51em;
		}

		&--three-quarters:after {
			width: 0.65em;
		}

		/*------- [Animation delay] -------*/
		// TODO Move to hero-review module
		@for $i from 0 through 5 {
			&:nth-child(#{$i}) {
				animation-delay: 0.33s * $i;
			}
		}
	}

	&__average {
		flex: 0 0 auto;
		@include pfont-bold;
		text-transform: uppercase;
		padding-left: map-get($spacing, tiny);
		font-size: 1em;
	}

	&__users {
		flex: 0 0 auto;
		padding-left: map-get($spacing, tiny);
		font-size: 1em;
	}

	&__link {
		margin-left:  map-get($spacing, tiny);
		a {
			color: map-get($brand-colors, black);
			text-decoration: underline;

			&:hover {
				color: map-get($brand-colors, red-light);
			}
		}
	}

	/*.list-menu-inline & {
		margin-top: -7px;
		position: relative;
		top: 4px;
	}*/
}
