/*================================================================================
[Arrow callout]
*/
.arrow-callout {
	position: relative;
	display: inline-flex;
	align-items: center;
	max-width: 25em;
	min-width: 11em;
	justify-self: flex-end;
	@include claire_handregular;
	font-size: 1.2em;
	transform: scale(0.75);
	transform-origin: 0 0;
	//transition: all $transition-fast ease;

	&.js-arrow-callout {
		position: absolute;
		left: 0;
		opacity: 0;
	}

	// Scale down the entire callout as the screen gets smaller
	@media #{$md-up} {
		transform: scale(0.85);
	}

	@media #{$lg-up} {
		transform: scale(1);
	}

	// To keep right- and bottom-aligned callouts pegged to the right spot on the target image,
	// we need to change which corner they scale from
	&--right {
		transform-origin: 100% 0;
	}

	&--bottom {
		transform-origin: 0 100%;
	}

	&--bottom.arrow-callout--right {
		transform-origin: 100% 100%;
	}

	/*------- [Elements] -------*/
	&__text {
		max-width: 9em;
		text-align: right;
		line-height: 1.25;
		flex-shrink: 0;
		flex-grow: 1;

		.arrow-callout__arrow + & {
			text-align: left;
		}

		.arrow-callout--text-large & {
			max-width: 10em;
		}
	}

	&__arrow {
		position: relative;
		left: -20px;
		top: 30px;
		min-width: 60px;
		min-height: 60px;
		height:60px;
		width: 60px;
		z-index: 1;
		animation-duration: 0.35s;
		animation-timing-function: steps(9);
		animation-iteration-count: 3;
		background: url(../img/ui/arrow2_black_30fps.svg) no-repeat;
		transform: rotate(-25deg);
		opacity: 1;
		transition: opacity $transition-med ease;

		@media #{$sm-down}{
			.arrow-callout__container & {
				background-size: auto 40px;
				min-width: 40px;
				min-height: 40px;
				height:40px;
				width: 40px;
				top:15px;
			}
		}

		&--flipped {
			transform: scale(-1, 1) rotate(-90deg);
			left: -5px;
			top: 20px;
		}

		&--sw {
			transform: rotate(-25deg);
			left: -20px;
			top: 30px;

			@media #{$sm-down}{
				.arrow-callout__container & {
					top: 10px;
				}
			}

			&.arrow-callout__arrow--flipped {
				transform: scale(-1, 1) rotate(-90deg);
				left: -5px;
				top: 20px;

				@media #{$sm-down}{
					.arrow-callout__container & {
						top: 10px;
					}
				}
			}
		}

		&--s {
			transform: rotate(-60deg);
			left: -5px;

			&.arrow-callout__arrow--flipped {
				transform: scale(-1, 1) rotate(-70deg);
				left: 0;
			}
		}

		&--se {
			transform: rotate(-95deg);
			left: 5px;
			top: 15px;

			@media #{$sm-down}{
				.arrow-callout__container & {
					top: 10px;
				}
			}

			&.arrow-callout__arrow--flipped {
				transform: scale(-1, 1) rotate(-10deg);
				top: 35px;
				left: 25px;

				@media #{$sm-down}{
					.arrow-callout__container & {
						top: 25px;
					}
				}
			}
		}


		&--n {
			transform: scale(1, -1) rotate(-70deg);
			left: -5px;
			top: -20px;

			@media #{$sm-down}{
				.arrow-callout__container & {
					top: -15px;
				}
			}

			&.arrow-callout__arrow--flipped {
				transform: scale(-1, -1) rotate(-70deg);
				left: 0;
				top: -20px;

				@media #{$sm-down}{
					.arrow-callout__container & {
						top: -15px;
					}
				}
			}
		}

		&--ne {
			transform: scale(1, -1) rotate(-95deg);
			left: 5px;
			top: -15px;

			@media #{$sm-down}{
				.arrow-callout__container & {
					top: -10px;
				}
			}

			&.arrow-callout__arrow--flipped {
				transform: scale(-1, -1) rotate(-10deg);
				top: -35px;
				left: 25px;

				@media #{$sm-down}{
					.arrow-callout__container & {
						top: -30px;
					}
				}
			}
		}

		&--nw {
			transform: scale(1, -1) rotate(-25deg);
			left: -20px;
			top: -30px;

			@media #{$sm-down}{
				.arrow-callout__container & {
					top: -25px;
				}
			}

			&.arrow-callout__arrow--flipped {
				transform: scale(-1, -1) rotate(-90deg);
				left: -5px;
				top: -20px;

				@media #{$sm-down}{
					.arrow-callout__container & {
						top: -15px;
					}
				}
			}
		}

		/*------- [States] -------*/
		&.state-playing {
			animation-name: arrow-jump;
		}

		&.state-hidden {
			opacity: 0;
		}
	}

	/*&__container {
		position: absolute;
		@include claire_handregular;
		z-index: ;
	}*/

	&__sticky-right {
		right: 0 !important;
		left: auto !important;
	}

	&__sticky-left {
		left: 0 !important;
		right: auto !important;
	}

	//[ VARIANTS FOR TEXT SIZING ]

	&--text-small {

		font-size: 0.9em;

		.js-arrow-callout & {
			font-size: inherit;
		}
	}

	&--text-large {
		font-size: 1.8em;

		.js-arrow-callout & {
			font-size: inherit;
		}
	}

	//[ VARIANTS FOR WIDTH SETTING ]

	&--narrow {
		max-width: 9em;

		@media #{$md-up} {
			max-width: 9.75em;
		}

		.arrow-callout__text {
			max-width: 7em;

			@media #{$md-up} {
				max-width: 7.75em;
			}
		}
	}

	&--wide {
		max-width: 100%;
		@media #{$sm-up} {
			max-width: 30em;
		}

		.arrow-callout__text {
			max-width: 100%;

			@media #{$sm-up} {
				max-width: 26em;
			}
		}
	}
}

@keyframes arrow-jump {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -540px 0;
	}
}

/*================================================================================
[Arrow callout image wrapper]
*/
.arrow-callout-wrapper {
	position: relative;

	.js-arrow-callout {
		opacity: 1;
	}
}

.arrow-callout-target {
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: rgba(255,0,0,0.25);
	transform: translate(-10px, -10px);
}
