// Alerts
$super-jumbo-h1-map: (
		$screen-xs-max : $font-size-h1-super-jumbo * 0.4,
		$screen-sm-min : $font-size-h1-super-jumbo * 0.7,
		$screen-md-min : $font-size-h1-super-jumbo * 0.9,
		$screen-lg-min : $font-size-h1-super-jumbo
);

$jumbo-h1-map: (
		$screen-xs-max : $font-size-h1-jumbo * 0.6,
		$screen-sm-min : $font-size-h1-jumbo * 0.8,
		$screen-md-min : $font-size-h1-jumbo * 0.9,
		$screen-lg-min : $font-size-h1-jumbo
);

$h1-map: (
		$screen-xs-max : $font-size-h1 * 0.7,
	    $screen-sm-min : $font-size-h1 * 0.8,
		$screen-md-min : $font-size-h1 * 0.9,
		$screen-lg-min : $font-size-h1
);

$super-h2-map: (
		$screen-xs-max : $font-size-h2-super * 0.7,
		$screen-sm-min : $font-size-h2-super * 0.8,
		$screen-md-min : $font-size-h2-super * 0.9,
		$screen-lg-min : $font-size-h2-super,
);

$h2-map: (
		$screen-xs-max : $font-size-h2 * 0.7,
		$screen-sm-min : $font-size-h2 * 0.8,
		$screen-md-min : $font-size-h2 * 0.9,
		$screen-lg-min : $font-size-h2
);

$h3-map: (
		$screen-xs-max : $font-size-h3 * 0.80,
		$screen-sm-min : $font-size-h3 * 0.85,
		$screen-md-min : $font-size-h3 * 0.9,
		$screen-lg-min : $font-size-h3
);

$h4-map: (
		$screen-xs-max : $font-size-h4 * 0.85,
		$screen-sm-min : $font-size-h4 * 0.9,
		$screen-md-min : $font-size-h4 * 0.95,
		$screen-lg-min : $font-size-h4
);

$h5-map: (
		$screen-xs-max : $font-size-h5 * 0.9,
		$screen-sm-min : $font-size-h5 * 0.95,
		$screen-md-min : $font-size-h5
);

$h6-map: (
		$screen-xs-max : $font-size-h6 * 0.8,
		$screen-sm-min : $font-size-h6 * 0.95,
		$screen-md-min : $font-size-h6
);

$base-map: (
		$screen-xs-max : $font-size-base * 0.9,
		$screen-sm-min : $font-size-base * 0.95,
		$screen-md-min : $font-size-base * 1,
		$screen-lg-min : $font-size-base * 1
);

@mixin responsive-type($rt-map) {
	@each $rt-breakpoint, $rt-font-size in $rt-map {
		@if $rt-breakpoint == $screen-xs-max {
			@media screen and (max-width: $rt-breakpoint) {
				font-size: $rt-font-size;
			}
		} @else {
			@media screen and (min-width: $rt-breakpoint) {
				font-size: $rt-font-size;
			}
		}
	}
}
