.no-wrap {
	white-space: nowrap;
}

@each $bpkey, $bp in $breakpoints {
	@media #{$bp} {
		.no-wrap-#{$bpkey} {
			white-space: nowrap !important;
		}
	}
}

