@media print {
	/*================================================================================
	[Global]
	*/
	/*@page {
		size: 8.5in 11in;
		margin: 0.25in;
	}*/
	@page {
		size: 330mm 427mm;
		margin: 14mm;
	}

	.container {
		width: 1170px;
	}

	.alert, .site-header__nav-toggle, .main-nav, .secondary-nav, .site-header__search, .breadcrumbs, .mastfoot, .site-footer__signup-form,
	.footer-subnav__copyright li:not(:first-child), .footer-subnav__social {
		display: none !important;
	}

	.site-header {
		margin: 0 !important;

		&__logo {
			-webkit-print-color-adjust: exact;
			background: map-get($brand-colors, red-dark) !important;
			margin: 0 0 2em !important;
		}
	}

	.footer-subnav__copyright {
		margin: 0 auto 2em;
		border-color: #000;

		&, * {
			color: #000;
		}
	}

	/*================================================================================
	[Product grid]
	*/
	.product-grid {
		&__item {
			page-break-inside: avoid;
		}
	}

	.product-card {
		-webkit-print-color-adjust: exact;
		border: 1px solid map-get($brand-colors, gray-light);
		page-break-inside: avoid;

		&__finishes {
			.finish-swatch-list {
				display: none;
			}
		}
	}
}