.table--matrix {
	//shared table matrix styles
	thead tr th {
		text-align: center;
		background: white;
		padding: 0 map-get($spacing, small);

		div {
			font-weight: normal;
		}
	}

	tbody tr td {
		text-align: center;
		background: map-get($brand-colors, gray-lighter);
		border: 2px solid white;

		strong {
			display: block;
			@include responsive-type($h4-map);
		}

		&:first-child {
			background: white;
			img {
				width: 100%;
				height: auto;
				max-width: 60px;
			}
		}

		&:nth-child(2) {
			strong {
				display: block;
				@include responsive-type($h5-map);
			}
			background: white;
			text-align: left;
		}

		[class^="icon-"] {
			font-size: 2em;
		}
	}

	//styles specific to the contaminants table
	&.table--matrix-contaminants {
		tbody tr td {
			&:nth-child(3) {
				background: map-get($brand-colors, gray-light);
			}
		}
	}

	//styles specific to savings table
	&.table--matrix-savings {
		tbody tr td {
			&:nth-child(3) {
				background: map-get($brand-colors, gray-lighter);
			}

			&:last-child {
				background: map-get($brand-colors, gray-light);
			}
		}
	}

	//style overrides for mobile versions
	&.table--mobile-friendly {

		@media #{$xs-only}{
			//shared overrides
			tbody tr td {
				text-align: right;
				padding-right: map-get($spacing, small);

				&:first-child {
					text-align: center;
					padding:  map-get($spacing, small) 0 0;

					&:after {
						display: none;
					}
				}

				&:nth-child(2) {
					text-align: center;
					padding: map-get($spacing, small) 0;
					position: relative;

					&:after {
						content: " ";
						position: absolute;
						left: 50%;
						top: 100%;
						transform: translateX(-50%);
						width: 0;
						height: 0;
						z-index: 10;
						border-left: 20px solid transparent;
						border-right: 20px solid transparent;
						border-top: 20px solid white;
					}
				}

				&:before {
					text-align: left;
				}
			}

			///mobile style overrides specific to savings table
			&.table--matrix-savings {
				tbody tr td {
					vertical-align: middle;
					padding: map-get($spacing, medium) map-get($spacing, small) map-get($spacing, medium) 75%;

					&:first-child {
						padding: map-get($spacing, small) 0 0 0;
						min-height: 0;
					}

					&:nth-child(2) {
						padding: 0 0 map-get($spacing, small) 0;
						min-height: 0;
					}

					&:before {
						width: 75%;
					}
				}
			}


			//mobile style overrides specific to contaminants table
			&.table--matrix-contaminants {
				tbody tr td {
					padding-left: 50%;

					&:first-child {
						padding: map-get($spacing, small) 0 0 0;
					}

					&:nth-child(2) {
						padding: 0 0 map-get($spacing, small) 0;
					}

					&:before {
						width: 45%;
					}
				}
			}
		}
	}
}
