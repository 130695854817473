//USED TO CREATE PADDING ON TOP AND BOTTOM BASED ON SASS MAP OPTIONS


@each $section-key, $section-value in $spacing {
	.section-#{$section-key} {
		//specify top and bottom so we dont overwrite any left and right padding that may be on a section
		padding-top: $section-value !important;
		padding-bottom: $section-value !important;

		@if $section-key == huge or $section-key == giant or $section-key == massive {
			@media #{$xs-only}{
				padding-top: map-get($spacing, medium) !important;
				padding-bottom: map-get($spacing, medium) !important;
			}
		}
	}

	.section-#{$section-key}-bottom {
		padding-bottom: $section-value !important;

		@if $section-key == huge or $section-key == giant or $section-key == massive {
			@media #{$xs-only}{
				padding-bottom: map-get($spacing, medium) !important;
			}
		}
	}

	.section-#{$section-key}-top {
		padding-top: $section-value !important;

		@if $section-key == huge or $section-key == giant or $section-key == massive {
			@media #{$xs-only}{
				padding-top: map-get($spacing, medium) !important;
			}
		}
	}

	//RESPONSIVE MODIFIERS
	@each $bpkey, $bp in $breakpoints {
		@media #{$bp} {

			.section-#{$section-key}-#{$bpkey} {
				padding-top: $section-value !important;
				padding-bottom: $section-value !important;
			}

			.section-#{$section-key}-bottom-#{$bpkey} {
				padding-bottom: $section-value !important;
			}

			.section-#{$section-key}-top-#{$bpkey} {
				padding-top: $section-value !important;
			}
		}
	}
}

/*================================================================================
[Special case sections]
*/

// Tools for Pros home page
.section-grid-bg {
	background-image: url('//images.pfisterfaucets.com/is/image/Pfister/ToolsForPros-GridBackground?$pfHugeAlpha$&fit=constrain');
	background-size: auto 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;

	@media #{$lg-up} {
		background-size: cover;
	}
}