/*================================================================================
[Hero image]
*/
.hero-image {
	position: relative;
	height: 90vh;
	min-height: 400px;
	max-height: 550px;
	overflow:hidden;
	background: map-get($brand-colors, gray-dark);

	@media #{$sm-up}{
		max-height: 800px;
	}

	&--video {
		@media #{$sm-only} {
			max-height: 550px;
		}
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		z-index: 1;
		display: block;
		background: rgba(20, 20, 20, 0.2);
	}

	/*------- [Shared Module Elements Styles] -------*/
	&__bg {
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: cover;



		&--video {
			object-position: center center;

			@media #{$xs-only}{
				position: absolute;
				right: 0;
				height: auto;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 100%;

				&:not([data-mobile-src]) {
					height: 100%;
					width: auto;
				}

				&.hero-image__bg--align-top {
					transform: none;
					top: 0;
					bottom: auto;
				}
				&.hero-image__bg--align-center {
					top: 50%;
					transform: translateY(-50%);
					bottom: auto;
				}
				&.hero-image__bg--align-bottom {
					bottom: 0;
					top: auto;
					transform: none;
				}

				.ie11 & {
					height: auto !important;
					right: 0 !important;
					left: 0 !important;
					transform: none !important;
					width: 100% !important;
					margin: 0 !important;
					//were having to overwrite a polyfill here for
					//mobile only therefore !important is used
				}


			}
			&.hero-image__bg--align-top {
				object-position: center top;
				@media #{$xs-only}{
					top: 0;
					transform: none;

					.ie11 & {
						top: 0 !important; //overwriting polyfill at mobile
						bottom: auto!important;//overwriting polyfill at mobile
					}
				}
			}
			&.hero-image__bg--align-center {
				object-position: center center;
				@media #{$xs-only}{
					top: 50%;
					transform: translateY(-50%);

					.ie11 & {
						bottom: auto !important; //overwriting polyfill at mobile
						top: 50% !important;
						transform: translateY(-50%);
					}
				}
			}
			&.hero-image__bg--align-bottom {
				object-position: center bottom;
				@media #{$xs-only}{
					bottom: 0;
					transform: none;

					.ie11 & {
						bottom: 0 !important; //overwriting polyfill at mobile
						top: auto!important;//overwriting polyfill at mobile
					}
				}
			}
		}


		//Microsoft edge does not support object fit on video elements so were
		//using this supports feature to detect edge only for proper video scaling
		@supports (-ms-ime-align: auto) {
			&--video {
				width: auto;
				height: 100%;
				min-height: 860px;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);

				@media #{$xs-only}{
					min-height: 650px;
					height: auto !important;
					top: auto !important;
					bottom: 0 !important;
					right: 0 !important;
					left: 0 !important;
					transform: none !important;
					margin-left: 0 !important;
					width: 100% !important;
					//were having to overwrite a polyfill here for
					//mobile only therefore !important is used

					&:not([data-mobile-src]) {
						height: 100% !important;
						width: auto !important;
					}
				}

				@media #{$sm-only}{
					min-height: 560px;
				}

				@media #{$sm-up}{
					&.hero-image__bg--align-bottom {
						top: auto;
						bottom: 0;
					}

					&.hero-image__bg--align-top {
						bottom: auto;
						top: 0;
					}
				}

				@media (min-width: 1440px){
					width: 100%;
					height: auto;
					top: 50%;
					left: auto;
					transform: translate(0, -50%);

					&.hero-image__bg--align-bottom {
						top: auto;
						bottom: 0;
						transform: none;
					}

					&.hero-image__bg--align-top {
						bottom: auto;
						top: 0;
						transform: none;
					}
				}
			}
		}
	}

	&__title {
		position: absolute;
		z-index: 2;

		&:after {
			content: '';
			position: absolute;
		}

		.hero-image__feature-icon + & {
			margin-top:0;
		}
	}

	&__title-text {
		margin-top: 0;
		color: #fff;
		@include responsive-type($jumbo-h1-map);
		line-height: 1;
		letter-spacing: -0.04em;
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

		margin-bottom: 0.25em;

		span {
			@include pfont-light;
		}
	}

	&__desc {
		@include responsive-type($h3-map);
		@include pfont-regular;
		line-height: 1.2;
	}

	&__footnote {
		position: absolute;
		color: white;
		@include pfont-bold;
		z-index: 3;
		left: 50%;
		bottom: map-get($spacing, small);
		font-size: 0.8rem;
		transform: translateX(-50%);
		width: 80%;
		display: flex;
		justify-content: center;
		align-items: center;

		@media #{$sm-up} {
			bottom: map-get($spacing, large);
			right: 10%;
			left: auto;
			transform: none;
			font-size: 1rem;
			width: auto;
			justify-content: flex-end;

		}
	}

	&__footnote-img {
		max-width: 120px;
		height: auto;
	}

	&__footnote-flex {
		display: flex;
		align-items: center;
	}

	&__feature-icon {
		img {
			max-width: 80px;
			max-height: 80px;
		}
	}

	&__video-icon {
		display: inline-block;
		text-decoration: none;
		color: map-get($brand-colors, true-white);
		font-size: 50px;
		margin-top: map-get($spacing, medium);

		@media #{$sm-up}{
			font-size: 70px;
		}

		&:hover {
			color: map-get($brand-colors, red-light);
			text-decoration: none;
		}
	}

	&__container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		z-index: 2;
	}

	&__content {
		max-width: 80%;

		&--has-ctas {
			margin-top: -16%;

			@media #{$sm-up}{
				margin-top: -14%;
			}
			@media #{$md-up}{
				margin-top: -8%;
			}
			@media #{$lg-up}{
				margin-top: -8%;
			}
		}

		@media #{$sm-up}{
			width: $container-sm;
		}

		@media #{$md-up}{
			width: $container-md;
		}

		@media #{$lg-up}{
			width: $container-lg;
		}
	}

	&__inner {
		position: relative;
		height: 100%;
		display: flex;
		margin: 0 auto;
		flex-direction: column;
	}

	/*------- [Modifiers] -------*/

	&--centered {
		.hero-image__content {
			@media #{$md-up}{
				max-width: 90%;
			}
		}
		.hero-image__feature-icon {
			justify-content: center;
			align-items: center;
			text-align: center;
		}
		.hero-image__inner {
			justify-content: center;
			align-items: center;
		}

		.hero-image__title {
			position: relative;
			text-align: center;
		}

		.hero-image__extended-content {
			text-align: center;
		}

		.hero-image__desc {
			text-align: center;
			padding: 0 10%;
		}

		.kicker {
			text-align: center;
		}
	}

	/*----------------------------*/

	&--bottom {
		.hero-image__title {
			bottom: map-get($spacing, giant);
			left: 10%;
			right: 10%;

			&:after {
				bottom: -0.5em;
				left: 0;
				border-bottom: 6px solid map-get($brand-colors, red-light);
				width: 10vw;
			}
		}

	}

	/*----------------------------*/

	&--left {
		.hero-image__inner {
			justify-content: center;
			align-items: flex-start;
		}
		.hero-image__content {
			left: 10%;
			position: relative;
			@media #{$md-up}{
				max-width: 45%;
			}
			@media #{$lg-up}{
				max-width: 40%;
			}

		}
		.hero-image__title {
			position: relative;
		}
	}

	/*----------------------------*/
	&--short {
		height: 40vh;
		min-height: 360px;

		@media #{$sm-up} {
			min-height: 500px;
		}
	}

	/*----------------------------*/
	&--parallax {
		.hero-image__bg {
			height: 120%;
			top: -120px;
		}
	}

	//Treatment that goes underneath the navigation
	/*----------------------------*/
	&--absolute {
		margin-top: -61px;
		z-index: 0;

		@media #{$sm-up}{
			margin-top: -77px;
		}

		// PFF02-160 - This was causing secondary-nav to be off the screen in IE11
		/*.ie11 & {
			@media #{$sm-up}{
				margin-top: -104px;
			}
		}*/
	}

	&--replacement-parts {
		height: 40vh;
		min-height: 360px;

		@media #{$sm-up} {
			min-height: 650px;
		}
	}

	/*------- [Special cases] -------*/
	// PFF02-385
	.bg-teal & {
		background: map-get($brand-colors, teal);
	}
}
