.product-grid {
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;
	@include list-unstyled;

	&__product-card {
		height: 100%;
	}

	&__item {
		flex: 1 1 100%;
		box-sizing: border-box;
		position: relative;
		order: 51;
		background: map-get($brand-colors, gray-lighter);

		.ie11 & {
			margin: 3px;
			flex: 1 1 calc(100% - 6px);
			max-width: calc(100% - 6px);
		}

		@media #{$xsl-up}{
			flex: 1 1 50%;
			.ie11 & {
				flex: 1 1 calc(50% - 6px);
				max-width: calc(50% - 6px);
			}
		}

		@media #{$md-up}{
			flex: 1 1 33.33%;
			.ie11 & {
				flex: 1 1 calc(33.33% - 6px);
				max-width: calc(33.33% - 6px);
			}
		}

		@media #{$lg-up}{
			flex: 1 1 25%;
			.ie11 & {
				flex: 1 1 calc(25% - 6px);
				max-width: calc(25% - 6px);
			}
		}

		@media (min-width: 1600px) {
			flex: 1 1 20%;
			.ie11 & {
				flex: 1 1 calc(20% - 6px);
				max-width: calc(20% - 6px);
			}
		}

		//nth-child (9) should serve as the spot for the feature.
		/*@media (min-width: $screen-md-min) and (max-width: 1599px){
			&:nth-child(9) {
				order: 4 !important;
			}
		}*/

		&--wide {
			flex: 1 1 100%;

			.ie11 & {
				flex: 1 1 calc(100% - 6px);
				max-width: calc(100% - 6px);
			}


			@media #{$xsl-up}{
				flex: 1 1 100%;

				.ie11 & {
					flex: 1 1 calc(100% - 6px);
					max-width: calc(100% - 6px);
				}
			}

			@media #{$md-up}{
				flex: 1 1 33.33%;

				.ie11 & {
					flex: 1 1 calc(33.33% - 6px);
					max-width: calc(33.33% - 6px);
				}
			}

			@media #{$lg-up}{
				flex: 1 1 25%;

				.ie11 & {
					flex: 1 1 calc(25% - 6px);
					max-width: calc(25% - 6px);
				}
			}

			@media (min-width: 1600px) {
				flex: 1 1 20%;

				.ie11 & {
					flex: 1 1 calc(20% - 6px);
					max-width: calc(20% - 6px);
				}
			}
		}
	}


	@supports(display: grid) {
		@include list-unstyled;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-auto-rows: minmax(auto, auto);
		grid-gap: 6px;
		list-style: none;
		margin: 0;
		grid-auto-flow: dense;
		order: 51;

		@for $i from 0 through 24 {
			> *:nth-child(#{$i}) {
				order: $i;
			}
		}

		&--offset {
			> *:nth-child(1) {
				@media (min-width: 1600px) {
					grid-column-start: 2;
				}
			}
		}

		&__item {
			position: relative;
			overflow: hidden;

			&--tall {
				@media #{$xsl-up}{
					grid-row: span 2;
				}
			}

			&--wide {
				@media #{$xsl-up}{
					grid-column: span 2;
				}
			}

		}

		@media #{$xsl-up}{
			grid-template-columns: repeat(2, 1fr);
		}

		@media #{$md-up}{
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: minmax(300px, auto);
		}

		@media #{$lg-up}{
			grid-template-columns: repeat(4, 1fr);
		}

		@media (min-width: 1600px){
			grid-template-columns: repeat(5, 1fr);
		}
	}
}
