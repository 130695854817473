.collection-grid {
	@include list-unstyled;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	flex-wrap: wrap;

	&__item {
		flex: 0 0 50%;
		overflow: hidden;
		box-sizing: border-box;
		position: relative;
		margin-bottom: 0;

		.ie11 & { max-width: 50% }


		&:after {
			content: "";
			width: map-get($spacing, tiny);
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			height: 100%;
			z-index: 10;
		}

		&:first-child {
			&:after {
				display: none;
			}
		}

		@media #{$sm-down}{
			&:nth-child(odd) {
				&:after {
					display: none;
				}
			}

			.ie11 & {
				margin: 3px;
				flex: 0 0 calc(50% - 6px);
				max-width: calc(50% - 6px);
			}
		}


		@media #{$md-up}{
			flex: 0 0 33.33%;

			&:nth-child(3n + 3) {
				&:after {
					display: none;
				}
			}

			.ie11 & {
				margin: 3px;
				flex: 0 0 calc(33.33% - 6px);
				max-width: calc(33.33% - 6px);
			}
		}

		@media #{$lg-up}{
			flex: 0 0 25%;

			&:nth-child(4n + 4) {
				&:after {
					display: none;
				}
			}

			.ie11 & {
				margin: 3px;
				flex: 0 0 calc(25% - 6px);
				max-width: calc(25% - 6px);
			}
		}

		@media (min-width: 1600px){
			flex: 0 0 20%;

			&:nth-child(5n + 5) {
				&:after {
					display: none;
				}
			}

			.ie11 & {
				margin: 3px;
				flex: 0 0 calc(20% - 6px);
				max-width: calc(20% - 6px);
			}
		}
	}

	@supports(display: grid) {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-auto-rows: minmax(200px, auto);
		grid-gap: map-get($spacing, tiny);
		list-style: none;
		margin: 0;
		grid-auto-flow: dense;

		&__item {
			position: relative;
			overflow: hidden;

			&:after {
				display: none;
			}

			&--tall {
				@media #{$xsl-up}{
					grid-row: span 2;
				}
			}

			&--wide {
				@media #{$xsl-up}{
					grid-column: span 2;
				}
				@media #{$sm-only}{
					grid-column: span 1;
				}
			}
		}

		@media #{$xsl-up}{
			grid-template-columns: repeat(2, 1fr);
		}

		@media #{$md-up}{
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: minmax(300px, auto);
		}

		@media #{$lg-up}{
			grid-template-columns: repeat(4, 1fr);
		}

		@media (min-width: 1600px){
			grid-template-columns: repeat(5, 1fr);
		}
	}

	&__sc{
		grid-gap: 35px;
	}
}
