.arrow {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		display: block;
		z-index: 10;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-top: 20px solid white;
	}

	&--teal {
		&:after {
			border-top: 20px solid map-get($brand-colors, legacy-teal);
		}
	}
}
