/*================================================================================
[Feature]
*/

.feature {
	width: 100%;
	padding-top: 56.25%;
	min-height: 18em;
	position: relative;

	/*------- [Variants] -------*/
	&--short {
		padding-top: 45%;
		min-height: 12em;
	}

	&--in-grid {
		height: 100%;
	}

	/*------- [Elements] -------*/

	&__link {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		transition: padding-bottom $transition-med ease;
		overflow: hidden;

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			//background: rgba(#000, 0.1);
			background: linear-gradient(to top, rgba(#000, 0.33), rgba(#000, 0));
			transition: background $transition-med ease;
		}

		.feature:hover & {
			text-decoration: none;
			padding-bottom: map-get($spacing, tiny);

			&:after {
				//background: rgba(#000, 0.2);
			}
		}

		> *:last-child {
			&:after {
				content: '';
				display: block;
				margin: map-get($spacing, small) 0 0.75em;
				width: 30%;
				border-top: 4px solid map-get($brand-colors, red-light);
				transition: width $transition-med ease;
			}

			.feature:hover &:after {
				width: 50%;
			}
		}

		.feature--top-alignment & {
			justify-content: flex-start;
			padding-top:map-get($spacing, small);

			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 1;
				//background: rgba(#000, 0.1);
				background: linear-gradient(to bottom, rgba(#000, 0.33), rgba(#000, 0));
				transition: background $transition-med ease;
			}
		}
	}

	&__bg {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
		transition: transform $transition-slow ease, opacity $transition-slow ease;

		.feature:hover & {
			transform: scale(1.1, 1.1);
			opacity: 0.9;
		}
	}

	&__heading {
		color: #fff;
		@include responsive-type($h2-map);
		font-weight: bold;
		z-index: 2;
		margin-left: map-get($spacing, medium);
		margin-right: map-get($spacing, medium);
		line-height: 1.1;
		text-shadow: 0px 2px 2px rgba(#000, 0.2);
	}

	&__kicker {
		color: map-get($brand-colors, tan);
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 0.025em;
		text-shadow: 0px 2px 2px rgba(#000, 0.2);
		z-index: 2;
		margin-left: map-get($spacing, medium);
		margin-right: map-get($spacing, medium);
		font-size: 0.8em;
	}

	&__desc {
		color: #fff;
		z-index: 2;
		font-size: 0.875em;
		margin-left: map-get($spacing, medium);
		margin-right: map-get($spacing, medium);
		margin-bottom: map-get($spacing, small);
	}

	/*------- [Variants] -------*/
	&--parallax {
		.feature__bg {
			&:first-child {
				transition: transform $transition-slow ease, opacity $transition-slow ease;
			}
		}

		&:hover .feature__bg {
			transform: none;
			opacity: 1;
			transition: transform $transition-slow ease, opacity $transition-slow ease;

			&:first-child {
				transform: scale(1.1, 1.1);
				opacity: 0.9;
			}
		}

		&:hover .feature__bg[data-object-fit] {
			.ie11 & {
				transform: scale(1.1, 1.1);
				opacity: 0.9;
			}
		}

		.feature__bg {
			width: auto;
			height: auto;
			top: auto;
			bottom: auto;
			left: auto;
			right: auto;
			object-fit: scale-down;
			transition: none;

			&[data-object-fit] {
				.ie11 & {
					transition: transform $transition-slow ease, opacity $transition-slow ease;
				}
			}

			&--cover {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}

			&--two-thirds {
				max-width: 85%;
				height: 100%;

				@media #{$sm-up} {
					max-width: 66%;
				}

				//override ie11 object fit polyfill
				.ie11 & {
					right: 0 !important;
					left: auto !important;
				}
			}

			&--half {
				max-width: 70%;

				@media #{$sm-up} {
					max-width: 50%;
				}
			}

			&--right {
				right: 10%;
			}

			&--clip-right {
				right: -10%;
			}

			&--flush-right {
				right: 0;
			}

			&--clip-bottom {
				bottom: -15%;

				@media #{$sm-down} {
					bottom: -10%;
				}
			}

			&--left {
				left: 10%;
			}

			&--top {
				top: -25%;
			}
		}
	}
}

/*================================================================================
[Feature list]
*/
.feature-list {
	@include list-unstyled;
	margin: 0;

	@media #{$xsl-up} {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__item {
		flex-basis: calc(50% - #{map-get($spacing, tiny)});
		margin: 0 (map-get($spacing, tiny) / 2) (map-get($spacing, tiny));
		flex-grow: 1;
		position: relative;
		z-index: $zindex-lower;

		@media #{$md-up} {
			flex-basis: calc(33.333% - #{map-get($spacing, tiny)});
		}

		/*------- [Overrides of lists of specific lengths] -------*/

		/*------- [4 items in list] -------*/
		&:first-child:nth-last-child(4),
		&:first-child:nth-last-child(4) ~ li {
			@media #{$md-up} {
				/*------- [Prefer 2x2 grid] -------*/
				flex-basis: calc(50% - #{map-get($spacing, tiny)});
			}
		}

		/*------- [5 items in list] -------*/
		&:first-child:nth-last-child(5),
		&:first-child:nth-last-child(5) ~ li {
			@media #{$xsl-to-sm} {
				/*------- [Prefer 2x1x2 grid] -------*/
				&:nth-child(3) {
					flex-basis: 100%;
				}
			}
		}

		/*------- [7 items in list: prefer 2x3x2 grid] -------*/
		&:first-child:nth-last-child(7),
		&:first-child:nth-last-child(7) ~ li {
			@media #{$md-up} {
				&:nth-child(1), &:nth-child(2), &:nth-child(6), &:nth-child(7) {
					flex-basis: calc(50% - #{map-get($spacing, tiny)});
				}
			}
		}
	}

	&--blog {
		.feature__heading {
			@include responsive-type($h3-map);
			.entry-subtitle {
				@include pfont-regular;
				display: block;
				margin-top: map-get($spacing, tiny);
				line-height: 1.3;
			}
		}
	}
}
