.border-between {
	& > * {
		position: relative;

		&:not(:first-child) {
			&:before {
				content: '';
				position: absolute;
				left: -$grid-gutter-width / 3;
				top: 0;
				bottom: 0;
				border-left: 1px solid map-get($brand-colors, gray-light);
			}
		}
	}
}