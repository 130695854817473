@each $key, $direction in $directions {
	.border-#{$direction} {
		border-#{$direction}: 1px solid map-get($brand-colors, gray-light) !important;

		@each $bpkey, $bp in $breakpoints {
			&-#{$bpkey} {
				@media #{$bp} {
					border-#{$direction}: 1px solid map-get($brand-colors, gray-light) !important;
				}
			}
		}
	}
}
