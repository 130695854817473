$z-layer2: 2;
$z-layer1: 1;

/*================================================================================
[Expanding Panel]
*/
.expanding-panel {
    overflow: hidden;
	z-index: 1;

	&__contents {
		background: map-get($brand-colors, gray-light);
		position: absolute;
		width: 100vw;

		@media #{$sm-up} {
			overflow: visible;
			position: relative;
		}
	}

	&__contents-inner {
		max-height: 0;
		background: map-get($brand-colors, gray-light);
		overflow: hidden;
		//transition: max-height $transition-fast ease;

		.ie11 & {
			transition: none;
		}

		@media #{$sm-up} {
			//position: absolute;
			position: relative;
			left: 0;
			right: 0;
			z-index: $z-layer1;
		}

		&.state-active {
			max-height: 42vh;
			z-index: $z-layer2;

			@media #{$sm-up} {
				//border-bottom: 3px solid lighten(map-get($brand-colors, blue-gray), 20%);
				box-shadow: 0 2px 3px rgba(#000, 0.1);
				max-height: 700px;
			}
		}

		@media #{$xs-only}{
			background: map-get($brand-colors, gray-light);

			&.state-active {
				overflow-y: auto;
			}
		}


	}

	&__container {
		padding: map-get($spacing, medium) map-get($spacing, medium);

		@media #{$sm-up} {
			padding: map-get($spacing, medium) 0 0;
		}
		@media #{$md-up} {
			padding: map-get($spacing, large) 0 0;
		}
	}

	&__footer {
		padding: map-get($spacing, small) 0 0;
		text-align: center;
		border-top: 1px solid rgba(#fff, 0.5);
	}

	&__footer-links {
		@media #{$xs-only} {
			font-size: 0.8em;
		}
	}

	&__tab {
		position: relative;

		@media #{$xs-only} {
			background: map-get($brand-colors, gray-lighter);
		}

		&:first-child {
			@media #{$xs-only}{
				.expanding-panel__trigger {
					border-top: 1px solid map-get($brand-colors, gray-light);
				}
			}
		}
	}

	&__tabs {
		@include list-unstyled;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		&--center {
			justify-content: center;
		}

		//mobile version
		@media #{$xs-only}{
			flex-direction: column;

			&.state-visible {
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				width: 100%;
				background: white;
				padding: 0;
				margin: 0;
				top: 100%;
				box-shadow: 0 6px 3px -3px rgba(#0A0A0A, 0.2);
			}
		}
	}

	&__mobile-wrap {
		display: none;

		@media #{$xs-only}{
			.state-filters-open & {
				display: block;
				height: 90vh;
				overflow-y: auto;

				[class^="container"] {
					height: 100%;
				}
			}
		}

		@media #{$sm-up}{
			display: block;
		}
	}

	&__mobile-trigger {
		padding: map-get($spacing, small);
		@media #{$sm-up}{
			display: none;
			padding: 0;
		}
	}

	&__trigger, &__label {
		display: flex;
		align-items: center;
		font-weight: 800;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		font-size: 0.875em;
		position: relative;
		color: $text-color;
		transition: all $transition-med ease;
		cursor: pointer;

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: $text-color;
		}

		&:after {
			font-family: icomoon;
			content: "\e911";
			color: map-get($brand-colors, red-light);
			margin-left: map-get($spacing, tiny);
			transition: all $transition-med ease;
		}

		&.state-selected {
			background: map-get($brand-colors, gray-light);

			&:after {
				content: "\e914";
			}
		}

		@media #{$xs-only} {
			border: 1px solid map-get($brand-colors, gray-light);
			border-top: 0;
			padding: map-get($spacing, small) map-get($spacing, medium);
			color: map-get($brand-colors, gray-med);

			&:after {
				position: absolute;
				right: map-get($spacing, medium);
				top: 50%;
				transform: translateY(-50%);
			}

			&.state-selected {
				color: $text-color;
			}
		}

		@media #{$sm-up} {
			font-size: 0.8em;
			padding: 14px map-get($spacing, small);
		}

		@media #{$md-up} {
			padding: 18px map-get($spacing, medium);
		}

		@media #{$lg-up} {
			font-size: 0.875em;
		}

		&--has-description {
			@media #{$sm-up} {
				padding-top: 8px;
				padding-bottom: 20px;
			}

			@media #{$md-up} {
				padding-top: 12px;
				padding-bottom: 24px;
			}
		}
	}

	&__label {
		pointer-events: none;

		&:after {
			display: none;
			content: '';
		}
	}

	&__tab-desc, &__tab-link {
		display: block;
		position: absolute;
		text-align: center;
		@include pfont-regular;
		font-size: 0.75em;
		right: 50px;
		top: 12px;
		pointer-events: none;
		text-transform: none;

		@media #{$sm-up} {
			left: 0;
			right: 1em;
			top: auto;
			bottom: 0.5em;
		}

		@media #{$md-up} {
			bottom: 0.75em;
		}
	}

	&__tab-link {
		pointer-events: all;

		@media #{$xs-only} {
			position: static;
			clear: both;
			margin-top: map-get($spacing, small);
		}
	}

	@media #{$xs-only} {
		&.state-filters-open {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			background: white;
		}
	}
}
