.image-grid {
	list-style: none;

	@supports (display: grid) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: map-get($spacing, tiny);
		list-style: none;
		margin: 0;
		padding: 0;
		grid-auto-flow: dense;
		border-top: 5px solid white;
		border-bottom: 5px solid white;

		&__item {
			position: relative;
			overflow: hidden;
			background: map-get($brand-colors, gray-light);

			&--tall {
				grid-row: span 2;
			}

			&--wide {
				grid-column: span 2;
			}
		}

		@media #{$sm-up} {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	.ie11 & {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
		border-top: 5px solid white;
		border-bottom: 5px solid white;
		@include clearfix;

		&__item {
			position: relative;
			overflow: hidden;
			background: map-get($brand-colors, gray-light);
			width: 25%;

			&--wide {
				width: 50%;
			}

			&--tall {
				height: 510px;

				@media #{$sm-up} {
					height: 700px;
				}
			}
		}
	}

	/*html.ie11 & {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		flex-wrap: wrap;
		border-top: 5px solid white;
		border-bottom: 5px solid white;

		&__item {
			flex: 0 0 50%;
			overflow: hidden;
			box-sizing: border-box;
			position: relative;
			margin-bottom: map-get($spacing, tiny);
			max-width: 50%;

			&:after {
				content: "";
				width: map-get($spacing, tiny);
				background: white;
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				height: 100%;
				z-index: 10;
			}

			&:first-child {
				&:after {
					display: none;
				}
			}

			@media #{$xs-only}{
				&:nth-child(odd) {
					&:after {
						display: none;
					}
				}
			}

			@media #{$sm-up} {
				flex: 0 0 33.33%;
				max-width: 33.33%;

				&:nth-child(4n + 4) {
					&:after {
						display: none;
					}
				}
			}

			&--tall {
				.image-zoom {
					height: 510px;

					@media #{$sm-up} {
						height: 700px;
					}
				}
			}
		}
	}*/
}
