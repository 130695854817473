/*=========================
PDF HEADER STYLES
==========================*/
.v-spec-pdf-header {
	border-bottom: 1px solid map-get($brand-colors, gray-light);
	margin: 0 map-get($spacing, massive) map-get($spacing, small) map-get($spacing, massive);
	font-size: 0.9em;
	display: flex;
	align-items: center;

	&__cell {
		padding: map-get($spacing, tiny);
		flex: 1 1 auto;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	&__inline-list {
		display: inline-flex;
		align-items: stretch;
		@include list-unstyled;
		margin: 0;
		padding: 0;
	}

	&__inline-list-item {
		padding: 0 map-get($spacing, small);
		border-left: 1px solid map-get($brand-colors, gray-med);
		text-align: center;
		display: flex;
		align-items: center;
		vertical-align: middle;

		&:first-child {
			padding-left: 0;
			border-left:0;
			align-self: flex-start;
		}

		&:last-child {
			padding-right: 0;
		}

		&--room {
			font-weight: bold;
		}
	}
}


/*=========================
PDF INTRO STYLES
==========================*/
.v-spec-pdf-intro {
	&__hero {
		position: relative;
		min-height: 650px;
		overflow:hidden;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			height: 500px;
			display: block;
			z-index: $zindex-lower;
			@include gradient-vertical(rgba(map-get($brand-colors, true-black), 0), rgba(map-get($brand-colors, true-black), 0.8), 0, 100%);
		}
	}
	
	&__hero-no-gradient {
		position: relative;
		min-height: 650px;
		overflow:hidden;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			height: 500px;
			display: block;
			z-index: $zindex-lower;
		}
	}

	&__hero-logo {
		position: absolute;
		display: flex;
		background: map-get($brand-colors, red-dark);
		align-self: center;
		top: 0;
		left: map-get($spacing, massive);
		z-index: $zindex-medium;
		padding: map-get($spacing, medium);
	}

	&__hero-logo-svg {
		width: 110px;
		height: 29px;
		vertical-align: middle;
	}

	&__hero-image {
		object-fit: cover;
		width: 100%;
		/*height: 100%;
		position: absolute;*/
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
	}

	&__hero-content {
		position: absolute;
		bottom: -30;
		left: 0;
		right: 0;
		padding: map-get($spacing, huge) map-get($spacing, massive);
		z-index: $zindex-low;
		color: map-get($brand-colors, true-white);
	}

	&__hero-content-heading {
		@include pfont-bold;
		@include responsive-type($jumbo-h1-map);
		color: map-get($brand-colors, true-white);
		padding-bottom: map-get($spacing, medium);
		margin-bottom:  map-get($spacing, medium);
		position: relative;

		&:after {
			content: "";
			width: 200px;
			height: 5px;
			position: absolute;
			left: 0;
			background: map-get($brand-colors, red-light);
			bottom: 0;
		}
	}
	
	&__hero-content-heading-no-gradient {
		@include pfont-bold;
		@include responsive-type($jumbo-h1-map);
		color: map-get($brand-colors, true-black);
		padding-bottom: map-get($spacing, medium);
		margin-bottom:  map-get($spacing, medium);
		position: relative;

		&:after {
			content: "";
			width: 200px;
			height: 5px;
			position: absolute;
			left: 0;
			background: map-get($brand-colors, red-light);
			bottom: 0;
		}
	}
	
	&__hero-content-heading-validthrough {
		color: map-get($brand-colors, true-white);
	}
	
	&__hero-content-heading-validthrough-no-gradient {
		color: map-get($brand-colors, true-black);
	}


	&__contacts {
		display: flex;
		align-items: flex-start;
		padding: map-get($spacing, huge) map-get($spacing, massive);
		flex-wrap: wrap;
		font-size:20px;
	}

	&__contacts-item {
		flex: 1 1 50%;
		max-width: 50%;
		padding-right: map-get($spacing, large);
	}

	&__contacts-logo {
		width: 100px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: map-get($spacing, small);
	}

	&__contacts-label {
		@include pfont-bold;
		@include responsive-type($h4-map);
		margin-bottom: map-get($spacing, tiny);
	}

	&__contacts-phone {
		margin-top: map-get($spacing, tiny);
	}

	&__contacts-email {
		font-size:0.8em;
		color: map-get($brand-colors, blue);
		overflow-wrap: anywhere;
	}
}



/*=========================
PDF OVERVIEW STYLES
==========================*/
.v-spec-pdf-overview {
	padding:  map-get($spacing, huge) map-get($spacing, massive);

	&__heading {
		@include pfont-bold;
		@include responsive-type($h1-map);
		padding-bottom: map-get($spacing, small);
		margin-bottom:  map-get($spacing, large);
		position: relative;

		&:after {
			content: "";
			width: 175px;
			height: 5px;
			position: absolute;
			left: 0;
			background: map-get($brand-colors, red-light);
			bottom: 0;
		}
	}

	&__section {
		margin-bottom: map-get($spacing, large);
	}

	&__section-wrap {
		padding: map-get($spacing, tiny) 0;
		border-bottom: 1px solid map-get($brand-colors, gray-med);
		display: flex;
		align-items: flex-end;
	}

	&__section-title {
		@include pfont-bold;
		@include responsive-type($h4-map);
		flex: 1 1 auto;
		padding-right: map-get($spacing, medium);
	}

	&__section-count {
		flex: 1 1 100px;
		max-width: 100px;
		margin-left: auto;
	}

	&__section-list {
		@include list-unstyled;
		margin: 0;
		padding: 0;
	}

	&__section-list-item {
		@include list-unstyled;
		margin: 0;
		padding: map-get($spacing, tiny) 0;
		border-bottom: 1px solid map-get($brand-colors, gray-light);
		display: flex;
		align-items: center;
	}

	&__section-list-item-name {
		flex: 1 1 auto;
		padding-right: map-get($spacing, medium);
	}

	&__section-list-item-count {
		flex: 1 1 100px;
		max-width: 100px;
		margin-left: auto;
	}
}


/*=========================
PDF PRODUCTS STYLES
==========================*/
.v-spec-pdf-products {
	&__heading {
		padding: map-get($spacing, medium) map-get($spacing, massive);
		background: map-get($brand-colors, red-dark);
		margin: 0;
		@include pfont-bold;
		@include responsive-type($h1-map);
		color: map-get($brand-colors, true-white);
		line-height: 1;
	}

	&__section-wrap {
		padding: map-get($spacing, medium) map-get($spacing, massive);
	}

	&__subsection {
		margin-bottom: map-get($spacing, medium);
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__list {
		@include list-unstyled;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
	}

	&__list-item {
		flex: 1 1 50%;
		background: map-get($brand-colors, gray-lighter);
		max-width: 50%;
		border: 10px solid map-get($brand-colors, true-white);
		display: flex;
		align-items: center;
		padding: map-get($spacing, medium);

		&:nth-child(odd) {
			border-left: 0;
		}

		&:nth-child(even) {
			border-right: 0;
		}
	}

	&__list-item-col-1 {
		flex: 1 1 40%;
		max-width: 140px;
		min-width: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__list-image {
		width: 100%;
		height: auto;
	}

	&__list-item-col-2 {
		flex: 1 1 60%;
		padding-left: map-get($spacing, medium);
		font-size: 0.9em;

	}

	&__list-collection-name {
		@include pfont-bold;
		@include responsive-type($h4-map);
	}

	&__list-collection-subname {
		@include responsive-type($h4-map);
		@include pfont-regular;
		line-height: 1.2;
		margin-bottom: map-get($spacing, tiny);
	}
}


/*=========================
PDF PRODUCT STYLES
==========================*/
.v-spec-pdf-product {
	&__grade {
		color: map-get($brand-colors, red-dark);
		text-transform: uppercase;
		letter-spacing: 2px;
		@include pfont-bold;
		font-size: 0.8em;
		padding: map-get($spacing, small) 0;
		margin: 0 map-get($spacing, massive) map-get($spacing, small) map-get($spacing, massive);
	}

	&__hero {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 map-get($spacing, massive) 0 map-get($spacing, massive);

		&--has-note {
			padding-right: 250px;
		}
	}

	&__hero-image {
		max-width: 100%;
		max-height: 500px;
	}

	&__note {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 200px;

		&:before {
			content: "";
			display: block;
			width: 120px;
			height: 56px;
			position: absolute;
			right: 100%;
			bottom: 100%;
			background: url(/ResourcePackages/Pfister/assets/dist/img/ui/pdf-arrow.svg) no-repeat left top;
		}

	}

	&__prod-bar {
		background: map-get($brand-colors, gray-lighter);
		display: flex;
		align-items: stretch;
		padding: 0 map-get($spacing, massive);
		margin: map-get($spacing, large) 0;
	}

	&__prod-bar-collection {
		flex: 1 1 auto;
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: map_get($spacing, medium) map-get($spacing, large) map_get($spacing, medium) 0;
	}

	&__prod-bar-collection-name {
		@include responsive-type($h3-map);
		@include pfont-bold;
		line-height: 1.1;
	}

	&__prod-bar-display-name {
		@include responsive-type($h3-map);
		@include pfont-light;
		line-height: 1.1;
	}

	&__prod-bar-additional-name {
		font-size: $font-size-base;
	}

	&__finish-msrp {
		flex: 1 1 auto;
		display: flex;
		width: 100%;
		max-width: 300px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: map_get($spacing, medium) 0;
	}

	&__model {
		@include responsive-type($h4-map);
		@include pfont-bold;
	}

	&__finish {
		font-size: 0.8em;
	}

	&__mrsrp {
		font-size: 0.8em;
	}

	&__quantity-count {
		flex: 1 1 auto;
		display: flex;
		width: 100%;
		max-width: 130px;
		background: map-get($brand-colors, gray-light);
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: map_get($spacing, medium) 0;
	}

	&__quantity {
		font-size: 0.8em;
	}

	&__count {
		@include responsive-type($h3-map);
		@include pfont-bold;
		line-height: 1.1;
	}

	&__diagram {
		border: 2px solid map-get($brand-colors, gray-light);
		margin-bottom: map-get($spacing, huge);
		text-align: center;
	}

	&__diagram-image {
		max-width: 100%;
		height: auto;
		max-height: 700px;
		margin: 0 auto;
		display: inline-block;
	}
}

/*=========================
PDF FOOTER STYLES
==========================*/
.v-spec-pdf-footer {
	border-top: 1px solid map-get($brand-colors, gray-light);
	margin: map-get($spacing, small) map-get($spacing, massive) 0 map-get($spacing, massive);
	font-size: 0.9em;
	display: flex;
	align-items: flex-end;

	&__cell {
		padding: map-get($spacing, tiny);
		flex: 1 1 auto;

		&:first-child {
			flex: 1 1 100px;
			padding-left: 0;
			max-width: 100px;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	&__logo {
		width: 85px;
		height: auto;
	}
}


.v-spec-pdf__content-wrap {
	margin: 0 map-get($spacing, massive);
}
