.search-results {
	@include list-unstyled;
	margin: 0;
	padding: 0;


	&__item {
		padding: map-get($spacing, large) 0;
		border-top: 1px solid map-get($brand-colors, gray-lighter);
		position: relative;
		display: flex;
		align-items: center;

		&:first-child {
			border-top: 0;
		}
	}


	&__icon-col {
		min-width: 55px;
		flex: 0 0 55px;
		margin-right: map-get($spacing, large);
		text-decoration: none;
		color: map-get($brand-colors, blue-gray);
		max-width: 55px;

		&:hover {
			color: map-get($brand-colors, red-light);
			text-decoration: none;
		}
	}

	&__icon {
		font-size: 50px;
		color: map-get($brand-colors, blue-gray);
	}

	&__content-col {
		flex: 1 1 853px;
		max-width: calc(100% - 91px);
		position: relative;
	}

	&__content-type {
		color: map-get($brand-colors, gray-med);
		text-transform: uppercase;
		font-size: 0.8em;
		letter-spacing: 1px;
		@include pfont-bold;
	}

	//collection name is link
	&__title {
		@include pfont-bold;
		@include responsive-type($h3-map);
		display: block;
		text-decoration: none;
		color: map-get($brand-colors, gray-dark);

		&:hover {
			color: map-get($brand-colors, red-light);
			text-decoration: none;
		}

		em {
			font-style: normal;
			background: map-get($brand-colors, tan);
		}
	}

	&__description {
		@include multiLineEllipsis($font-size: 1.3rem, $line-height: 1.3, $lines-to-show: 3, $excerpt-bg: map-get($brand-colors, white));
		//overflow:hidden;
		//text-overflow: ellipsis;
		//position: relative;
		//width: 100%;
		//white-space: nowrap;
	}
}
