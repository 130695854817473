.audio {
	position: relative;
	font-size: 60px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	box-sizing: content-box;
	align-items: center;
	justify-content: center;
	display: flex;
	padding: 0;
	cursor: pointer;
	color: map-get($brand-colors, orange);

	&.icon-audio {
		&:hover {

		}
		&:before {
			transition: all 0.25s ease;
			line-height: 60px;
			z-index: 4;
		}
		&:after {
			content: "";
			left: 8px;
			right: 8px;
			box-sizing: content-box;
			bottom: 8px;
			top: 8px;
			display: block;
			border-radius: 50%;
			position: absolute;
			background: white;
			z-index: 1;
		}

		&.state-playing {
			&:before {
				font-family: icomoon;
				content: "\e92d";
				font-size: 56px;
				line-height: 60px;
			}
		}

		&.state-loading {
			&:before {
				content: '';
				display: block;
				position: absolute;
				font-size: 1.75rem;
				width: 35px;
				height: 35px;
				margin-bottom: 0.33em;
				//transition: opacity 0.75s ease 0.5s;
				animation: load-indicator-rotate 1s linear;
				animation-iteration-count: infinite;
				border-radius: 50%;
				border: 3px solid #fff;
				border-right-color: transparent;
			}

			&:after {
				background: map-get($brand-colors, orange);
			}

			.ie11 & {
				&:before, &:after {
					display: none;
				}
			}
		}
	}

	&__circle {
		position: absolute;
		left: 50.50%;
		top: 48.25%;
		display: block;
		z-index: 20;
		width: 36px;
		height: 36px;
		pointer-events: none;
		transform: translate(-50%, -50%) scale(1.1) rotate(-90deg);
		transform-origin: center; /* 4 */
	}

	&__circle-circle {
		 /* 2, 3 */

	}

	&--large {
		font-size: 80px;
		width: 80px;
		height: 80px;
		line-height: 80px;

		&.icon-audio {
			&:before {
				line-height: 80px;
			}

			&.state-playing {
				&:before {
					font-family: icomoon;
					content: "\e92d";
					font-size: 70px;
				}

				&:after {
					left: 15px;
					top: 15px;
					bottom: 15px;
					right: 15px;
				}
			}
		}

		.audio__circle {
			position: absolute;
			left: 8.75px;
			top: 9px;
			right: 8px;
			bottom: 8px;
			display: block;
			z-index: 20;
			width: 62px;
			height: 62px;
			padding: 2px;
			pointer-events: none;
		}
	}
}
