.image-zoom {
	height: 250px;
	z-index: 2;
	transition: all 0.25s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	position: relative;
	overflow: hidden;
	border: 1px solid map-get($brand-colors, gray-lighter);

	@media #{$sm-up} {
		height: 350px;
	}

	.image-grid__item--tall & {
		height: 100%;
		max-height: 510px;

		@media #{$sm-up}{
			max-height: 710px;
		}
	}

	&:before {
		content: "";
		transition: all 0.25s ease-in-out;
		display: block;
		position: absolute;
		background: rgba(map-get($brand-colors, gray-dark), 0.5);
		opacity: 0;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 3;
	}

	&:hover {
		text-decoration: none;

		&:before {
			opacity: 1;
		}

		.image-zoom__icon {
			opacity: 1;
		}

		.image-zoom__img {
			transform: scale(1.1);
		}

		.image-zoom__title-overlay {
			display: flex;
		}

	}

	/*------- [Modifiers] -------*/

	&--dark {
		border: 1px solid rgba(map-get($brand-colors, gray-med), 0.75);
	}

	&--height-auto {
		height: auto;
	}

	&--with-title {
		&:before {
			opacity: 0.4;
		}
	}

	/*------- [Elements] -------*/


	&__icon {
		font-size: 45px;
		color: white;
		opacity: 0;
		transition: all 0.15s ease-in-out;
		z-index: 4;

		&:not(.icon-with-title) {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__indicator {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 0.25em;
		background: rgba(map-get($brand-colors, black), 0.5);
		text-transform: uppercase;
		color: #fff;
		padding: map-get($spacing, tiny) map-get($spacing, small);
		pointer-events: none;
		z-index: 5;
		visibility: hidden;

		&--hover {
			transition: opacity $transition-fast ease;

			html.no-coarsepointer & {
				opacity: 0;
				visibility: visible;
			}
		}

		html.coarsepointer & {
			&.image-zoom__indicator--hover {
				left: auto;
				right: 0;
				top: initial;
				bottom: 0;
				transform: none;
				font-size: 0.7em;
				padding:0;
				visibility: visible;
				background: transparent;
				color: map-get($brand-colors, gray-dark);

				@media #{$sm-up}{
					bottom: 90px;
				}
			}
		}

		&--small {
			@media #{$xs-only}{
				display: none;
			}

			@media #{$sm-up}{
				font-size: 0.6em;
			}

		}
	}

	&__title-overlay {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		height: 100%;
		width: 100%;
		max-width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		padding: 0 map-get($spacing, small);
	}

	&__title-overlay-text {
		@include pfont-bold;
		color: white;
		text-decoration: none;
		position: absolute;
		z-index: 4;
		right: 0;
		bottom: 0;
		left: 0;
		text-align: right;
		display: flex;
		max-width: 100%;
		justify-content: flex-end;
		padding: map-get($spacing, tiny);
	}

	html.no-coarsepointer *:hover > &__indicator {
		opacity: 1;
		visibility: visible;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-position: center center;
		transform: scale(1);
		transition: all 0.75s ease;

		&--cover {
			object-fit: cover;
		}

		&--contain {
			object-fit: contain;
		}
	}
}
