.accordion-list {
	@include list-unstyled;
	margin: 0;
	padding: 0;

	&__item {
		border: 1px solid map-get($brand-colors, gray-light);
		margin-bottom: map-get($spacing, small);
		background: map-get($brand-colors, gray-lightest);
	}

	&__link {
		background: white;
		padding: map-get($spacing, small) map-get($spacing, small) map-get($spacing, small) 42px;
		color: map-get($brand-colors, gray-dark);
		text-decoration: none;
		display: flex;
		font-size: 1.2em;
		transition: 0.25s ease;
		position: relative;


		&:hover {
			text-decoration: none;
			color: map-get($brand-colors, gray-dark);
		}

		&:before {
			content: "\e915";
			font-family: icomoon;
			flex: 0 0 auto;
			display: inline-block;
			margin-right: map-get($spacing, small);
			transition: all 0.35s ease;
			transform-origin: center;
			width: 28px;
			height: 28px;
			line-height: 28px;
			text-align: center;
			position: absolute;
			left: 10px;

			.state-open & {
				content: "\e92e";
				font-family: icomoon;
				color: map-get($brand-colors, red-light);
			}
		}
	}

	&__content {
		transition: max-height $transition-med cubic-bezier(0, 1, 0, 1);
		max-height: 0;
		box-sizing: border-box;
		overflow: hidden;

		.state-open & {
			transition: max-height $transition-med ease-in-out;
			max-height: 1000px;
			overflow: auto;
		}
	}

	&__content-inner {
		padding: map-get($spacing, medium);
		line-height: 1.5;

		.state-open & {
			box-shadow: inset 0px 11px 8px -10px #CCC,
		}

		p {
			line-height: 1.5;
		}
	}

	&--faqs {
		.accordion-list__link {
			padding: map-get($spacing, small) map-get($spacing, small) map-get($spacing, small) 72px;

			&:after {
				content: "Q:";
				display: inline-block;
				position: absolute;
				left: 42px;
				top: 11px;
				font-size: 1em;
				@include pfont-bold;
			}
		}

		.accordion-list__content-inner {
			position: relative;
			padding: map-get($spacing, medium) map-get($spacing, medium) map-get($spacing, medium) 73px;
			&:before {
				content: "A:";
				display: inline-block;
				position: absolute;
				left: 42px;
				top: 20px;
				font-size: 1.2em;
				@include pfont-bold;
			}
		}
	}

	&--light {
		.accordion-list__link {
			background: map-get($brand-colors, gray-light);
		}
		&.accordion-list--faqs {
			.accordion-list__link {
				background: map-get($brand-colors, gray-light);
			}
		}
	}
}
