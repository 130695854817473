@mixin section-nav-fade($fade-color: #fff) {
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		display: block;
		height: 100%;
		width: 70px;
		@include gradient-horizontal(rgba($fade-color, 1), rgba($fade-color, 0), 70%, 100%);
		z-index: 1;
	}

	&:after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		display: block;
		height: 100%;
		width: 70px;
		@include gradient-horizontal(rgba($fade-color, 0), rgba($fade-color, 1), 0%, 30%);
		z-index: 1;
	}
}

/*================================================================================
[Section navigation]
*/
.section-nav {
	font-size: 0.8em;
	position: relative;

	&:not(.section-nav--absolute) {
		background: #fff;
		box-shadow: 0 2px 8px rgba(#000, 0.1);
		overflow-x: hidden;
		display: flex;
		@include section-nav-fade;
	}

	@media print {
		display: none !important;
	}

	/*------- [Elements] -------*/

	&__body {
		display: flex;
		position: relative;
	}

	//used in the &--absolute version
	&__title {
		color: white;
		@include responsive-type($h6-map);
		@include pfont-bold;
		text-align: center;
		margin: 0 auto 0 auto;

		@media #{$sm-up}{
			@include responsive-type($h5-map);
		}

		.section-nav--white & {
			display: none;
		}
	}

	&__wrapper {
		flex-grow: 1;
		justify-content: center;
		overflow: hidden;
		position: relative;

		.section-nav:not(.section-nav--absolute) & {
			max-width: calc(100vw - 90px);
		}
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		font-weight: bold;
		line-height: 1.1;
		display: flex;
		text-transform: uppercase;
		opacity: 0;
		transition: opacity $transition-fast ease;

		&.state-visible {
			opacity: 1;
		}
	}

	&__item {
		white-space: nowrap;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		flex: 0 0 auto;

		&.state-active {
			.section-nav:not(.section-nav--absolute) & {
				&:after {
					display: block;
					position: absolute;
					content: '';
					height: 0;
					bottom: 0;
					left: map-get($spacing, medium);
					right: map-get($spacing, medium);
					border-top: 4px solid map-get($brand-colors, red-light);
				}
			}
		}
	}

	&__trigger {
		display: flex;
		width: 100%;
		align-self: stretch;
		align-items: center;
		justify-content: center;
		padding: map-get($spacing, medium);
		color: map-get($brand-colors, gray-dark);
		transition: background-color $transition-med ease, color $transition-med ease;

		@media #{$md-up} {
			padding: map-get($spacing, medium);
		}

		&:hover, .state-active & {
			color: map-get($brand-colors, red-light);
			background: rgba(#fff, 0.75);
			text-decoration: none;
		}
	}

	&__btn {
		//position: absolute;
		//height: 100%;
		//top: 0;
		width: 45px;
		background: transparent;
		border: none;
		font-size: 2em;
		transition: opacity $transition-fast ease;
		z-index: 2;
		opacity: 0;
		pointer-events: none;

		&--left {
			//left: 0;
			//background: linear-gradient(to right, #fff, rgba(#fff, 0));
		}

		&--right {
			//right: 0;
			//background: linear-gradient(to right, rgba(#fff, 0), #fff);
		}

		&.state-visible {
			opacity: 1;
			pointer-events: all;
		}

		&:focus {
			border: 0;
			outline: 0;
		}
	}

	&__header {
		padding: map-get($spacing, small) map-get($spacing, medium);
		border-bottom: 1px solid $hr-border;
		background: map-get($brand-colors, gray-lighter);
		display: none;

		.state-stuck & {
			display: block;
		}
	}

	/*------- [Modifiers] -------*/
	&--has-header {
		flex-direction: column;

		&:not(.section-nav--absolute) {
			&:before, &:after { display: none; }
		}

		.section-nav__body {
			@include section-nav-fade;
		}
	}

	&--centered {
		.section-nav__list {
			justify-content: center;
		}
	}

	&--shaded {
		background: map-get($brand-colors, gray-lighter);
		box-shadow: none;
		@include section-nav-fade(map-get($brand-colors, gray-lighter));
	}

	&--sticky {
		position: sticky;
		top: -1px;
		z-index: $zindex-low;
	}

	//gets applied when nav becomes sticky
	&--white {
		background: map-get($brand-colors, true-white);
		//box-shadow: 0 8px 5px -5px rgba(55,55,55,0.4);
		box-shadow: 0 2px 8px rgba(#000, 0.1);
		padding: map-get($spacing, small) 0 0 0;

		&.section-nav--absolute {
			.section-nav__item {
				&:after {
					content: "";
					background: map-get($brand-colors, true-white);
					position: absolute;
					bottom: 0;
					left: 50%;
					display: block;
					width: calc(100% - 50px);
					margin: 0 auto;
					height: 4px;
					transform: translateX(-50%);

					@media #{$sm-up}{
						width: calc(100% - 120px);
					}
				}
			}
			.section-nav__trigger {
				color: $text-color;
				@include pfont-regular;
				padding: map-get($spacing, small) map-get($spacing, medium) map-get($spacing, medium) map-get($spacing, medium);

				&:hover {
					color: $text-color;
				}
			}

			.state-active {
				&.section-nav__item {
					&:after {
						background: map-get($brand-colors, red-light);
					}
				}
				.section-nav__trigger {
					color: $text-color;

					&:hover {
						color: $text-color;
					}
				}
			}
		}

	}

	&--absolute {
		margin-top: -84px;

		@media #{$sm-up}{
			margin-top: -96px;
		}

		.section-nav__trigger {
			color: map-get($brand-colors, white);
			font-size: $font-size-h5;
			@include pfont-light;
			margin: 0 0;
			transition: all $transition-med ease;
			padding: map-get($spacing, small) map-get($spacing, medium) map-get($spacing, large) map-get($spacing, medium);

			@media #{$sm-up}{
				margin: 0 map-get($spacing, large);
			}

			&:hover {
				 color: map-get($brand-colors, white);
				 background: transparent;
				 text-decoration: none;
				 //transform: translateY(-5px);
			 }

			&.state-animated {
				animation: pulsate-fwd 6s ease-in-out infinite both;
				animation-delay: 6s;

				&:hover {
					animation: initial;
				}
			}

			@media #{$sm-up}{
				font-size: $font-size-h3;
			}
		}

		.state-active {
			.section-nav__trigger {
				color: map-get($brand-colors, white);
				background: transparent;
				text-decoration: none;
			}
		}

		.section-nav__item {
			&:after {
				content: "\e911";
				font-family: icomoon;
				display: inline-block;
				color: map-get($brand-colors, red-light);
				bottom: map-get($spacing, small);
				position: absolute;
				left: 50%;
				font-size: $font-size-h3;
				transform: translateX(-50%);
				z-index: $zindex-high;
			}
		}
	}
}


@-webkit-keyframes pulsate-fwd {
	0% {
		transform: scale(1);
	}
	5% {
		transform: scale(1.1);
	}
	10% {
		transform: scale(1);
	}
	15% {
		transform: scale(1.1);
	}
	20% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.1);
	}
	30% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes pulsate-fwd {
	0% {
		transform: scale(1);
	}
	5% {
		transform: scale(1.1);
	}
	10% {
		transform: scale(1);
	}
	15% {
		transform: scale(1.1);
	}
	20% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.1);
	}
	30% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}

/*================================================================================
[Navigation sections]
*/

section[data-section], section[data-sf-element="Section Nav"] {
	@media print {
		page-break-inside: avoid;
		background: transparent;
	}

	&.allow-break-inside {
		@media print {
			page-break-inside: auto !important;
		}
	}
}
