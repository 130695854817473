.v-about {
	position: relative;
	height: 100vh;
	min-height: 450px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	max-width: 100%;

	@media #{$sm-up}{
		height: 100vh;
		min-height: 650px;
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: $zindex-lower;
		height: 300px;
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		@include gradient-vertical(rgba(map-get($brand-colors, true-white), 0), rgba(map-get($brand-colors, true-white), 1), 0, 100%);
	}

	&__container {
		max-width: $container-lg;
		position: relative;
		z-index: $zindex-low;
	}

	&__content {
		@include make-xs-column(12);
		@include make-sm-column(6);
		@include make-sm-column-offset(1);
		margin: map-get($spacing, massive) 0;
	}

	&__img {
		position: absolute;
		transition: opacity $transition-med ease;

		img {
			max-width: 100%;
		}
	}
	&__img-1 {
		left: 50%;
		top: 50%;

		@media #{$xs-only}{
			transform: translate(-50%, -50%) !important;
			opacity: 0.3;
		}

		@media #{$sm-up}{
			left: 68%;
			top: 20%;
			max-width: 170px;
		}

		@media #{$md-up}{
			left: 68%;
			top: 20%;
			max-width: none;
		}
	}
	&__img-2 {
		display: none;

		@media #{$md-up}{
			display: block;
			left: 45%;
			top: 2%;
		}

		@media #{$lg-up}{
			left: 51%;
			top: 5%;
		}

	}
	&__img-3 {
		display: none;

		@media #{$md-up}{
			display: block;
			left: 92%;
			top: 14%;
		}

		@media #{$lg-up}{
			left: 87%;
			top: 14%;
		}
	}
	&__img-4 {
		display: none;

		@media #{$md-up}{
			display: block;
			left:14%;
			top:7%;
			opacity: 0.8
		}

		@media #{$lg-up}{
			left:14%;
			top:19%;
			opacity: 1;
		}
	}
	&__img-5 {
		display: none;

		@media #{$md-up}{
			display: block;
			left: -4%;
			top:50%;
			opacity: 0.8;
		}

		@media #{$lg-up}{
			left: 2%;
			top:50%;
			opacity: 1;
		}
	}
}

.v-about-water-conservation {
	&__bg {
		background: url("//images.pfisterfaucets.com/is/image/Pfister/handwashing?$pfFullWidthCoverImage$") no-repeat center center;
		background-size: cover;
	}
}
