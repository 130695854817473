.upc-list {
	@include list-unstyled;
	margin: 0;
	padding: 0;
	font-size: 0.9em;

	&__label {
		border-top: 2px solid map-get($brand-colors, gray-light);
		border-bottom: 2px solid map-get($brand-colors, gray-light);
		display: flex;
		align-items: center;
		flex-wrap: no-wrap;
	}

	&__label-el {
		flex: 1 1 33.33%;
		max-width: 33.33%;
		color: map-get($brand-colors, blue-gray);
		padding: map-get($spacing, tiny) map-get($spacing, tiny) map-get($spacing, tiny) 0;
		@include pfont-bold;
		line-height: 1.2;

		&:last-child {
			padding-right: 0;
		}
	}

	&__item {
		border-bottom: 2px solid map-get($brand-colors, gray-light);
		display: flex;
		align-items: center;
		flex-wrap: no-wrap;

		&:last-child {
			border-bottom: 0;
		}
	}

	&__link {
		@include pfont-bold;
		color: $text-color;
		text-decoration: underline;
	}

	&__item-el {
		flex: 1 1 33.33%;
		max-width: 33.33%;
		line-height: 1.2;
		padding: map-get($spacing, tiny) map-get($spacing, tiny) map-get($spacing, tiny) 0;

		&:last-child {
			padding-right: 0;
		}

	}
}
