.search-results-product {
	@include list-unstyled;
	margin: 0;
	padding: 0;


	&__item {
		padding: map-get($spacing, large) 0;
		border-top: 1px solid map-get($brand-colors, gray-lighter);

		@media #{$sm-up}{
			display: flex;
			align-items: center;
		}

		&:first-child {
			border-top: 0;
		}
	}

	&__image-col {
		flex: 0 0 auto;
		margin-right: map-get($spacing, large);
		display: block;
	}

	&__image {
		max-width: 170px;
		max-height: 170px;
		width: 100%;
		margin-bottom: map-get($spacing, medium);

		@media #{$sm-up}{
			max-width: 140px;
			max-height: 140px;
			margin-bottom: 0;
		}

		@media #{$md-up}{
			max-width: 170px;
			max-height: 170px;
		}
	}

	&__content-col {
		flex: 1 1 auto;
		padding-right: map-get($spacing, medium);
	}

	&__cta-col {
		flex: 0 0 auto;
		margin-top: map-get($spacing, small);

		@media #{$sm-up}{
			margin-top: 0;
		}
	}

	&__heading {

	}

	//collection name is link
	&__collection {
		@include pfont-bold;
		@include responsive-type($h3-map);
		display: inline-block;
		text-decoration: none;
		color: map-get($brand-colors, gray-dark);

		&:hover {
			color: map-get($brand-colors, red-light);
			text-decoration: none;
		}
	}

	&__label {
		padding: 2px map-get($spacing, tiny);
		color: map-get($brand-colors, true-white);
		text-transform: uppercase;
		font-size: 0.7em;
		@include pfont-bold;
		position: relative;
		top: -2px;
		display: none;

		&--discontinued {
			background: map-get($brand-colors, gray-cool);
			display: inline-block;
		}
	}

	&__description {

	}

	&__meta {

	}

	&--download-images {
		.search-results-product__image-col {
			flex: 1 1 75px;
			max-width: 75px;
			min-width: 75px;
		}

		.search-results-product__item {
			@media #{$sm-up}{
				.list-menu-inline--search-results > li {
					font-size: 0.6em;
				}
			}
		}
	}
}
