.compare-tray {
	box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.05);
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	width: 100%;
	background: white;
	z-index: $zindex-navbar-fixed;
	padding: map-get($spacing, small) 0;
	border-bottom: 1px solid map-get($brand-colors, gray-lighter);

	@media #{$sm-up}{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__items {
		flex: 0 0 auto;
		@include list-unstyled;
		margin: -12px 0 0 0;
		padding: 0;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		@media #{$sm-up}{
			justify-content: flex-end;
		}
	}

	&__item {
		flex: 0 0 auto;
		background: map-get($brand-colors, gray-lighter);
		padding: map-get($spacing, small) map-get($spacing, small);
		position: relative;
		margin: map-get($spacing, small) map-get($spacing, micro) 0;
		cursor: pointer;

		@media #{$sm-up} {
			margin: map-get($spacing, small) map-get($spacing, tiny) 0;
		}

		&:before {
			position: absolute;
			right: map-get($spacing, tiny);
			top: map-get($spacing, tiny);
			font-family: icomoon;
			content: "\e90e";
			display: inline-block;
			font-size: 0.7em;
			color: map-get($brand-colors, red-light);
		}
	}

	&__img {
		display: block;
		max-height: 40px;
		max-width: 40px;
		margin: 0 auto;

		@media #{$sm-up}{
			max-height: 70px;
			max-width: 70px;
		}
	}

	&__options {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: map-get($spacing, medium);

		@media #{$xs-only}{
			margin-top: map-get($spacing, small);
		}

		@media #{$sm-up}{
			justify-content: flex-start;
		}
	}

	&__options-clear {
		color: map-get($brand-colors, gray-dark);
		text-decoration: none;
		margin: 0 map-get($spacing, medium);
		@include pfont-bold;
		font-size: 0.8em;

		&:hover {
			text-decoration: none;
		}
	}

	&__options-icon {
		color: map-get($brand-colors, red-light);
		@include pfont-regular;
		display: inline-block;
		margin-right: map-get($spacing, tiny);
	}
}
