/*================================================================================
[Full-width sections]
*/

:root {
	--scrollbar-width: 0px; // This value is updated by JavaScript in main.ts
}

.full-width {
	clear: both;
	position: relative;
	margin-left: calc(-50vw + 50%); // Safe-ish scrollbar width for IE11
	margin-right: calc(-50vw + 50%); // Safe-ish scrollbar width for IE11

	@supports (--css: variables) {
		margin-left: calc((-50vw + 50% + (var(--scrollbar-width) / 2)));
		margin-right: calc((-50vw + 50% + (var(--scrollbar-width) / 2)));
	}

	/* Restoring SiteFinity value */
  	.sf-backend-wrp & {
		margin: 0 !important;
	}
}
