.v-technology {
	//background: map-get($brand-colors, teal);

	&__gif {
		position: relative;

		&:before, &:after {
			content: "";
			width: 100%;
			left: 0;
			right: 0;
			height: 10px;
			display: block;
			background: map-get($brand-colors, legacy-teal);
			position: absolute;
			z-index:5;
		}

		&:before {
			top: calc(100% - 5px);
		}

		&:after {
			bottom: calc(100% - 5px);
		}
	}

	&__gif-image {
		width: 100%;
		height: auto;
	}

	&__install-guide {
		max-height: 550px;
		position: relative;
		display: block;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	&__flex-list {
		display: flex;
		align-items: center;
		justify-content: center;
		@include list-unstyled;
		max-width: 100%;
		flex-direction: column;
		margin-top: map-get($spacing, large);
		position: relative;

		@media #{$lg-up}{
			flex-direction: row;
			justify-content: flex-start;
		}
	}

	&__flex-list-item {
		flex: 1 1 auto;
		padding: 0 map-get($spacing, small);
		text-align: center;
		align-self: stretch;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: flex-start;

		&:first-child {
			justify-content: center;
			margin-bottom: map-get($spacing, medium);
		}

		@media #{$lg-up}{
			flex: 1 1 calc((100% - 184px) / 3);
			max-width: 116px;
			flex-wrap: wrap;
			align-self: stretch;
			justify-content: center;
			align-items: center;

			&:first-child {
				flex: 1 1 184px;
				max-width: none;
				margin-bottom: 0;
				border-right: 2px solid map-get($brand-colors, gray-light);
			}

		}

		img {
			width: 100%;
			height: auto;
			min-width: 140px;
			max-width: 160px;
		}

		i, strong {
			display: block;
			line-height: 1;
			margin-bottom: 5px;
			min-width: 45px;
			@include responsive-type($h2-map);

			@media #{$lg-up}{
				flex: 0 0 auto;
			}
		}
	}

	&__actions-link {
		color: map-get($brand-colors, gray-dark);
		text-decoration: underline
	}

	&__five-up-list {

		&.flex--5-up {
			.flex-item {
				@media #{$xs-only}{
					padding: map-get($spacing, large) 0;
					border-bottom: 1px solid map-get($brand-colors, gray-light);

					&:last-child {
						border-bottom: none;
					}
				}
			}
		}

	}

	&__five-up-list-img {
		max-width: 100%;
		height: auto;
		margin: 0 auto;
		display: block;
	}

	&--text-reversed {
		color: white;

		.list-large-icon__text {
			color: white;
		}
	}


	//xtract page ge logo override
	&__ge-logo {
		.content-and-media__feature-icon {
			img {
				margin-left: -16px;
			}
		}
	}


	//spot defense page parrallax
	&__force-field-iframe-container {
		position: relative;
		padding-bottom: map-get($spacing, large);
	}

	&__force-field-with-sd {
		position: absolute;
		right: calc(50% - 55px);
		top:-3%;
		display: block;
		z-index: 2;
		transform: scale(0.6);

		@media #{$sm-up}{
			right: calc(50% + 82px);
			top:15%;
			transform: scale(1);
		}
	}

	&__force-field-without-sd {
		left: calc(37% + 5px);
		bottom: 3%;
		position: absolute;
		display: block;
		z-index: 2;
		transform: scale(0.6);

		@media #{$sm-up}{
			left: calc(50% + 82px);
			bottom: 15%;
			transform: scale(1);
		}
	}

	&__force-field {
		position: relative;
		overflow: hidden;
	}

	&__force-field-hand {
		position: absolute;
		left: 0;
		bottom: 0px;
		display: block;
		width: 50vw;
		height: auto;
		z-index:1;

		@media #{$sm-up}{
			width: 30vw;
		}
	}

	&__force-field-splash {
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		width: 50vw;
		height: auto;
		z-index:1;

		@media #{$sm-up}{
			width: 40vw;
		}
	}

	&__force-field-with-without {
		display: block;
		margin: -(map-get($spacing, large)) auto map-get($spacing, large) auto;
		height: auto;
		width: 80%;
	}

	&__force-field-content {
		position: relative;
		z-index:2;
	}
}

