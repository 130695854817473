.support-category-grid {
	@include list-unstyled;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 0;
	opacity: 0;
	position: absolute;
	left: 0;
	visibility:hidden;
	width: 100%;
	transition: all $transition-med ease;

	&.state-visible {
		visibility: visible;
		opacity: 1;
		left: 0;
		transition: all $transition-med ease;
	}

	&__back-button {
		color: map-get($brand-colors, red-light);
		font-size: 0.9em;
		display: flex;
		align-items: center;
		flex: 1 1 auto;
		transition: color $transition-fast ease;
		display: none;
		@include pfont-bold;

		&:hover {
			text-decoration: none;
			color: map-get($brand-colors, red-dark);
		}

		&:before {
			display: inline-block;
			flex: 0 0 auto;
			font-family: icomoon;
			content: "\e926";
			font-size: 0.7em;
			margin-right: map-get($spacing, tiny);
		}

		&.state-visible {
			display: block;
		}
	}

	&__container {
		overflow:hidden;
		position: relative;
		transition: all $transition-med ease;
		min-height: 135px;

		.sub-nav__panel & {
			.support-category-grid__item {
				flex: 0 0 calc(50% - 4px);
				margin: 2px;
				background: map-get($brand-colors, white);
				min-height: 100px;
				max-width: calc(50% - 4px);

				@media #{$sm-up}{
					flex: 0 0 calc(33.33% - 4px);
					max-width: calc(33.33% - 4px);
				}
			}
		}
	}

	&__item {
		min-width: 100px;
		flex: 1 0 100px;
		max-width: 33%;
		margin: 2px;
		background: map-get($brand-colors, white);
		min-height: 100px;

		@media #{$sm-up} {
			flex: 1 0 200px;
			max-width: 25%;
			flex-grow: 1;
			flex-shrink: 0;
		}
	}

	&__img {
		position: relative;
		img {
			max-width: 100%;
			height: auto;
			max-height: 80px;
			margin-bottom: map-get($spacing, tiny);
		}
	}

	&__text {
		text-align: center;
		max-width: 100%;
		padding: 0 map-get($spacing, tiny);
	}

	&__link {
		width: 100%;
		height: 100%;
		background: white;
		border: 1px solid map-get($brand-colors, gray-light);
		transition: all $transition-med ease;
		color: map-get($brand-colors, gray-dark);
		text-decoration: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: map-get($spacing, small);
		text-align: center;
		@include pfont-bold;
		font-size: 0.7em;
		line-height: 1.1;
		position: relative;
		max-width: 100%;

		&.state-has-children {
			&:after {
				content: "\e93b";
				font-family: icomoon;
				font-size: 1.4em;
				position: absolute;
				display: inline-block;
				right: 2px;
				top: 2px;
				color: map-get($brand-colors, gray-med);
			}
		}

		@media #{$sm-up}{
			font-size: 0.9em;

		}

		&:hover {
			border-color: map-get($brand-colors, red-light);
			text-decoration: none;
			color: map-get($brand-colors, red-light);
		}
	}



	&--replacement-parts-landing {
		@include list-unstyled;
		display: none;
		flex-wrap: wrap;
		margin: 0 auto;
		padding: 0;
		opacity: 1;
		position: relative;
		left: initial;
		visibility:visible;
		width: 100%;
		transition: all $transition-med ease;
		justify-content: flex-start;

		.support-category-grid__item {
			flex: 1 0 33%;
			max-width: 33%;
			min-width: 33%;
			margin: 2px;
			background: map-get($brand-colors, white);
			min-height: 100px;

			@media #{$sm-up} {
				flex: 1 0 calc(20% - 4px);
				min-width: calc(20% - 4px);
				max-width: calc(20% - 4px);
				flex-grow: 1;
				flex-shrink: 0;
			}
		}

		@media #{$sm-up}{
			display: flex;
		}
	}

	&__replacement-parts-wrap {
		@media #{$sm-up}{
			display: flex;
		}
	}
}
