.load-more {
	position: relative;
	//transition: all 1s cubic-bezier(0.600, -0.280, 0.735, 0.045); // ease-in-back
	text-align: center;

	&__btn {
		//border-radius: 22px;
		padding: 12px 22px;
		width: 150px;
		height: 46px;
		//transition: all 0.5s cubic-bezier(0.600, -0.280, 0.735, 0.045); // ease-in-back
		text-align: center;
		position: relative;

		&, &:hover, &:active, &:focus {
			outline: none;
			border: transparent;
		}
	}

	&__text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: all $transition-fast ease;
	}

	&__indicator {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-53%, -53%);
		width: 34px;
		height: 34px;
		opacity: 0;
		//transition: opacity 0.75s ease 0.5s;
		animation: load-more-rotate 1.5s linear;
		animation-iteration-count: infinite;
		border-radius: 50%;
		border: 3px solid #fff;
		border-right-color: transparent;
	}

	&.state-loading {
		.load-more__btn {
			display: inline-block;
			border-radius: 50%;
			width: 0;
			overflow: hidden;
			text-align: center;
		}

		.load-more__text {
			opacity: 0;
			transition: all 1s ease;
		}

		.load-more__indicator {
			opacity: 1;
		}
	}

}

@keyframes load-more-rotate {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}