/*================================================================================
[Product Info Bar: Used on product detail pages]
*/
.product-info-bar {
	display: flex;
	align-items: center;
	line-height: 1.33;

	/*------- [Modifiers] -------*/
	&--mini {
		font-size: 0.85em;
	}

	/*------- [Elements] -------*/

	&__main {
		display: flex;
		align-items: center;
	}

	&__name {
		font-size: 1.1em;
	}

	&__details {
		font-size: 0.9em;
		margin-top: map-get($spacing, micro);
		margin-bottom: 0;
	}

	&__img {
		max-height: 60px;
		margin-right: map-get($spacing, tiny);

		&--lg {
			max-height: 80px;
			margin-right: map-get($spacing, small);
		}
	}

	&__back-to-top {
		margin-left: auto;
		margin-right: map-get($spacing, small);
		padding-left: map-get($spacing, tiny);
		text-transform: uppercase;
		@include pfont-bold;
		text-align: center;
		line-height: 1.25;

		&, &:hover, &:active, &:focus {
			text-decoration: none;
		}

		[class^=icon] {
			display: block;
			margin-bottom: map-get($spacing, micro);
		}
	}
}