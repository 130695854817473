@mixin montserrat-regular {
	font-family: 'montserrat';
	font-weight: normal;
	font-style: normal;
}

@mixin montserrat-light {
	font-family: 'montserrat';
	font-weight: 200;
	font-style: normal;
}

@mixin montserrat-bold {
	font-family: 'montserrat';
	font-weight: bold;
	font-style: normal;
}

@mixin pfont-regular {
	font-family: 'Pfont';
	font-weight: normal;
	font-style: normal;
}

@mixin pfont-italic {
	font-family: 'Pfont';
	font-weight: normal;
	font-style: italic;
}

@mixin pfont-light {
	font-family: 'Pfont';
	font-weight: 200;
	font-style: normal;
}

@mixin pfont-light-italic {
	font-family: 'Pfont';
	font-weight: 200;
	font-style: italic;
}

@mixin pfont-bold {
	font-family: 'Pfont';
	font-weight: bold;
	font-style: normal;
}

@mixin pfont-bold-italic {
	font-family: 'Pfont';
	font-weight: bold;
	font-style: italic;
}

@mixin claire_handlight {
	font-family: '../fonts/claire_handlight';
	font-weight: normal;
	font-style: normal;
}

@mixin claire_handregular {
	font-family: 'claire_handregular';
	font-weight: normal;
	font-style: normal;
}

@mixin claire_handbold {
	font-family: 'claire_handbold';
	font-weight: normal;
	font-style: normal;
}

$font-faces: (
		'pfont-regular': 'pfont-regular',
		'pfont-italic': 'pfont-italic',
		'pfont-light': 'pfont-light',
		'pfont-light-italic': 'pfont-light-italic',
		'pfont-bold': 'pfont-bold',
		'pfont-bold-italic': 'pfont-bold-italic',
		'claire_handlight': 'claire_handlight',
		'claire_handregular': 'claire_handregular',
		'claire_handbold': 'claire_handbold'
);

.text-pfont-regular { @include pfont-regular; }
.text-pfont-italic { @include pfont-italic; }
.text-pfont-light { @include pfont-light; }
.text-pfont-light-italic { @include pfont-light-italic; }
.text-pfont-bold { @include pfont-bold; }
.text-pfont-bold-italic { @include pfont-bold-italic; }
.text-claire_handlight { @include claire_handlight; }
.text-claire_handregular { @include claire_handregular; }
.text-claire_handbold { @include claire_handbold; }
