.sortable-list {

	&__list {
		@include list-unstyled;
		margin: 0;
		padding: 0;
	}

	&__item {
		border: 1px solid map-get($brand-colors, gray-light);
		padding: map-get($spacing, small);

		&:not(:first-child) {
			border-top: none;
		}
	}

	&__parent-wrapper {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		background: map-get($brand-colors, true-white);
		padding: map-get($spacing, tiny) 0;
		//border-top: 1px solid map-get($brand-colors, gray-light);
		position: relative;
		margin-left: map-get($spacing, tiny) * -1;

		&:last-child {
			border-bottom: 1px solid map-get($brand-colors, gray-light);
		}
	}

	&__parent-title {
		flex: 1 1 100%;
		max-width: 100%;
		display: flex;
		align-items: center;
		cursor: grab;
		position: relative;

		&:before {
			content: "\e95a";
			flex: 0 0 0;
			font-family: icomoon;
			padding: 0 map-get($spacing, tiny);
			color: lighten(map-get($brand-colors, gray-med), 3%);
			font-size: $font-size-small;
		}
	}

	&__parent-link {
		flex: 0 0 0;
		padding: 0 map-get($spacing, tiny);
		display: flex;
		align-items: center;
		text-decoration: none;
		color: $text-color;
		font-size: $font-size-small;

		.ie11 & {
			flex-basis: 0.75em;
		}

		&:hover {
			color: map-get($brand-colors, red-light);
			text-decoration: none;
		}

		.icon-delete1 {
			font-size: $font-size-base;
		}
	}

	&__child-outer-wrapper {
		background: map-get($brand-colors, gray-light);
		padding: map-get($spacing, small);
		margin-left: map-get($spacing, small);
	}

	&__list-child {
		@include list-unstyled;
		margin: 0;
		padding: 0;
	}

	&__list-child-item {
		margin-bottom: map-get($spacing, tiny);
	}

	&__child-wrapper {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		background: map-get($brand-colors, gray-lighter);
		padding: map-get($spacing, tiny) 0;
		transition: all $transition-med ease;

		&:hover {
			background: lighten(map-get($brand-colors, gray-lighter), 1%);
		}
	}

	&__child-title {
		flex: 1 1 100%;
		max-width: 100%;
		display: flex;
		align-items: center;
		cursor: grab;

		&:before {
			content: "\e95a";
			flex: 0 0 0;
			font-family: icomoon;
			padding: 0 map-get($spacing, tiny);
			color: lighten(map-get($brand-colors, gray-med), 3%);
			font-size: $font-size-small;
		}
	}

	&__input {
		background: transparent;
		border: 0;
		margin: 0;
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;
		border-radius: 0;
		padding: 0;
		margin-left: map-get($spacing, large) * -1;
		padding-left: map-get($spacing, large);

		.ie11 & {
			margin-left: 0;
			padding-left: map-get($spacing, small);
		}

		&.form-control:focus {
			border-bottom: 1px solid map-get($brand-colors, gray-light);
			outline: 0;
			box-shadow: none;
		}

		&--large {
			&.form-control {
				@include pfont-bold;
				@include responsive-type($h3-map);
			}
		}

		&--small {
			&.form-control {
				font-size: $font-size-base;
				height: 36px;
			}
		}
	}

	&__child-link {
		flex: 0 0 0;
		padding: 0 map-get($spacing, tiny);
		display: flex;
		align-items: center;
		text-decoration: none;
		color: $text-color;
		font-size: $font-size-small;

		.ie11 & {
			flex-basis: 0.5em;

			&:last-child {
				margin-right: 1em;
			}
		}

		&:hover {
			color: map-get($brand-colors, red-light);
			text-decoration: none;
		}

		.icon-delete1 {
			font-size: $font-size-base;
		}
	}

	&__add-section {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		margin-top: map-get($spacing, small);
	}

	&__add-section-btn {
		flex: 1 1 100%;
		max-width: 100%;
		display: flex;
		align-items: center;
		@include pfont-bold;
		@include responsive-type($h3-map);
		border: 1px solid map-get($brand-colors, gray-light);
		color: $text-color;
		padding: map-get($spacing, tiny);
		text-decoration: none;
		transition: all $transition-med ease;
		cursor: pointer;

		.ie11 & {
			display: inline-block;
			flex-basis: 1em;
		}

		&:before {
			content: "\e915";
			flex: 0 0 0;
			font-family: icomoon;
			padding: 0 map-get($spacing, tiny);
			color: lighten(map-get($brand-colors, gray-med), 3%);
			font-size: $font-size-small;
		}

		&:hover {
			color: $text-color;
			background: map-get($brand-colors, gray-lighter);
			text-decoration: none;
		}
	}

	&__child-add-section {
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		margin-top: map-get($spacing, tiny);
	}

	&__child-add-section-btn {
		flex: 1 1 100%;
		max-width: 100%;
		display: flex;
		align-items: center;
		border: 2px solid map-get($brand-colors, gray-lighter);
		color: map-get($brand-colors, gray-med);
		@include pfont-bold;
		padding: map-get($spacing, tiny) 0;
		text-decoration: none;
		transition: all $transition-med ease;
		cursor: pointer;

		&:before {
			content: "\e915";
			flex: 0 0 0;
			font-family: icomoon;
			padding: 0 map-get($spacing, tiny);
			color: lighten(map-get($brand-colors, gray-med), 3%);
			font-size: $font-size-small;
			font-weight: normal;

			.ie11 & {
				display: inline-block;
				flex-basis: 1em;
			}
		}

		&:hover {
			color: $text-color;
			background: map-get($brand-colors, gray-lighter);
			text-decoration: none;
		}
	}
}
