.video-list {
	@include list-unstyled;
	margin: 0;
	padding: 0;

	&__item {
		margin-bottom: map-get($spacing, small);
	}

	&__link {
		display: flex;
		color: $gray-dark;
		text-decoration: none;
		align-items: center;

		&:hover {
			color: map-get($brand-colors, red-light);
			text-decoration: none;
		}
	}

	&__col-img {
		flex: 1 1 35%;
		max-width: 35%;
	}

	&__img {
		max-width: 100%;
		height: auto;
	}

	&__col-content {
		flex: 1 1 65%;
		max-width: 65%;
		padding-left: map-get($spacing, medium);
	}
}
