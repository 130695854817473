/*================================================================================
[Bubble tooltip]
*/
.bubble-tooltip {
	//pointer-events: none;

	&:hover {
		z-index: $zindex-tooltip;
	}

	&__container {
		max-width: $container-lg;
		margin: 0 auto;
	}

	&__body {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
	}

	&__trigger {
		background: #fff;
		border: 0;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		padding: 0.25em;
		color: map-get($brand-colors, red-light);
		transition: background-color $transition-med ease, color $transition-med ease, transform $transition-fast ease;
		box-shadow: 0 1px 6px rgba(#000, 0.5);
		pointer-events: all;

		&:hover, &:active, &:focus {
			background: map-get($brand-colors, gray-lighter);
			color: map-get($brand-colors, red-dark);
			transform: scale(1.15);

			html:not(.enable-cursor) & {
				outline: none;
			}
		}

		[class^=icon] {
			font-size: 1.25em;
			line-height: 34px;
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__label {
		display: block;
		margin-top: 0.5em;
		color: #fff;
		font-weight: 600;
		max-width: 10em;
		line-height: 1.2;
		font-size: 0.825em;
		text-align: center;
		opacity: 0;
		transform: translateY(-6px);
		transition: opacity $transition-fast ease, transform $transition-fast ease;
		pointer-events: none;
		background: rgba(#000, 0.75);
		padding: 0.33em;
		border-radius: 0.25em;

		.bubble-tooltip:hover & {
			opacity: 1;
			transform: translateY(0);
			transition: opacity $transition-fast ease, transform $transition-fast ease;
		}
	}

	&__contents {
		position: absolute;
		display: none;
		z-index: $zindex-modal;
		width: 90%;
		max-width: 60em;
		//max-height: 90%;
		background: #fff;
		border: 8px solid map-get($brand-colors, gray-lighter);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: map-get($spacing, medium) map-get($spacing, huge);
		overflow: auto;
		box-shadow: 0 1px 15px rgba(#000, 0.25);

		&.state-visible {
			display: block;
			animation: bubble-tooltip-reveal 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		}
	}

	&__close {
		position: absolute;
		top: 0.25em;
		right: 0.25em;
		width: 40px;
		height: 40px;
		background: transparent;
		border: none;
		color: map-get($brand-colors, red-light);
		transition: background-color $transition-med ease, color $transition-med ease, transform $transition-fast ease;
		z-index: $zindex-popover;


		&:hover, &:active, &:focus {
			background: map-get($brand-colors, gray-lighter);
			color: map-get($brand-colors, red-dark);
			transform: scale(1.1) translateY(-1px);
		}

		[class^=icon] {
			font-size: 1.25em;
			line-height: 34px;
		}
	}
}

@keyframes bubble-tooltip-reveal {
	0% {
		transform: translate(-50%, 600px) rotateX(30deg) scale(0);
		transform-origin: 50% 100%;
		opacity: 0;
	}
	100% {
		transform: translate(-50%, -50%) rotateX(0) scale(1);
		transform-origin: 50% -1400px;
		opacity: 1;
	}
}

[data-name=mojs-shape] {
	pointer-events: none;
}
