.cover-image-list {
	list-style: none;
	margin-left: 0;
	padding-left: 0;

	@media #{$xsl-up} {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		display: block;

		@media #{$xsl-up} {
			width: calc(50% - #{map-get($spacing, small)});
			margin-right: map-get($spacing, small);
		}

		@media #{$sm-up} {
			width: calc(25% - #{map-get($spacing, small)});
		}
	}

	/*------- [Modifiers] -------*/
	&--small {
		.cover-image-list__item {
			@media #{$xsl-up} {
				width: calc(33% - #{map-get($spacing, small)});
				margin-right: map-get($spacing, large);
			}

			@media #{$sm-up} {
				width: calc(25% - #{map-get($spacing, small)});
			}

			@media #{$md-up} {
				width: calc(20% - #{map-get($spacing, small)});
			}

			@media #{$lg-up} {
				width: calc(16.66% - #{map-get($spacing, small)});
			}
		}
	}
}