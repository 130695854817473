.back-to-top {
	position: fixed;
	right: 0;
	bottom: 0;
	width: 80px;
	height: 80px;
	display: flex;
	align-items:center;
	justify-content: center;
	z-index: $zindex-navbar;
	transition: all 0.25s ease;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;

	&.state-visible {
		visibility: visible;
		pointer-events: initial;
		opacity: 1;
	}

	&__circle {
		width: 60px;
		height: 60px;
		display: flex;
		border-radius: 50%;
		text-align: center;
		border:1px solid rgba(white, 0.7);
		background: rgba(map-get($brand-colors, black), 0.7);
		text-decoration: none;
		color: white;
		flex-direction: column;

		&:before {
			font-family: icomoon;
			color: white;
			content: "\e914";
			font-size: 45px;
			position: relative;
			top: auto;
			line-height: 1.33;
			text-decoration: none;
			transition: all 0.25s ease;
		}

		&:hover {
			text-decoration: none;

			&:before {
				content: "\e914";
				font-size: 32px;
				line-height: 1;
				text-decoration: none;
				top: 3px;
			}

			.back-to-top__text {
				visibility: visibile;
				font-size: 1.1em;
				opacity: 1;
				top: -8px;
			}
		}
	}

	&__text {
		display: block;
		visibility: 0;
		font-size: 0;
		opacity: 0;
		transition: all 0.25s ease;
		text-transform: uppercase;
		@include pfont-bold;
		color: white;
		position: relative;
		top: 0;
	}
}
