.v-search-results {
	&__search-icon {
		font-size: 26px;
		line-height: 0.7;
	}

	&__search-box {
		font-size: 1.3em;
		@media #{$sm-up}{
			min-width: 380px;
		}
	}

	&__query-box {
		@media #{$sm-up}{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			max-width: 100%;
			margin-bottom: map-get($spacing, large);
		}

		@media #{$md-up}{
			flex-wrap: nowrap;
		}

		&--download-images {
			@media #{$md-up}{
				flex-wrap: wrap;
			}
		}
	}

	&__query-box-heading {
		@include pfont-bold;

		@media #{$sm-up}{
			flex: 1 1 100%;
			margin: 0;
			padding: 0 map-get($spacing, small) 0 0;

			&:first-child {
				margin: 0;
			}
		}
		@media #{$md-up}{
			flex: 0 0 auto;
		}

		.v-search-results__query-box--download-images & {
			@include pfont-light;
			@media #{$sm-up}{
				padding: 0 map-get($spacing, small) map-get($spacing, tiny) 0;
			}
			@media #{$md-up}{
				flex: 1 1 100%;
			}
		}
	}

	&__query-box-input {
		@media #{$sm-up}{
			flex: 1 1 100%;
		}
		@media #{$md-up}{
			flex: 1 1 auto;
		}

		.v-search-results__query-box--download-images & {
			@media #{$md-up}{
				flex: 1 1 100%;
			}
		}
	}
}
