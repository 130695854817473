/* Ion.RangeSlider, Simple Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// © guybowden, 2014        https://github.com/guybowden
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs {
	height: 55px;
}

.irs-with-grid {
	height: 75px;
}

.irs-line {
	height: 6px;
	top: 35px;
	background: #fff;
	border: 1px solid #CCC;
	border-radius: 16px;
}

.irs-line-left {
	height: 8px;
}

.irs-line-mid {
	height: 8px;
}



.irs-line-right {
	height: 8px;
}

.irs-bar {
	height: 8px;
	top: 34px;
	background: map-get($brand-colors, red-light);
}

.irs-bar-edge {
	height: 8px;
	top: 34px;
	width: 14px;
	border: 1px solid map-get($brand-colors, red-light);
	border-right: 0;
	background: map-get($brand-colors, red-light);
	border-radius: 16px 0 0 16px;
}

.irs-shadow {
	height: 2px;
	top: 38px;
	background: #000;
	opacity: 0.3;
	border-radius: 5px;
	-moz-border-radius: 5px;
}

.lt-ie9 .irs-shadow {
	filter: alpha(opacity=30);
}

.irs-slider {
	top: 28px;
	width: 20px;
	height: 20px;
	border: 3px solid white;
	background: map-get($brand-colors, red-light);
	border-radius: 20px;
	-moz-border-radius: 20px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
	cursor: pointer;
	transition: background 0.25s ease;
}

.irs-slider.state_hover, .irs-slider:hover {
	background: map-get($brand-colors, red-dark);
}

.irs-min, .irs-max {
	color: #333;
	font-size: 12px;
	line-height: 1.333;
	text-shadow: none;
	top: 0;
	padding: 1px 5px;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	-moz-border-radius: 3px;
}

.lt-ie9 .irs-min, .lt-ie9 .irs-max {
	background: #ccc;
}

.irs-from, .irs-to, .irs-single {
	@include responsive-type($h4-map);
	font-weight: bold;
	line-height: 1.333;
	text-shadow: none;
	padding: 0;
	background: transparent;
}

.lt-ie9 .irs-from, .lt-ie9 .irs-to, .lt-ie9 .irs-single {
	background: #999;
}

.irs-grid {
	height: 27px;
}

.irs-grid-pol {
	opacity: 0.5;
	background: #428bca;
}

.irs-grid-pol.small {
	background: #999;
}

.irs-grid-text {
	bottom: 5px;
	color: #99a4ac;
}

.irs-disabled {
}
