//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
	//display: inline-block;
	display: inline-flex;
	align-items: center;
	margin-bottom: 0; // For input.btn
	font-weight: $btn-font-weight;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	border: 2px solid transparent;
	white-space: nowrap;
	transition: all $transition-fast ease-in-out;
	text-transform: uppercase;
	@include button-size($button-pad-vert-sm, $button-pad-horz-sm, $font-size-small, $line-height-small, $btn-border-radius-small);
	@include user-select(none);

	&,
	&:active,
	&.active {
		&:focus,
		&.focus {
			@include tab-focus;
		}
	}

	&:hover,
	&:focus,
	&.focus {
		color: $btn-default-color;
		text-decoration: none;
	}

	&:active,
	&.active {
		outline: 0;
		background-image: none;
		@include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: $cursor-disabled;
		@include opacity(.65);
		@include box-shadow(none);
	}

	@media #{$xs-only}{
		@include button-size($button-pad-vert-xs, $button-pad-horz-xs, (ceil(($font-size-small * 0.8))), $line-height-small, $btn-border-radius-small);
	}

	@media #{$md-up}{
		@include button-size($button-pad-vert-default, $button-pad-horz-default, $font-size-small, $line-height-base, $btn-border-radius-base);
	}

	// [converter] extracted a& to a.btn
}

a.btn {
	&.disabled,
	fieldset[disabled] & {
		pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
	}
}

// Alternate buttons
// --------------------------------------------------

.btn-primary {
	@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    &--psbutton
        {
            background-color: map-get($brand-colors, white);      
            border-color: map-get($brand-colors, white);         
        }
	&:hover {
		background: map-get($brand-colors, red-dark);
		border-color: rgba(#fff, 0.25);
	}
}

.btn-secondary {
	@include button-variant($btn-secondary-color, #fff, $btn-secondary-border);

	&:hover {
		background: transparent;
		color: map-get($brand-colors, red-dark);
		border-color: map-get($brand-colors, red-dark);
	}
}

.btn-outline {
	@include button-variant($btn-outline-color, $btn-outline-bg, $btn-outline-border);
	&:hover {
		background: transparent;
		border-color: map-get($brand-colors, red-dark);
	}
}

// Success appears as green
.btn-success {
	@include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Info appears as blue-green
.btn-info {
	@include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
.btn-warning {
	@include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
	@include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
	color: $link-color;
	font-weight: normal;
	border-radius: 0;

	&,
	&:active,
	&.active,
	&[disabled],
	fieldset[disabled] & {
		background-color: transparent;
		@include box-shadow(none);
	}
	&,
	&:hover,
	&:focus,
	&:active {
		border-color: transparent;
	}
	&:hover,
	&:focus {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
		background-color: transparent;
	}
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus {
			color: $btn-link-disabled-color;
			text-decoration: none;
		}
	}
}

// Button Sizes
// --------------------------------------------------

.btn-lg {
	// line-height: ensure even-numbered height of button next to large input
	@include button-size($button-pad-vert-default, $button-pad-horz-default, $font-size-small, $line-height-base, $btn-border-radius-base);


	@media #{$md-up}{
		@include button-size($button-pad-vert-lg, $button-pad-horz-lg, $button-size-large, $line-height-large, $btn-border-radius-large);
	}
}

.btn-sm {
	@media #{$xs-only}{
		@include button-size($button-pad-vert-xs, $button-pad-horz-xs, (ceil(($font-size-small * 0.8))), $line-height-small, $btn-border-radius-small);
	}
	// line-height: ensure proper height of button next to small input
	@include button-size($button-pad-vert-sm, $button-pad-horz-sm, (ceil(($font-size-small * 0.9))), $line-height-small, $btn-border-radius-small);
}

.btn-xs {
	@include button-size($button-pad-vert-xs, $button-pad-horz-xs, (ceil(($font-size-small * 0.8))), $line-height-small, $btn-border-radius-small);
}

// Block button
// --------------------------------------------------

.btn-block {
	display: block;
	width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
	margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}

/*================================================================================
[Button load indicator]
*/

.btn {
	&__load-indicator {
		width: 1.25em;
		height: 1.25em;
		position: relative;
		top: ($button-pad-vert-default / 2);
		margin-right: 0.25em;
		animation: load-more-rotate 1.5s linear; // found in _load-more.scss
		animation-iteration-count: infinite;
		border-radius: 50%;
		border: 3px solid #fff;
		border-right-color: transparent;
	}
}
