pff-filter-category {
	display: contents;
	width: 100%;
}

// PFF01-698: Display: block is needed for child elements to use position: sticky in iOS
pff-product-filtering {
	display: block;
}

pff-category-description {
	display: block;

	@media #{$sm-up} {
		position: relative;
		margin-top: map-get($spacing, medium) * -1;
	}
}

.v-product-filtering {
	&__filter {
		box-shadow: 0 1px 3px rgba(#000, 0.1);
	}

	&__sort {
		top: -1px;
		background: map-get($brand-colors, true-white);
		padding: map-get($spacing, tiny) 0;
		box-shadow: 0 2px 3px rgba(#000, 0.1);
		border-top: 1px solid rgba(#000, 0.05);

		@media print {
			page-break-inside: avoid;
		}
	}
}

favorites-app, collections-landing-app, product-family-app, product-filtering-app, spec-builder-app, view-all-support-products-app {
	display: block;
	min-height: 90vh;
}