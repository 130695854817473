.quick-search-box {
	position: relative;

	&__input {
		appearance: none;
		border-radius: 0;
		border: solid rgba(map-get($brand-colors, gray-med), 0.2);
		border-width: 0 0 1px;
		background: #fff;
		font-weight: 300;
		letter-spacing: -0.025em;
		font-size: 2rem;
		position: absolute;
		width: 100%;
		max-width: 1600px - 163px;
		transition: all $transition-med ease 0s;
		left: 283px;
		padding: 0.25em 0;
		opacity: 0;
		pointer-events: none;
		z-index: 1;

		&::-ms-clear {
			display: none;
		}

		@include placeholder($input-color-placeholder);

		@media #{$xs-only} {
			top: 61px;
			left: 0;
			border-bottom: 6px solid map-get($brand-colors, gray-light);
			z-index: $zindex-navbar;
		}

		@media #{$sm-up} {
			top: 30px;
			right: 0;
			left: 183px;
			width: calc(100% - 183px);
			height: 76px;
			padding: 0.25em 2em 0;
			box-sizing: border-box;
		}

		@media (min-width: 1600px) {
			left: 159px;
		}

		.state-quick-search-visible & {
			pointer-events: all;
			padding: 0.25em 1em;
			//max-width: 100vw;
			opacity: 1;
			z-index: $zindex-high;

			@media #{$sm-up} {
				transition: all $transition-med ease $transition-med;
				padding: 0.25em 1em 0;

			}
		}

		&, &:hover, &:active, &:focus {
			outline: none;
		}
	}

	&__btn {
		display: block;
		position: absolute;
		top: 15px;
		right: map-get($spacing, medium);
		background: #FFF;
		//border: none;
		margin: 0;
		padding: 0;
		font-size: 1.25rem;
		border: 1px solid map-get($brand-colors, gray-light);
		border-radius: 50%;
		width: 40px;
		height: 40px;
		z-index: 1;

		&:hover, &:active, &:focus {
			outline: none;
		}

		@media #{$sm-up} {
			top: 52.5px;
			z-index: 1;
		}

		@media #{$md-up} {
			top: 45px;
		}
	}

	&__submit {
		transition: all $transition-fast ease;
		color: map-get($brand-colors, red-light);

		.state-quick-search-visible & {

		}

		.quick-search-box--reversed & {
			color: map-get($brand-colors, red-light);
		}

		&--open {
			z-index: $zindex-higher;
			.icon-search {
				&:before {
					content: "\e90e";
				}
			}
		}
	}

	&--reversed {
		z-index: $zindex-high;
	}

	/*&__cancel {
		opacity: 0;
		pointer-events: none;
		transition: opacity $transition-fast ease;

		[class^=icon] {
			color: map-get($brand-colors, red-light);
		}

		.state-quick-search-visible & {
			opacity: 1;
			pointer-events: all;
		}
	}*/
}

/*================================================================================
[Quick-search results]
*/
.quick-search-results {
	//z-index: $zindex-tooltip;

	@media #{$xs-only} {
		@include mobile-full-screen-panel;
		display: block;
		top: 117px;

		&.state-visible {
			height: calc(100vh - 117px);
		}
	}

	&__body {
		padding: map-get($spacing, large) 0;
		position: relative;
		width: 100%;
		margin: 0 auto;

		@media #{$sm-up}{
			width: $container-sm;
			display: flex;
		}

		@media #{$md-up}{
			width: $container-md;
		}

		@media #{$lg-up}{
			width: $container-lg;
		}

		.ie11 & {
			margin: 0;
		}
	}

	&__panel {
		border-bottom: 1px solid rgba(#000, 0.1);
		margin: 0;
		padding: 0 map-get($spacing, small) map-get($spacing, medium) map-get($spacing, small);

		@media #{$sm-up} {
			width: 50%;
			border: none;
			padding-bottom: 0;
		}

		&:last-child {
			border: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	a {
		transition: all $transition-fast ease;

		&:focus, &:hover {
			background: map-get($brand-colors, gray-light);
		}

		&.quick-search-results__view-all {
			@include pfont-bold;
			color: map-get($brand-colors, red-light);
			padding: 0 map-get($spacing, tiny);

			&:hover, &:active, &:focus {
				color: map-get($brand-colors, red-dark);
				background: transparent;
				text-decoration: inherit;
			}

		}
	}

	&__matching-terms {
		font-size: 1.2em;
	}

	&__matching-term {
		margin-bottom: 10px;
	}

	&__link, &__view-all {
		display: block;
	}

	&__link {
		@include pfont-regular;
		border-radius: 5px;
		padding: map-get($spacing, tiny);

		em {
			font-style: normal;
			@include pfont-bold;
		}
		&, &:hover, &:active, &:focus {
			color: inherit;
			text-decoration: inherit;
		}
	}

	&__matching-products, &__matching-support, &__matching-pages {
		@include list-unstyled;
	}

	&__matching-product {
		margin-bottom: map-get($spacing, small);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.menu-quick-search-row {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: map-get($spacing, medium);

	@media #{$sm-up}{
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}
}

.menu-quick-search {
	position: relative;

	button,
	button:hover,
	button:focus,
	button:active,
	button:active:hover,
	button:active:focus {
		position: absolute;
		top: 17px;
		right: 10px;
		color: map-get($brand-colors, red-light);
		background: transparent;
		margin: 0;
		padding: 0;
		border: 0;
		line-height: 1;
		height: auto;
		width: auto;


		@media #{$sm-up}{
			top: 17px;
		}

		@media #{$md-up}{
			top: 17px;
		}
	}
}
