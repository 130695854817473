/*================================================================================
[Image switcher]
*/
.image-switcher {
	padding: map-get($spacing, medium);
	position: relative;

	@media #{$sm-up} {
		padding: map-get($spacing, large) map-get($spacing, huge);
	}

	&__images {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 250px;
		@include clearfix;

		@media #{$sm-up}{
			min-height: 25em;
		}

	}

	&__title {
		@media #{$xs-only}{
			text-align: center;
		}
	}

	&__desc {
		margin-bottom: map-get($spacing, large);
		@media #{$xs-only}{
			text-align: center;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		height: 100%;
		//overflow: hidden;
		opacity: 0;
		transition: opacity $transition-slow ease-in-out;

		.ie11 & {
			bottom: 0;
			left:0;
			right: 0;
			width: 100%;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
			top: 50%;
			position: relative;
			transform: translateY(-50%);

			.ie11 & {
				transform: none;
				top: initial !important; //overwrite polyfill for ie11
				margin-left: initial !important; //overwrite polyfill for ie11
			}
		}

		&.state-active {
			opacity: 1;
		}

		/*------- [Modifiers] -------*/
		&--porthole {
			img {
				border: 8px solid #fff;
				border-radius: 50%;
			}
		}

		.image-switcher--image-right &, .image-switcher--image-left & {
			left: 0;
			right: 0;

			img {
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	&__tabs {
		margin-top: map-get($spacing, small);
	}

	//toggle animation
	&__animated-click {
		position: absolute;
		display: flex;
		margin: 0.8;
		padding: 0;
		left: map-get($spacing, small);
		bottom: map-get($spacing, small);
		width: 50px;
		height: 50px;
		border: none;
		border-radius: 50%;
		//background: rgba(white,0.5);
		color: map-get($brand-colors, gray-med);
		font-size: 1.8em;
		overflow: visible;
		transition: all 0.7s;
		z-index: 8;
		pointer-events: none;
		///transform: translate(-50%, -50%);
		align-items: center;
		justify-content: center;

		&:after {
			box-shadow: inset 0 0 0 15px rgba(map-get($brand-colors, gray-dark), 0);
			animation: click-me-now 1s ease-out infinite;
			position: absolute;
			top: calc(50% - 3px);
			left: calc(50% + 1px);
			margin: -20px 0 0 -20px;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			content: '';
			opacity: 0;
			pointer-events: none;
		}

		i {
			position: relative;
			top: 4px;
			left: 2px;
			z-index: 10;
		}

		&--white {
			color: white;
			opacity: 1;
			//background: rgba(black,0.5);

			&:after {
				animation: click-me-now-white 1s ease-out infinite;
				box-shadow: inset 0 0 0 5px rgba(white, 0);
			}
		}
	}

	&__html {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		z-index: 4;
		pointer-events: none;
	}

	&__extended-content {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		overflow: visible;
	}

	&--image-right, &--image-left {
		padding: 0;

		@media #{$sm-up} {
			padding: 0;
			display: flex;
			align-items: center;

			.image-switcher__tabs {
				margin: 0 0 0 -12px;
			}
		}

		@media #{$md-up}{
			.image-switcher__tabs {
				margin: 0 0 0 -24px;
			}
		}

		.image-switcher__body {
			@media #{$sm-up} {
				flex: 1 1 auto;
			}
		}

		.image-switcher__images {
			flex: 0 0 auto;

			@media #{$sm-up} {
				min-height: 30em;
				max-width: 50%;
				min-width: 20%;
			}
		}
	}

	&--image-right {
		.image-switcher__body {
			@media #{$sm-up} {
				order: 1;
				flex: 1 1 50%;
				padding-right: map-get($spacing, small);
			}
		}

		.image-switcher__images {
			@media #{$xs-only}{
				height: 250px;
			}
			@media #{$sm-up} {
				order: 2;
				min-height: 25em;
			}
			@media #{$md-up}{
				min-height: 28em;
			}
			@media #{$lg-up}{
				min-height: 30em;
			}
		}
	}

	&--image-left {
		.image-switcher__body {
			@media #{$sm-up} {
				padding-left: map-get($spacing, large);
				order: 2;
				flex: 1 1 50%;
			}
		}

		.image-switcher__images {
			@media #{$xs-only}{
				height: 250px;
			}
			@media #{$sm-up} {
				order: 1;
				min-height: 25em;
			}
			@media #{$md-up}{
				min-height: 28em;
			}
			@media #{$lg-up}{
				min-height: 30em;
			}
		}
	}

	&--toggle {
		padding: 0;
		cursor: pointer;
		border: 1px solid map-get($brand-colors, gray-light);

		.image-switcher__image {
			z-index: 2;

			&:first-child {
				opacity: 0;
				transition: 0.25s ease-in-out;
				z-index: 3;
			}

			&.state-visible {
				&:first-child {
					opacity: 1;
				}
			}
		}
	}
}



@-webkit-keyframes click-me-now {
	0% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5);
	}
	50% {
		box-shadow: inset 0 0 0 2px rgba(map-get($brand-colors, gray-med),0.8);
		opacity: 0.8;
	}
	100% {
		box-shadow: inset 0 0 0 2px rgba(map-get($brand-colors, gray-med),0.8);
		opacity: 0;
		transform: scale3d(1.2, 1.2, 1);
	}
}

@keyframes click-me-now {
	0% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5);
	}
	50% {
		box-shadow: inset 0 0 0 2px rgba(map-get($brand-colors, gray-med),0.8);
		opacity: 0.8;
	}
	100% {
		box-shadow: inset 0 0 0 2px rgba(map-get($brand-colors, gray-med),0.8);
		opacity: 0;
		transform: scale3d(1.2, 1.2, 1);
	}
}
@-webkit-keyframes click-me-now-white {
	0% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5);
	}
	50% {
		box-shadow: inset 0 0 0 2px rgba(white,0.8);
		opacity: 0.8;
	}
	100% {
		box-shadow: inset 0 0 0 2px rgba(white,0.8);
		opacity: 0;
		transform: scale3d(1.2, 1.2, 1);
	}
}

@keyframes click-me-now-white {
	0% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5);
	}
	50% {
		box-shadow: inset 0 0 0 2px rgba(white,0.8);
		opacity: 0.8;
	}
	100% {
		box-shadow: inset 0 0 0 2px rgba(white,0.8);
		opacity: 0;
		transform: scale3d(1.2, 1.2, 1);
	}
}
