.collection-card {
	background: map-get($brand-colors, gray-lighter);
	min-height: 200px;
	padding: map-get($spacing, large) map-get($spacing, medium);
	display: flex;
	text-decoration: none;
	position: relative;
	box-sizing: border-box;
	flex-direction: column;
	transition: background-color 0.2s linear;
	text-align: center;
	cursor: pointer;
	font-size: 1em;
	line-height: 1.2;
	align-items: center;
	justify-content: center;
	color: map-get($brand-colors, gray-dark);

	&:hover {
		//background: map-get($brand-colors, gray-lightest);
		text-decoration:none;
		color: map-get($brand-colors, gray-dark);

		.collection-card__image {
			transform: scale(1.1);

		}
	}

	&__grade {
		@media #{$xs-only}{
			font-size: 0.9em;
		}

		position: absolute;
		top: 0;
		left: 15px;
		bottom: 0;
		padding: 0 0 0 0;
		text-transform: uppercase;
		color: map-get($brand-colors, red-dark);
		font-weight: bold;
		letter-spacing: .1em;
		z-index: 100;
		white-space: nowrap;
		pointer-events: none;
		text-align: right;
		font-size: 1em;
	}

	&__grade-inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%) rotate(-90deg);
	}
	&__grade-logo {
		position: absolute;
		top: 80%;
		left: 50%;	
		width:75px;	
	}

	&__status-flag {
		color: white;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		font-size: x-small;
		text-transform: uppercase;
		font-weight: bold;

		&:before {
			position: absolute;
			top: 0;
			right: 0;
			content: "";
			width: 0;
			height: 0;
			overflow: hidden;
			border: 42px solid transparent;
			border-right-color: map-get($brand-colors, gray-dark);
			border-top-color: map-get($brand-colors, gray-dark);
		}

		span {
			display: block;
			position: absolute;
			top: 30px;
			right: -7px;
			color: white;
			transform-origin: 50% 50%;
			transform: rotateZ(45deg);
			line-height: .9em;
		}

		&--coming-soon {
			&:before {
				border-right-color: map-get($brand-colors, blue-gray);
				border-top-color: map-get($brand-colors, blue-gray);
			}

			span {
				top: 20px;
				right: 6px;
			}
		}

		&--new {
			&:before {
				border-right-color: map-get($brand-colors, red-dark);
				border-top-color: map-get($brand-colors, red-dark);
			}

			span {
				top: 25px;
				right: 12px;
			}
		}
	}

	&__info {
		text-align: left;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: map-get($spacing, medium);
		max-width: 100%;
	}

	&__family {
		display: block;
		text-transform: uppercase;
		@include responsive-type($h3-map);
		@include pfont-bold;
		text-align: center;
		max-width: 100%;
	}

	&__image {
		max-width: 240px;
		height: 16vw;
		max-height: 25vh;
		margin: 0 auto;
		transition: all 0.25s ease;
		width: auto;
		min-height: 25vh;
	}

	&__description {
		text-decoration: none;
		margin-bottom: 4px;
		font-size: $font-size-base * 0.875;
		max-width: 100%;
	}

	&--in-grid {
		height: 100%;
	}
}

.collection-active
	{
		border: 4px solid #e24747;		
	}
