.v-innovations {
	&__parallax {
		background-color: map-get($brand-colors, gray-dark);
		height: 900px;
		position: relative;
		overflow:hidden;
		text-align: center;
		@include clearfix;

		@media #{$sm-up}{
			height: 1200px;
		}

		&--thermoforce {
			height: 1000px;

			background-color: #8caeb7;
			@media #{$sm-up}{
				height: 1300px;
			}
		}
	}

	&__parallax-img {
		height: 100%;
		float: left;
		width: auto;
		margin: 0 auto;
		position: relative;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		display: block;
	}

	&__parallax-disclaimer {
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 15px;
		left: 0;
		right: 0;
		max-width: $container-lg;
		padding: map-get($spacing, large);
		margin: 0 auto;
		color: map-get($brand-colors, true-white);
	}

	&__content-container {
		.v-innovations__parallax--thermoforce & {
			max-width: $container-lg;
			width: 90%;
			position: absolute;
			margin: 0 auto;
			left: 0;
			right: 0;
			//left: 50%;
			//transform: translateX(-50%);
			top: 7%;
			text-align: center;

			@media #{$sm-up}{
				text-align: left;
				top: 12%;
			}

			@media #{$md-up}{
				top: 15%;
			}
		}
	}

	&__parallax-container {
		width: 40px;
		margin: 0 auto;
		text-align: left;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 2;
		color: map-get($brand-colors, true-white);
		height: 100%;
	}

	&__parallax-item {
		position: absolute;
		display: inline-flex;
		z-index: 3;
		align-items: center;

		@media #{$xs-only}{
			&:before {
				content: "";
				width: 110%;
				position: absolute;
				left: -5%;
				display: block;
				z-index: 1;
				top: -5%;
				padding: 0;
				height:110%;
				border-radius: 20px;
				background: rgba(map-get($brand-colors, true-black), 0.2);
				box-shadow: 0 0 45px rgba(map-get($brand-colors, true-black), 0.4);
			}
		}


		&--item-1 {
			left: -120px;
			top: 21%;

			@media #{$sm-up}{
				left: 100%;
				top: 15%;
			}

			@media #{$md-up}{
				left: calc(100% + 30px);
			}

			.v-innovations__parallax--thermoforce & {
				top: 36%;

				@media #{$sm-up}{
					top: 31%;
				}
			}
		}

		&--item-2 {
			right: -130px;
			top: 40%;

			.v-innovations__parallax-item-content-col {
				min-width: 180px;

				@media #{$sm-up}{
					min-width: 180px;
				}
			}

			@media #{$sm-up}{
				top: 35%;
				right: calc(100% + 10px);
			}

			.v-innovations__parallax--thermoforce & {
				top: 56.5%;

				@media #{$sm-up}{
					top: 51%;
				}
			}
		}

		&--item-3 {
			left: -120px;
			top: 56%;

			@media #{$sm-up}{
				left: 100%;
				top: 55%;
			}

			.v-innovations__parallax--thermoforce & {
				top: 78%;

				@media #{$sm-up}{
					top: 75%;
				}
			}
		}

		&--img-right {
			.v-innovations__parallax-item-img-col {
				order: 2;
				text-align: right;
			}
			.v-innovations__parallax-item-content-col {
				order: 1;
			}
		}
	}

	&__parallax-item-img-col {
		flex: 0 0 110px;
		min-width: 110px;
		text-align: left;
		order: 1;
		position: relative;
		z-index: $zindex-low;

		@media #{$md-up}{
			flex: 0 0 200px;
			min-width: 200px;
		}
	}

	&__parallax-item-img {
		width: 100px;
		height: 100px;

		@media #{$md-up}{
			width: 188px;
			height: 188px;
		}
	}

	&__parallax-item-content-col {
		flex: 1 1 auto;
		min-width: 180px;
		order: 2;
		color: map-get($brand-colors, true-white);
		font-size: .8em;
		position: relative;
		z-index: $zindex-high;

		@media #{$sm-up}{
			font-size: 0.9em;
			min-width: 220px;
		}

		@media #{$md-up}{
			font-size: 1em;
			min-width: 250px;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__parallax-item-title {
		@include pfont-bold;
		@include responsive-type($h4-map);

		@media #{$sm-up}{
			@include responsive-type($h3-map);
		}
	}


	//comparison image on hydrodrench page
	&__coverage-image {
		max-width: 100%;
		height: auto;
	}


	&__frame-stack {
		.hero-feature {
			align-items: flex-start;
			justify-content: flex-start;
			background: map-get($brand-colors, gray-dark);
		}
		.hero-feature__bg {
			right: 0;
			top: 0;
			justify-self: flex-start;
			align-self: flex-start;
		}
		.hero-feature__body {
			@media #{$sm-up}{
				top: auto;
				transform: none;
				margin: map-get($spacing, massive) 0;
			}
		}
	}

	&__narrow {
		position: absolute;
		left: 0;
		@include pfont-bold;
	}

	&__wide {
		position: absolute;
		right: 0;
		@include pfont-bold;
	}




	//THERMOFORCE CUSTOM CONTENT BLOCK;
	&__head-rotator {
		position: relative;
		@media #{$sm-up}{
			display: flex;
			align-items: flex-start;
			justify-content: center;
		}
	}

	&__head-rotator-container {
		@media #{$sm-up}{
			height: 642px;
			display: flex;
			align-items: flex-start;
			width: $container-sm;
			max-width: 100%;
			padding-top: 100px;
		}
		@media #{$md-up}{
			height: 742px;
			width: $container-md;
			padding-top: 170px;
		}
		@media #{$lg-up}{
			height: 842px;
			width: $container-lg;
			padding-top: 220px;
		}
	}

	&__head-rotator-step-position {

	}

	&__head-rotator-position {
		align-items: center;
		display: none;

		&.state-visible {
			display: flex;
		}
	}

	&__head-rotator-copy {
		display: none;

		&.state-visible {
			display: block;
		}
	}


	&__head-rotator-position-img {
		flex: 1 1 70px;
		max-width: 70px;
		width: 100%;
		height: auto;

		@media #{$md-up}{
			flex: 1 1 130px;
			max-width: 130px;
		}
	}

	&__head-rotator-position-content {
		flex: 1 1 auto;
		@include pfont-bold;
		@include responsive-type($h3-map);
		padding: 0 map-get($spacing, medium);
		color: map-get($brand-colors, gray-med);
		line-height: 1.2;
	}

	&__head-rotator-visual {
		@media #{$xs-only}{
			position: relative;
			width: 300px;
			margin: map-get($spacing, giant) auto 0 auto;
		}
		@media #{$sm-up}{
			position: absolute;
			right: 0;
			left: 30%;
			height: 100%;
			top: 0;
			bottom: 0;
			overflow: hidden;
		}
		@media #{$md-up}{
			left: 40%;
		}
		@media #{$lg-up}{
			left: 50%;
		}

	}

	&__head-rotator-drag-zone {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100px;
		height: 100%;
		z-index: $zindex-low - 1;

		@media #{$sm-up}{
			width: 250px;
		}
	}

	&__head-rotator-input {
		@media #{$xs-only}{
			width: 280px; //change here
			height: 100px;
			left: 100%;
			top: 10px;
			bottom: 10px;
			position: absolute;
			transform:rotate(90deg);
			transform-origin: 0 0;
		}
		@media #{$sm-up}{
			width: 582px; //change here
			height: 250px;
			left: 100%;
			top: 30px;
			bottom: 30px;
			position: absolute;
			//writing-mode: vertical-lr; /* IE */
			transform:rotate(90deg);
			transform-origin: 0 0;
		}

		@media #{$md-up}{
			width: 682px; //change here
		}

		@media #{$lg-up}{
			width: 782px; //change here
		}

	}

	&__head-rotator-showerhead {
		@media #{$sm-up}{
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__head-rotator-rotation {
		@media #{$xs-only}{
			text-align: center;
			position: relative;
			width: 300px;
			height: 300px;
			margin: 0 auto;
		}

		@media #{$sm-up}{
			width: 900px;
			height: 900px;
			position: absolute;
			top: 50%;
			left: 10px;
			transform: translateY(-50%) rotate(0) scale(0.85);
			transition: $transition-med ease;
			transform-origin: center center;
			z-index: $zindex-lower - 1;
		}

		@media #{$md-up}{
			transform: translateY(-50%) scale(1);
			left: 60px;
		}

		@media #{$lg-up}{
			transform: translateY(-50%) scale(1.10);
			left: 120px;
		}

		&--setting-1 {
			transform: rotate(0) scale(1);
			@media #{$sm-up}{
				transform: translateY(-50%) rotate(0) scale(0.85);
			}
			@media #{$md-up}{
				transform: translateY(-50%) rotate(0) scale(1);
			}
			@media #{$lg-up}{
				transform: translateY(-50%) rotate(0) scale(1.10);
			}
		}

		&--setting-2 {
			transform: rotate(-20deg) scale(1);
			@media #{$sm-up}{
				transform: translateY(-50%) rotate(-20deg) scale(0.85);
			}
			@media #{$md-up}{
				transform: translateY(-50%) rotate(-20deg) scale(1);
			}
			@media #{$lg-up}{
				transform: translateY(-50%) rotate(-20deg) scale(1.10);
			}
		}

		&--setting-3 {
			transform: rotate(-38deg) scale(1);
			@media #{$sm-up}{
				transform: translateY(-50%) rotate(-38deg) scale(0.85);
			}
			@media #{$md-up}{
				transform: translateY(-50%) rotate(-38deg) scale(1);
			}
			@media #{$lg-up}{
				transform: translateY(-50%) rotate(-38deg) scale(1.10);
			}
		}

		&--setting-4 {
			transform: rotate(-54deg) scale(1);
			@media #{$sm-up}{
				transform: translateY(-50%) rotate(-54deg) scale(0.85);
			}
			@media #{$md-up}{
				transform: translateY(-50%) rotate(-54deg) scale(1);
			}
			@media #{$lg-up}{
				transform: translateY(-50%) rotate(-54deg) scale(1.10);
			}
		}

		&--setting-5 {
			transform: rotate(-72deg) scale(1);
			@media #{$sm-up}{
				transform: translateY(-50%) rotate(-72deg) scale(0.85);
			}
			@media #{$md-up}{
				transform: translateY(-50%) rotate(-72deg) scale(1);
			}
			@media #{$lg-up}{
				transform: translateY(-50%) rotate(-72deg) scale(1.10);
			}
		}

		&--setting-6 {
			transform: rotate(-91deg) scale(1);
			@media #{$sm-up}{
				transform: translateY(-50%) rotate(-91deg) scale(0.85);
			}
			@media #{$md-up}{
				transform: translateY(-50%) rotate(-91deg) scale(1);
			}
			@media #{$lg-up}{
				transform: translateY(-50%) rotate(-91deg) scale(1.10);
			}
		}
	}


	&__head-rotator-showerhead-img {
		width: 100%;
		height: auto;
		position: relative;
		z-index: $zindex-lower + 1;
		margin: 0 auto;

		@media #{$sm-up}{
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			margin: initial;
		}

	}

	&__head-rotator-headfeature-img {
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		width: auto;
		max-width: 100%;
		margin: 0 auto;
		visibility: hidden;
		z-index: $zindex-lower + 2;
		opacity: 0;
		transition: all $transition-med ease;


		@media #{$sm-up}{
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			max-width: none;
		}

		&.state-visible {
			opacity: 1;
			visibility: visible;
		}
	}

	&__head-rotator-click-drag {
		@media #{$xs-only}{
			background: url(//images.pfisterfaucets.com/is/image/Pfister/click%2Dand%2Ddrag%2Dmobile?wid=294&resMode=sharp&qlt=90&fmt=png-alpha) no-repeat left top;
			width: 300px;
			height: 43px;
			position: absolute;
			bottom: 100%;
			transform: scale(0.6);
			left: calc(50% - 170PX);

			html.coarsepointer & {
				background: url(//images.pfisterfaucets.com/is/image/Pfister/tap%2Darrows%2Dto%2Dmove?wid=294&resMode=sharp&qlt=90&fmt=png-alpha) no-repeat left top;
			}
		}

		@media #{$sm-up}{
			position: absolute;
			right: calc(70% - 180px);
			top: 30px;
			z-index: $zindex-lower;
			transform-origin: top right;
			transform: rotate(-20deg) scale(0.6);
			background: url(//images.pfisterfaucets.com/is/image/Pfister/click%2Dand%2Ddrag?wid=294&resMode=sharp&qlt=90&fmt=png-alpha) no-repeat left top;
			width: 294px;
			height: 102px;
			background-size: cover;

			html.coarsepointer & {
				background: url(//images.pfisterfaucets.com/is/image/Pfister/tap%2Darrows%2Ddesktop?wid=294&resMode=sharp&qlt=90&fmt=png-alpha) no-repeat left top;
			}
		}
		@media #{$md-up}{
			right: calc(60% - 190px);
			transform: scale(0.75);
		}
		@media #{$lg-up}{
			right: calc(50% - 220px);
			transform: scale(1);
		}
	}

	&__head-rotator-arrows {
		position: absolute;
		width: auto;
		display: block;
		z-index: $zindex-lower - 2;
		transform: rotate(-0.5deg);

		@media #{$xs-only}{
			top: 46px;
			left: calc(50% - 145px);
			height: 216px;
		}

		@media #{$sm-up}{
			top: 52px;
			left: calc(30% + 90px);
			height: 550px;
		}

		@media #{$md-up}{
			top: 65px;
			left: calc(40% + 80px);
			height: 630px;
		}

		@media #{$lg-up}{
			top: 80px;
			left: calc(50% + 90px);
			height: 700px;
		}
	}
}
